import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { NewestNodesComponent_Query } from "@generated/NewestNodesComponent_Query.graphql";
import { NodesCarousel } from "./NodesCarousel";

const QUERY = graphql`
	query NewestNodesComponent_Query {
		LearnV2 {
			GetNewestTwelveNodes {
				...NodeCard_NodeFragment
			}
		}
	}
`;

export const NewestNodesComponent = () => {
	const nodes = useLazyLoadQuery<NewestNodesComponent_Query>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	return <NodesCarousel nodes={nodes.LearnV2.GetNewestTwelveNodes} />;
};
