import { stripUTC } from "../../components/DateTimeDisplay.component";

/**
 *
 * @param dateString the date from which the next date will be subtracted.
 * @param compareDateString if not entered the current date is used by default
 */
export const calculateDaysDifference = (dateString: string, compareDateString?: string) => {
	const cleanedDateString = stripUTC(dateString);
	const givenDate = new Date(cleanedDateString);
	const compareDate = compareDateString ? new Date(stripUTC(compareDateString)) : new Date();

	if (isNaN(givenDate.getTime()) || isNaN(compareDate.getTime())) {
		throw new Error("Invalid date");
	}

	const timeDifference = givenDate.getTime() - compareDate.getTime();
	return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};
