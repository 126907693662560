import React from "react";
import styled from "styled-components";
import { CalendarIcon } from "./SvgNodeIcons.component";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeCardDateComponent_Fragment$key } from "@generated/NodeCardDateComponent_Fragment.graphql";
import {
	formatDateTime,
	formatDateTimeAndReturnOnlyDate,
} from "../../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { NodeCardIconTextContainer } from "../../containers/NodeCardIconText.container";

const FRAGMENT = graphql`
	fragment NodeCardDateComponent_Fragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						configConsequences {
							isRewardedTo
							rewardExpired
						}
						reducedDataInTree {
							cmePoints
						}
						firstLearnOpportunityIdWithMultipleChoiceElement
						multipleChoiceContentSubmissionInfo {
							passedContentSubmissionFinishedAt
						}
						heftContentSubmissionInfo {
							hasActiveContentSubmission
							activeContentSubmissionStartedAt
						}
						firstReleasedAt
						issuedCertificates {
							issuedAt
						}
					}
				}
			}
		}
	}
`;

export const TextContainer = styled.p`
	display: flex;
	height: 16px;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
	@media screen and (max-width: 1024px) {
		font-size: 13px;
	}
	max-width: 90%;
`;

interface OwnProps {
	isUserRewardedWithCertificate: boolean;
	learnOpportunityFragmentRef: NodeCardDateComponent_Fragment$key;
	hasCanNotBeRestarteAfterPassed: boolean;
	hasNoLekButActiveContentSubmission: boolean;
	doesNodeExpiresBeforeUserGetsUnblocked: boolean;
}

export const NodeCardDateComponent = ({
	isUserRewardedWithCertificate,
	learnOpportunityFragmentRef,
	hasCanNotBeRestarteAfterPassed,
	hasNoLekButActiveContentSubmission,
	doesNodeExpiresBeforeUserGetsUnblocked,
}: OwnProps) => {
	const learnOpportunity = useFragment<NodeCardDateComponent_Fragment$key>(
		FRAGMENT,
		learnOpportunityFragmentRef,
	);

	const isRewardExpired =
		learnOpportunity.structureDefinition.extension?.configConsequences?.rewardExpired || false;

	const hasHeftWithActiveContentSubmission =
		learnOpportunity.structureDefinition.extension?.heftContentSubmissionInfo
			?.hasActiveContentSubmission || false;

	const isAModulWithoutLekAndHasNoActiveContentSubmission =
		!learnOpportunity.structureDefinition.extension
			?.firstLearnOpportunityIdWithMultipleChoiceElement && !hasHeftWithActiveContentSubmission;

	const isModuleExpiredButUserFinished = isRewardExpired && hasCanNotBeRestarteAfterPassed;

	const isModulFinishedWithCertificate =
		isUserRewardedWithCertificate &&
		learnOpportunity?.structureDefinition?.extension?.issuedCertificates?.length! > 0;

	const isModulRewardedAndNoExpired =
		(learnOpportunity?.structureDefinition?.extension?.reducedDataInTree?.cmePoints || -1) > 0 &&
		!isRewardExpired;

	const getDateText = (
		learnOpportunity: any,
		conditions: {
			isAModulWithoutLekAndHasNoActiveContentSubmission: boolean;
			isModuleExpiredButUserFinished: boolean;
			hasNoLekButActiveContentSubmission: boolean;
			isModulFinishedWithCertificate: boolean;
			isModulRewardedAndNoExpired: boolean;
		},
	): string => {
		const extension = learnOpportunity.structureDefinition.extension;

		if (conditions.isAModulWithoutLekAndHasNoActiveContentSubmission) {
			return `verfügbar seit ${formatDateTime(extension?.firstReleasedAt!, false)}`;
		} else if (conditions.isModuleExpiredButUserFinished) {
			return `absolviert am ${formatDateTime(
				extension?.multipleChoiceContentSubmissionInfo?.passedContentSubmissionFinishedAt!,
				false,
			)}`;
		} else if (conditions.hasNoLekButActiveContentSubmission) {
			return `absolviert am ${formatDateTime(
				extension?.heftContentSubmissionInfo?.activeContentSubmissionStartedAt!,
				false,
			)}`;
		} else if (conditions.isModulFinishedWithCertificate) {
			return `absolviert am ${formatDateTime(
				extension?.issuedCertificates?.[0]?.issuedAt!,
				false,
			)}`;
		} else if (conditions.isModulRewardedAndNoExpired && !doesNodeExpiresBeforeUserGetsUnblocked) {
			return `bis ${formatDateTimeAndReturnOnlyDate(
				extension?.configConsequences.isRewardedTo,
			)} zertifiziert`;
		} else {
			return `verfügbar seit ${formatDateTime(extension?.firstReleasedAt!, false)}`;
		}
	};

	const dateText = getDateText(learnOpportunity, {
		isAModulWithoutLekAndHasNoActiveContentSubmission,
		isModuleExpiredButUserFinished,
		hasNoLekButActiveContentSubmission,
		isModulFinishedWithCertificate,
		isModulRewardedAndNoExpired,
	});

	return (
		<NodeCardIconTextContainer>
			<CalendarIcon></CalendarIcon>
			<TextContainer>{dateText}</TextContainer>
		</NodeCardIconTextContainer>
	);
};
