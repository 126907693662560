import React, { useRef } from "react";
import { RedesignedExternalSearchBar } from "./RedesignedExternalSearchBar";
import { ProfileMenu } from "./ProfileMenu";
import { Menu } from "primereact/menu";
import { MAIN_MENU_ITEMS } from "../../../infecto-lms-webapp-impl/impl-main-menu-items";
import { PROFILE_LINK_MENU_ITEMS } from "../../../infecto-lms-webapp-impl/impl-profile-menu-items";
import { logout } from "../../../infecto-lms-webapp/redux/slices/auth.slice";
import styled from "styled-components";
import { useTypedDispatch } from "../../../infecto-lms-webapp/redux/redux-store";
import { UserIcon } from "./SVGMenuIcons.component";
import { Button } from "primereact/button";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { NavLink } from "react-router-dom";
import { DASHBOARD_PATH } from "../../../infecto-lms-webapp-impl/router/routes/auth/dashboard.routes";
import { ProjectLogoComponent } from "../../../infecto-lms-webapp-impl/components/ProjectLogo.component";

export const TopBarWithSearchBarAndProfileMenu = () => {
	const dispatch = useTypedDispatch();
	const menuRef = useRef<Menu | null>(null);

	return (
		<div className="py-2 px-2 lg:px-4 flex flex-row justify-content-between align-items-center">
			<div className="block md:hidden">
				{!LMS_WEBAPP_CONFIG_IMPL.alwaysShowLogoInMenuTopBar && (
					<NavLink to={DASHBOARD_PATH}>
						<ProjectLogoComponent width={50} />
					</NavLink>
				)}
			</div>
			<RedesignedExternalSearchBar />
			<div className="hidden md:block">
				<ProfileMenu />
			</div>
			<div className="block md:hidden">
				<Menu
					ref={menuRef}
					popup
					model={[
						...MAIN_MENU_ITEMS("ProfileMenu", false),
						...PROFILE_LINK_MENU_ITEMS(() => dispatch(logout()), "ProfileMenu", false),
					]}
				/>
				<MenuBurgerButton
					className="ml-auto p-button-text"
					icon={<UserIcon />}
					onClick={(e) => menuRef.current?.show(e)}
				/>
			</div>
		</div>
	);
};

const MenuBurgerButton = styled(Button)`
	background-color: transparent;
	border: none;
	max-height: 2.357rem;
	max-width: 2.357rem;
`;
