import styled from "styled-components";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { IconWrapperWithHover } from "./SVGMenuIcons.component";
import { LinkMenuText } from "./NavigationLinkMenuItem.component";

export interface ItemProps {
	menuType: string;
	isSelected?: boolean;
}
export const StyledMenuItem = styled.div<ItemProps>`
	display: flex;
	flex-direction: ${(props) => (props.menuType === "SideMenu" ? "column" : "row")};
	align-items: center;
	:hover {
		cursor: pointer;
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
		${IconWrapperWithHover} path {
			stroke: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
		}
		,
		${LinkMenuText}, i {
			color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
		}
	}
`;
