import React, { Suspense, useEffect, useState } from "react";
import { TabPanel, TabPanelHeaderTemplateOptions, TabView } from "primereact/tabview";
import { NewestNodesComponent } from "./NewestNodes.component";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { NodesCarousel } from "./NodesCarousel";
import { CarouselsTabsComponent_CertificatedNodes_NodeFragment$key } from "@generated/CarouselsTabsComponent_CertificatedNodes_NodeFragment.graphql";
import { CarouselsTabsComponent_TemporaryBlockedNodes_NodeFragment$key } from "@generated/CarouselsTabsComponent_TemporaryBlockedNodes_NodeFragment.graphql";
import { CarouselsTabsComponent_TemporaryBlockedNodes_Refetch } from "@generated/CarouselsTabsComponent_TemporaryBlockedNodes_Refetch.graphql";
import { CarouselsTabsComponent_Refetch } from "@generated/CarouselsTabsComponent_Refetch.graphql";
import { CarouselsTabsComponent_Query } from "@generated/CarouselsTabsComponent_Query.graphql";
import { NodesCarouselSkeleton } from "./NodesCarouselSkeleton";
import styled from "styled-components";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";

const QUERY = graphql`
	query CarouselsTabsComponent_Query {
		...CarouselsTabsComponent_CertificatedNodes_NodeFragment
		...CarouselsTabsComponent_TemporaryBlockedNodes_NodeFragment
	}
`;

const CERTIFICATED_NODES_FRAGMENT = graphql`
	fragment CarouselsTabsComponent_CertificatedNodes_NodeFragment on Query
	@refetchable(queryName: "CarouselsTabsComponent_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" }) {
		LearnV2 {
			CertificatedNodesExpiringInLessThanOneMonth(first: $first, after: $after)
				@connection(key: "CarouselsTabsComponent_CertificatedNodesExpiringInLessThanOneMonth") {
				edges {
					node {
						...NodeCard_NodeFragment
					}
				}
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
			}
		}
	}
`;

const BLOCKED_NODES_FRAGMENT = graphql`
	fragment CarouselsTabsComponent_TemporaryBlockedNodes_NodeFragment on Query
	@refetchable(queryName: "CarouselsTabsComponent_TemporaryBlockedNodes_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" }) {
		LearnV2 {
			GetUserTemporarilyBlockedNodes(first: $first, after: $after)
				@connection(key: "CarouselsTabsComponent_GetUserTemporarilyBlockedNodes") {
				edges {
					node {
						...NodeCard_NodeFragment
					}
				}
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
			}
		}
	}
`;
export const tabHeaderTemplate = (
	options: TabPanelHeaderTemplateOptions,
	title: string,
): React.ReactNode => {
	return (
		<TabHeader selected={options.selected} onClick={options.onClick}>
			<span>{title}</span>
		</TabHeader>
	);
};
export const CarouselsTabsComponent = () => {
	const [isShowingCertificatedTab, setIsShowingCertificatedTab] = useState(false);
	const [isShowingBlockedTab, setIsShowingBlockedTab] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const [isCertificatedTabLoaded, setIsCertificatedTabLoaded] = useState(false);
	const [isBlockedTabLoaded, setIsBlockedTabLoaded] = useState(false);

	const query = useLazyLoadQuery<CarouselsTabsComponent_Query>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const {
		data: certificatedData,
		loadNext: loadNextCert,
		hasNext: hasNextCert,
		refetch: refetchCert,
	} = usePaginationFragment<
		CarouselsTabsComponent_Refetch,
		CarouselsTabsComponent_CertificatedNodes_NodeFragment$key
	>(CERTIFICATED_NODES_FRAGMENT, query);

	const {
		data: blockedData,
		loadNext: loadNextBlocked,
		hasNext: hasNextBlocked,
		refetch: refetchBlocked,
	} = usePaginationFragment<
		CarouselsTabsComponent_TemporaryBlockedNodes_Refetch,
		CarouselsTabsComponent_TemporaryBlockedNodes_NodeFragment$key
	>(BLOCKED_NODES_FRAGMENT, query);

	const certificatedNodes =
		certificatedData?.LearnV2?.CertificatedNodesExpiringInLessThanOneMonth?.edges
			?.filter((e) => !!e?.node)
			.map((e) => e!.node) || [];

	const blockedNodes =
		blockedData?.LearnV2?.GetUserTemporarilyBlockedNodes?.edges
			?.filter((e) => !!e?.node)
			.map((e) => e!.node) || [];

	useEffect(() => {
		if (certificatedNodes.length > 0) {
			setIsShowingCertificatedTab(true);
		} else {
			setIsShowingCertificatedTab(false);
		}
	}, [certificatedNodes]);

	useEffect(() => {
		if (blockedNodes.length > 0) {
			setIsShowingBlockedTab(true);
		} else {
			setIsShowingBlockedTab(false);
		}
	}, [blockedNodes]);

	const handleTabChange = (e: { index: number }) => {
		setActiveIndex(e.index);
		if (e.index === 1 && !isCertificatedTabLoaded) {
			refetchCert({}, { fetchPolicy: "store-and-network" });
			setIsCertificatedTabLoaded(true);
		} else if (e.index === 2 && !isBlockedTabLoaded) {
			refetchBlocked({}, { fetchPolicy: "store-and-network" });
			setIsBlockedTabLoaded(true);
		}
	};

	return (
		<TabsContainer>
			<ResponsiveTabView activeIndex={activeIndex} onTabChange={handleTabChange} className="w-full">
				<TabPanel headerTemplate={(options) => tabHeaderTemplate(options, "Neue Module")}>
					<Suspense fallback={<NodesCarouselSkeleton />}>
						<NewestNodesComponent />
					</Suspense>
				</TabPanel>

				{isShowingCertificatedTab && (
					<TabPanel
						headerTemplate={(options) =>
							tabHeaderTemplate(options, "Zertifizierung endet in Kürze")
						}
					>
						<Suspense fallback={<NodesCarouselSkeleton />}>
							<NodesCarousel
								nodes={certificatedNodes}
								hasNext={hasNextCert}
								onLoadMore={() => loadNextCert(10)}
							/>
						</Suspense>
					</TabPanel>
				)}

				{isShowingBlockedTab && (
					<TabPanel headerTemplate={(options) => tabHeaderTemplate(options, "Gesperrte Module")}>
						<Suspense fallback={<NodesCarouselSkeleton />}>
							<NodesCarousel
								nodes={blockedNodes}
								hasNext={hasNextBlocked}
								onLoadMore={() => loadNextBlocked(10)}
							/>
						</Suspense>
					</TabPanel>
				)}
			</ResponsiveTabView>
		</TabsContainer>
	);
};
const TabsContainer = styled.div`
	position: relative;
	width: 100%;
`;
export const ResponsiveTabView = styled(TabView)`
	@media (max-width: 767px) {
		ul.p-tabview-nav {
			padding: 0;
			width: 75vw;
		}

		ul.p-tabview-nav li {
			margin-bottom: 5px;
		}
		position: relative;
	}

	.p-tabview-panels {
		background: transparent !important;
		padding: 1rem 0 1rem 0;
	}

	.p-tabview-panel {
		background: transparent !important;
	}

	.p-tabview-panel-content {
		background: transparent !important;
	}
	.p-tabview {
		background: transparent !important;
		border: none;
		position: relative;
	}
	.p-tabview-nav {
		background: transparent !important;
		border: none;
		position: relative;
	}
`;

const defaultTextColor = "#000";
export const TabHeader = styled.div<{ selected: boolean }>`
	cursor: pointer;
	border-bottom-width: 1px;
	border-style: none none solid none;
	padding: 0.5rem 0;
	margin-right: 2rem;
	white-space: nowrap;
	border-bottom-color: ${({ selected }) =>
		selected ? LMS_WEBAPP_CONFIG_IMPL.brandColor : "transparent"};
	color: ${({ selected }) => (selected ? LMS_WEBAPP_CONFIG_IMPL.brandColor : defaultTextColor)};
	font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
	margin-bottom: 10px;

	@media screen and (max-width: 1024px) {
		font-size: 14px;
		padding: 1rem 0.8rem;
	}

	&:hover {
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor};
	}
`;
