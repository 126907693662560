import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import styled from "styled-components";
import { Navigate, useMatch } from "react-router-dom";
import { NodeScreen_Query } from "@generated/NodeScreen_Query.graphql";
import { NodeDescription } from "../../infecto-lms-webapp/components/node/NodeDescription.component";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { NodeBreadcrumbs } from "../../infecto-lms-webapp/components/node/NodeBreadcrumbs.component";
import { UserStatusMessage } from "../../infecto-lms-webapp/components/UserStatusMessage.component";
import { NodeConfigurationsDisplay } from "../../infecto-lms-webapp/components/node/NodeConfigurationsDisplay.component";
import { Message } from "primereact/message";
import {
	ElementTypeV2,
	NodeScreen_NodeFragment$key,
} from "@generated/NodeScreen_NodeFragment.graphql";
import {
	getLearnOpportunityPath,
	getLegacyLearnOpportunityPath,
	LEARN_OPPORTUNITY_ID_URL_PARAM,
} from "../../infecto-lms-webapp-impl/router/routes/auth/nodes.routes";
import { NodeLeftFrame } from "../components/NodeLeftFrame.component";
import { NodeInstructors } from "../../infecto-lms-webapp/components/node/NodeInstructors.component";
import { DateTimeDisplay } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { PageDefaultContainer } from "../containers/PageDefaultContainer";
import { WideContainerWithTopBar } from "../containers/WideContainerWithTopBar.container";
import { ShowAnswerButtonContainer } from "../components/node-screen/ShowAnswerButton.container";
import { TreeAdvancementMessageComponent } from "../components/TreeAdvancementMessage.component";
import { NodeStatusMessageComponent } from "../components/NodeStatusMessage.component";

const QUERY = graphql`
	query NodeScreen_Query($learnOpportunityId: ID!) {
		node(id: $learnOpportunityId) {
			id
			... on LearnOpportunityV2 {
				id
				...NodeScreen_NodeFragment
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
					}
				}
			}
		}
	}
`;

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeScreen_NodeFragment on LearnOpportunityV2 {
		id
		image {
			url
		}
		root {
			id
			structureDefinition {
				title
				... on LearnOpportunityRootStructureDefinition {
					extension {
						... on LearnOpportunityRootExtensionImpl {
							dateTimeOnlyAdminsVisibility
							reducedDataInTree {
								elementTypes
							}
							configConsequences {
								rewardExpired
								showAnswerExpired
							}
						}
					}
					icon {
						url
					}
					...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
				}
			}
		}
		shortDescription
		description
		instructors {
			superId
		}
		structureDefinition {
			title
		}
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					id
					typeDefinition {
						... on LearnOpportunityELearningContentTypeDefinition {
							containedElementTypes
						}
					}
					...ShowAnswerButtonComponent_NodeFragment
				}
			}
			definitionType
		}
		...ProjectContentButtonCME_LearnOpportunityFragment
		...AttachmentHeftDisplay_LearnOpportunityV2Fragment
		...NodeBreadcrumbs_NodeFragment
		...NodeConfigurationsDisplay_NodeFragment
		...NodeContentElementsList_NodeFragment
		...AttachmentDisplay_LearnOpportunityV2Fragment
		...NodeDescription_NodeFragment
		...ShowAnswerButtonComponent_NodeFragment
		...TreeAdvancementMessageComponent_NodeFragment
		...NodeStatusMessageComponent_NodeFragment
	}
`;

export function NodeScreen() {
	// TODO: remove after 01.01.2025 - this is temporary here as a fix for the reminder email sent with legacy URIs
	// replace with;
	/*
    const {
		params: { learnOpportunityId },
	} = useMatch(getLearnOpportunityPath(LEARN_OPPORTUNITY_ID_URL_PARAM))!;
     */
	// TO BE REMOVED
	let learnOpportunityId;
	learnOpportunityId = useMatch(getLearnOpportunityPath(LEARN_OPPORTUNITY_ID_URL_PARAM))?.params
		.learnOpportunityId;

	if (!learnOpportunityId) {
		learnOpportunityId = useMatch(getLegacyLearnOpportunityPath(LEARN_OPPORTUNITY_ID_URL_PARAM))
			?.params.learnOpportunityId;
	}
	///////////////////////////////

	const query = useLazyLoadQuery<NodeScreen_Query>(
		QUERY,
		{
			learnOpportunityId: learnOpportunityId!,
		},
		{ fetchPolicy: "network-only" },
	);

	const learnOpportunity = useFragment<NodeScreen_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		query.node,
	)!;

	if (!learnOpportunity) {
		return <Navigate to="*" />;
	}

	usePageTitle(learnOpportunity.structureDefinition.title);

	const onlyAdminVisibilityDateTime =
		learnOpportunity?.root?.structureDefinition?.extension?.dateTimeOnlyAdminsVisibility;

	const hasMultipleChoice =
		learnOpportunity.typeDefinition.children?.some(
			(child: {
				readonly id: string;
				readonly typeDefinition: {
					readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
				};
			}) => child.typeDefinition.containedElementTypes?.includes("multipleChoice"),
		) || false;

	const isModuleExpired =
		learnOpportunity.root?.structureDefinition.extension?.configConsequences?.showAnswerExpired;
	return (
		<PageDefaultContainer
			collapsed={false}
			screenName="learn-opportunity"
			containerId={learnOpportunity.id}
		>
			<WideContainerWithTopBar>
				<div className="flex flex-column lg:flex-row">
					<NodeLeftFrame rootId={learnOpportunity?.root?.id!} />

					<DescriptionAndAttachmentsSideBar className="col-12 xl:col-9 p-0 border-left-1 border-300 flex flex-column flex-grow-1">
						<DescriptionContainer className="flex pl-5 pt-2 pr-5 flex-grow-1 relative md:justify-content-between">
							<div className="flex-grow-1 md:relative md:pr-3" style={{ maxWidth: 1024 }}>
								<div className="hidden md:block">
									<div className="flex flex-row align-items-center justify-content-between">
										<NodeBreadcrumbs learnOpportunityFragmentRef={learnOpportunity} />
										{isModuleExpired && (
											<div className="hidden lg:block">
												<ShowAnswerButtonContainer
													learnOpportunityFragmentRef={learnOpportunity}
													hasMultipleChoice={hasMultipleChoice}
												/>
											</div>
										)}
									</div>
								</div>

								<div className="flex lg:align-items-center flex-column lg:flex-row">
									<Title className="mb-4">{learnOpportunity.structureDefinition.title}</Title>
									{onlyAdminVisibilityDateTime !== null &&
										learnOpportunity?.root?.id === learnOpportunityId && (
											<Message
												className="lg:mt-4 mb-4 w-12 md:w-12 lg:w-4 p-3 lg:ml-4"
												severity="warn"
												content={
													<div>
														Sichtbar ab:{" "}
														<DateTimeDisplay
															hideTimezone
															value={onlyAdminVisibilityDateTime as string}
														/>
													</div>
												}
											/>
										)}
								</div>

								<UserStatusMessage userFragment={query.Viewer.Auth.currentUser?.user!} />
								<TreeAdvancementMessageComponent nodeFragment={learnOpportunity} />

								{learnOpportunity.image?.url && (
									<div className="mb-4">
										<img
											style={{ maxWidth: "100%" }}
											alt={learnOpportunity.structureDefinition.title}
											src={learnOpportunity.image.url}
										/>
									</div>
								)}

								<NodeStatusMessageComponent learnOpportunityFragmentRef={learnOpportunity} />

								{isModuleExpired && (
									<div className="block lg:hidden mt-4 mb-4">
										<ShowAnswerButtonContainer
											learnOpportunityFragmentRef={learnOpportunity}
											hasMultipleChoice={hasMultipleChoice}
										/>
									</div>
								)}

								<NodeDescription learnOpportunityFragmentRef={learnOpportunity} />

								{learnOpportunity?.instructors.length > 0 && (
									<NodeInstructors ids={learnOpportunity.instructors.map((i) => i.superId)} />
								)}

								<NodeConfigurationsDisplay learnOpportunityFragmentRef={learnOpportunity} />
							</div>
						</DescriptionContainer>
					</DescriptionAndAttachmentsSideBar>
				</div>
			</WideContainerWithTopBar>
		</PageDefaultContainer>
	);
}

const DescriptionContainer = styled.div`
	@media (max-width: 768px) {
		padding-bottom: 200px;
	}
	height: 100%;
`;

const DescriptionAndAttachmentsSideBar = styled.div`
	padding-bottom: 200px;
	@media only screen and (min-width: 1020px) {
		overflow: hidden;
	}
`;

const Title = styled.h1`
	word-break: break-all;
`;
