import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { Field, Form, Formik, useFormik } from "formik";
import { CommunicationSettingsScreen_Query } from "@generated/CommunicationSettingsScreen_Query.graphql";
import { CommunicationSettingsScreen_CommunicationSettingsFragment$key } from "@generated/CommunicationSettingsScreen_CommunicationSettingsFragment.graphql";
import { CommunicationSettingsScreen_SetCommunicationSettingsMutation } from "@generated/CommunicationSettingsScreen_SetCommunicationSettingsMutation.graphql";
import { toast } from "react-toastify";
import { CommunicationSettingsScreen_SupplyEfnNumberMutation } from "@generated/CommunicationSettingsScreen_SupplyEfnNumberMutation.graphql";
import { InputText } from "primereact/inputtext";
import { StyledCheckbox } from "../../infecto-lms-webapp-impl/components/relay/ProjectSearchFilters";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";
import { calculateButtonStateColor } from "../../infecto-lms-webapp/functions/helpers/calculateButtonStateColor";
import { PageDefaultContainer } from "../containers/PageDefaultContainer";
import { WideContainerWithTopBar } from "../containers/WideContainerWithTopBar.container";

const QUERY = graphql`
	query CommunicationSettingsScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on InfectopharmUserExtensionImpl {
								salutation
								title
								lastName
								efnNumber
								country
							}
						}
						...DefaultContainer_UserDataFragment
					}
				}
			}

			Email {
				CommunicationSettings {
					...CommunicationSettingsScreen_CommunicationSettingsFragment
				}
			}
		}
	}
`;

const COMMUNICATION_SETTINGS_FRAGMENT = graphql`
	fragment CommunicationSettingsScreen_CommunicationSettingsFragment on CommunicationSettings {
		id
		sendExpirationNoticeEmails
		sendExpirationNoticeNotifications
		sendInformRetryEmails
		sendInformRetryNotifications
	}
`;

const SET_COMMUNICATION_SETTINGS_MUTATION = graphql`
	mutation CommunicationSettingsScreen_SetCommunicationSettingsMutation(
		$input: SetCommunicationSettingsInput!
	) {
		Viewer {
			Email {
				setCommunicationSettings(input: $input) {
					communicationSettings {
						...CommunicationSettingsScreen_CommunicationSettingsFragment
					}
				}
			}
		}
	}
`;

const SUPPLY_EFN_NUMBER_MUTATION = graphql`
	mutation CommunicationSettingsScreen_SupplyEfnNumberMutation($input: SupplyEfnNumberInput!) {
		InfectopharmAuth {
			supplyEfnNumber(input: $input) {
				clientMutationId
				user {
					extension {
						... on InfectopharmUserExtensionImpl {
							salutation
							title
							lastName
							efnNumber
						}
					}
				}
			}
		}
	}
`;

interface SupplyEfnNumberFormikState {
	efnNumber: string;
}

interface FormikState {
	sendExpirationNoticeEmails: boolean;
	sendExpirationNoticeNotifications: boolean;
	sendInformRetryEmails: boolean;
	sendInformRetryNotifications: boolean;
}

export function CommunicationSettingsScreen() {
	const data = useLazyLoadQuery<CommunicationSettingsScreen_Query>(QUERY, {});

	const efnLabel =
		data.Viewer.Auth.currentUser?.user.extension.country === "Deutschland"
			? "EFN-Nummer"
			: "ÖÄK-Arztnummer";
	const efnNumber = data.Viewer.Auth.currentUser?.user.extension.efnNumber || null;

	const communicationSettings =
		useFragment<CommunicationSettingsScreen_CommunicationSettingsFragment$key>(
			COMMUNICATION_SETTINGS_FRAGMENT,
			data.Viewer.Email.CommunicationSettings,
		);
	const [setCommunicationSettings, isSetting] =
		useMutation<CommunicationSettingsScreen_SetCommunicationSettingsMutation>(
			SET_COMMUNICATION_SETTINGS_MUTATION,
		);
	const [supplyEfnNumber, isSupplyingEfnNumber] =
		useMutation<CommunicationSettingsScreen_SupplyEfnNumberMutation>(SUPPLY_EFN_NUMBER_MUTATION);

	const formik = useFormik<FormikState>({
		initialValues: {
			sendExpirationNoticeEmails: communicationSettings.sendExpirationNoticeEmails,
			sendExpirationNoticeNotifications: communicationSettings.sendExpirationNoticeNotifications,
			sendInformRetryEmails: communicationSettings.sendInformRetryEmails,
			sendInformRetryNotifications: communicationSettings.sendInformRetryNotifications,
		},
		onSubmit: (values) => {
			setCommunicationSettings({
				variables: {
					input: {
						...values,
					},
				},
				onCompleted: () => {
					toast.success("Einstellungen erfolgreich gesetzt!");
				},
			});
		},
	});

	return (
		<PageDefaultContainer collapsed={false} screenName="communication-settings">
			<WideContainerWithTopBar>
				<h1 className="mb-5">Kommunikations-Einstellungen</h1>

				<h2 className="mb-4">Profil</h2>
				{efnNumber && (
					<p className="mb-5">{`Ihre ${efnLabel} ist ${
						data.Viewer.Auth.currentUser?.user.extension.efnNumber || ""
					}.`}</p>
				)}
				{!efnNumber && (
					<Formik
						className="mb-5"
						initialValues={{
							efnNumber: efnNumber || "",
						}}
						onSubmit={(values: SupplyEfnNumberFormikState) => {
							supplyEfnNumber({
								variables: {
									input: {
										...values,
									},
								},
								onCompleted: () => {
									toast.success(`${efnLabel} erfolgreich gesetzt!`);
								},
							});
						}}
					>
						<Form>
							<div className="grid p-fluid">
								<div className="col-12">
									<label htmlFor="efnNumber" className="block mb-2">
										{efnLabel}
									</label>
									<div className="p-inputgroup">
										<Field name="efnNumber">
											{(fieldProps: any) => (
												<InputText placeholder={`${efnLabel} eintragen...`} {...fieldProps.field} />
											)}
										</Field>
										<PrimaryButton
											disabled={isSupplyingEfnNumber}
											className="p-button-primary bg-primary border-primary"
											type="submit"
											label="Einreichen"
										/>
									</div>
									{data.Viewer.Auth.currentUser?.user.extension.country === "Oesterreich" && (
										<p>
											Bitte tragen Sie hier Ihre ÖÄK-Arztnummer im Format "3-6 Ziffern - Bindestrich
											- 2 Ziffern" (z.B. 12345-67) und ohne führenden Buchstaben ein. Sie benötigen
											diese, um auf der Lernplattform zertifizierte Module bearbeiten zu können.
										</p>
									)}
								</div>
							</div>
						</Form>
					</Formik>
				)}

				<h2 className="mb-4">Newsletter</h2>
				<p className="mb-5">
					Ihre Newsletter-Einstellungen können Sie bequem in Ihrem{" "}
					<a
						className="text-primary"
						target="_blank"
						rel="noopener noreferrer"
						href={`${process.env.REACT_APP_USER_SYSTEM_BASE}/login/${LMS_WEBAPP_CONFIG_IMPL.brandId}/?origin=${process.env.REACT_APP_REDIRECT_BASE_64_APP_BASE}`}
					>
						InfectoPharm Benutzerkonto
					</a>{" "}
					ändern.
				</p>

				<h2 className="mb-4">E-Mails</h2>

				<div className="col-12 mb-4 flex align-items-center">
					<StyledCheckbox
						colorPress={calculateButtonStateColor().pressColor}
						color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
						className="mr-3"
						inputId="sendExpirationNoticeEmails"
						disabled={isSetting}
						checked={formik.values.sendExpirationNoticeEmails}
						onChange={(e) => {
							formik.setFieldValue("sendExpirationNoticeEmails", e.checked);
							formik.handleSubmit();
						}}
					/>
					<label htmlFor="sendExpirationNoticeEmails" className="p-checkbox-label">
						Bitte informieren Sie mich per E-Mail, wenn ein Modul abgelaufen ist und die Lösungen
						eingesehen werden können.
					</label>
				</div>

				<div className="col-12 mb-5 flex align-items-center">
					<StyledCheckbox
						colorPress={calculateButtonStateColor().pressColor}
						color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
						className="mr-3"
						inputId="sendInformRetryEmails"
						disabled={isSetting}
						checked={formik.values.sendInformRetryEmails}
						onChange={(e) => {
							formik.setFieldValue("sendInformRetryEmails", e.checked);
							formik.handleSubmit();
						}}
					/>
					<label htmlFor="sendInformRetryEmails" className="p-checkbox-label">
						Bitte informieren Sie mich per E-Mail, wenn ich ein in der Vergangenheit falsch
						beantwortetes Modul nach Sperrfrist erneut beantworten kann.
					</label>
				</div>

				<h2 className="mb-4">Mobile Benachrichtigungen</h2>

				<div className="col-12 mb-4 flex align-items-center">
					<StyledCheckbox
						colorPress={calculateButtonStateColor().pressColor}
						color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
						className="mr-3"
						inputId="sendExpirationNoticeNotifications"
						disabled={isSetting}
						checked={formik.values.sendExpirationNoticeNotifications}
						onChange={(e) => {
							formik.setFieldValue("sendExpirationNoticeNotifications", e.checked);
							formik.handleSubmit();
						}}
					/>
					<label htmlFor="sendExpirationNoticeNotifications" className="p-checkbox-label">
						Bitte informieren Sie mich per App-Benachrichtigung, wenn ein Modul abgelaufen ist und
						die Lösungen eingesehen werden können.
					</label>
				</div>

				<div className="col-12 mb-5 flex align-items-center">
					<StyledCheckbox
						colorPress={calculateButtonStateColor().pressColor}
						color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
						className="mr-3"
						inputId="sendInformRetryNotifications"
						disabled={isSetting}
						checked={formik.values.sendInformRetryNotifications}
						onChange={(e) => {
							formik.setFieldValue("sendInformRetryNotifications", e.checked);
							formik.handleSubmit();
						}}
					/>
					<label htmlFor="sendInformRetryNotifications" className="p-checkbox-label">
						Bitte informieren Sie mich per App-Benachrichtigung, wenn ich ein in der Vergangenheit
						falsch beantwortetes Modul nach Sperrfrist erneut beantworten kann.
					</label>
				</div>
			</WideContainerWithTopBar>
		</PageDefaultContainer>
	);
}
