import React from "react";
import styled from "styled-components";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
interface IconProps {
	color?: string;
}
export const DashboardIcon = ({ color }: IconProps) => (
	<IconWrapperWithHover width="33" height="33" viewBox="0 0 33 33" fill="none">
		<path
			d="M12.5 28.5V18.6333C12.5 17.8866 12.5 17.5132 12.6453 17.228C12.7732 16.9771 12.9771 16.7731 13.228 16.6453C13.5132 16.5 13.8866 16.5 14.6333 16.5H18.3667C19.1134 16.5 19.4868 16.5 19.772 16.6453C20.0229 16.7731 20.2268 16.9771 20.3547 17.228C20.5 17.5132 20.5 17.8866 20.5 18.6333V28.5M15.1903 4.18533L6.14719 11.2188C5.54269 11.689 5.24045 11.9241 5.0227 12.2185C4.82982 12.4793 4.68614 12.773 4.5987 13.0854C4.5 13.438 4.5 13.8209 4.5 14.5867V24.2333C4.5 25.7268 4.5 26.4735 4.79065 27.044C5.04631 27.5457 5.45426 27.9537 5.95603 28.2093C6.52646 28.5 7.27319 28.5 8.76667 28.5H24.2333C25.7268 28.5 26.4735 28.5 27.044 28.2093C27.5457 27.9537 27.9537 27.5457 28.2094 27.044C28.5 26.4735 28.5 25.7268 28.5 24.2333V14.5867C28.5 13.8209 28.5 13.438 28.4013 13.0854C28.3139 12.773 28.1702 12.4793 27.9773 12.2185C27.7596 11.9241 27.4573 11.689 26.8528 11.2188L17.8097 4.18533C17.3413 3.82099 17.1071 3.63883 16.8485 3.5688C16.6203 3.50701 16.3797 3.50701 16.1515 3.5688C15.8929 3.63883 15.6587 3.82099 15.1903 4.18533Z"
			stroke={color ? color : "black"}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapperWithHover>
);

export const ModulesPageIcon = ({ color }: IconProps) => (
	<IconWrapperWithHover width="33" height="33" viewBox="0 0 33 33" fill="none">
		<path
			d="M27.8333 10.2037L16.5 16.5M16.5 16.5L5.16662 10.2037M16.5 16.5L16.5 29.1667M28.5 21.9114V11.0886C28.5 10.6318 28.5 10.4033 28.4327 10.1996C28.3731 10.0194 28.2758 9.85393 28.1472 9.71434C28.0018 9.55656 27.8021 9.44563 27.4027 9.22376L17.536 3.74227C17.1579 3.5322 16.9688 3.42716 16.7686 3.38598C16.5914 3.34953 16.4086 3.34953 16.2314 3.38598C16.0312 3.42716 15.8421 3.5322 15.464 3.74228L5.59729 9.22376C5.19793 9.44563 4.99825 9.55656 4.85284 9.71435C4.72421 9.85393 4.62686 10.0194 4.56731 10.1996C4.5 10.4033 4.5 10.6318 4.5 11.0886V21.9114C4.5 22.3683 4.5 22.5967 4.56731 22.8005C4.62686 22.9807 4.72421 23.1461 4.85284 23.2857C4.99825 23.4435 5.19793 23.5544 5.5973 23.7763L15.464 29.2578C15.8421 29.4679 16.0312 29.5729 16.2314 29.6141C16.4086 29.6505 16.5914 29.6505 16.7686 29.6141C16.9688 29.5729 17.1579 29.4679 17.536 29.2578L27.4027 23.7763C27.8021 23.5544 28.0018 23.4435 28.1472 23.2857C28.2758 23.1461 28.3731 22.9807 28.4327 22.8005C28.5 22.5967 28.5 22.3683 28.5 21.9114Z"
			stroke={color ? color : "black"}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapperWithHover>
);

export const QuestionLinkIcon = () => (
	<IconWrapperWithHover width="33" height="33" viewBox="0 0 33 33" fill="none">
		<path
			d="M14.4995 12.503C14.7344 11.8351 15.1981 11.272 15.8085 10.9133C16.4188 10.5546 17.1364 10.4235 17.8342 10.5432C18.5319 10.6628 19.1648 11.0256 19.6207 11.5672C20.0767 12.1088 20.3262 12.7943 20.3251 13.5022C20.3251 15.5007 17.3274 16.5 17.3274 16.5M17.366 20.5H17.3793M17.1661 27.1667C23.4254 27.1667 28.4995 22.0926 28.4995 15.8333C28.4995 9.57411 23.4254 4.5 17.1661 4.5C10.9069 4.5 5.83281 9.57411 5.83281 15.8333C5.83281 17.1 6.04061 18.3181 6.42397 19.4555C6.56824 19.8835 6.64037 20.0975 6.65338 20.2619C6.66623 20.4243 6.65652 20.5381 6.61635 20.6959C6.57568 20.8558 6.48587 21.022 6.30627 21.3544L4.12539 25.3911C3.81431 25.9669 3.65877 26.2548 3.69358 26.477C3.7239 26.6706 3.83781 26.841 4.00504 26.943C4.19705 27.0601 4.52254 27.0265 5.17353 26.9592L12.0016 26.2534C12.2083 26.232 12.3117 26.2213 12.406 26.2249C12.4986 26.2284 12.5641 26.2371 12.6545 26.258C12.7464 26.2792 12.8619 26.3237 13.093 26.4127C14.3571 26.8997 15.7304 27.1667 17.1661 27.1667Z"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapperWithHover>
);

export const TrainingPageIcon = () => (
	<IconWrapperWithHover width="33" height="33" viewBox="0 0 33 33" fill="none">
		<path
			d="M28.5 13.8333H4.5M21.8333 3.16663V8.49996M11.1667 3.16663V8.49996M10.9 29.8333H22.1C24.3402 29.8333 25.4603 29.8333 26.316 29.3973C27.0686 29.0138 27.6805 28.4019 28.064 27.6493C28.5 26.7936 28.5 25.6735 28.5 23.4333V12.2333C28.5 9.99308 28.5 8.87298 28.064 8.01733C27.6805 7.26468 27.0686 6.65276 26.316 6.26927C25.4603 5.83329 24.3402 5.83329 22.1 5.83329H10.9C8.65979 5.83329 7.53969 5.83329 6.68404 6.26927C5.93139 6.65276 5.31947 7.26468 4.93597 8.01733C4.5 8.87298 4.5 9.99308 4.5 12.2333V23.4333C4.5 25.6735 4.5 26.7936 4.93597 27.6493C5.31947 28.4019 5.93139 29.0138 6.68404 29.3973C7.53969 29.8333 8.65979 29.8333 10.9 29.8333Z"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapperWithHover>
);

export const LearningAchievementIcon = ({ color }: IconProps) => (
	<IconWrapperWithHover width="33" height="33" viewBox="0 0 33 33" fill="none">
		<path
			d="M11.1223 20.1292L9.83335 29.8333L15.9512 26.1626C16.1508 26.0429 16.2505 25.983 16.357 25.9596C16.4512 25.9389 16.5488 25.9389 16.643 25.9596C16.7495 25.983 16.8493 26.0429 17.0488 26.1626L23.1667 29.8333L21.8791 20.1283M25.8334 12.5C25.8334 17.6546 21.6547 21.8333 16.5 21.8333C11.3454 21.8333 7.16669 17.6546 7.16669 12.5C7.16669 7.3453 11.3454 3.16663 16.5 3.16663C21.6547 3.16663 25.8334 7.3453 25.8334 12.5Z"
			stroke={color ? color : "black"}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapperWithHover>
);

export const ContactLinkIcon = () => (
	<IconWrapperWithHover width="33" height="32" viewBox="0 0 33 32" fill="none">
		<path
			d="M8.62584 14.9718C8.54296 14.4377 8.49997 13.8906 8.49997 13.3334C8.49997 7.44238 13.307 2.66675 19.2368 2.66675C25.1666 2.66675 29.9737 7.44238 29.9737 13.3334C29.9737 14.6642 29.7284 15.938 29.2802 17.1128C29.1872 17.3567 29.1406 17.4787 29.1195 17.574C29.0985 17.6683 29.0905 17.7347 29.0882 17.8314C29.0859 17.9289 29.0991 18.0363 29.1256 18.2512L29.6624 22.6114C29.7205 23.0835 29.7495 23.3195 29.671 23.491C29.6022 23.6414 29.48 23.7608 29.3281 23.8261C29.1548 23.9006 28.9195 23.8661 28.449 23.7972L24.202 23.1746C23.9802 23.1421 23.8693 23.1259 23.7683 23.1264C23.6684 23.127 23.5993 23.1344 23.5016 23.1549C23.4027 23.1757 23.2765 23.223 23.024 23.3176C21.8463 23.7587 20.5699 24.0001 19.2368 24.0001C18.6792 24.0001 18.1316 23.9579 17.5969 23.8764M10.6755 29.3334C14.6287 29.3334 17.8334 26.0502 17.8334 22.0001C17.8334 17.95 14.6287 14.6667 10.6755 14.6667C6.72226 14.6667 3.51756 17.95 3.51756 22.0001C3.51756 22.8142 3.64705 23.5973 3.88609 24.3291C3.98713 24.6384 4.03765 24.793 4.05423 24.8987C4.07154 25.009 4.07457 25.0709 4.06813 25.1824C4.06195 25.2891 4.03524 25.4098 3.98181 25.6512L3.16669 29.3334L7.15976 28.7881C7.37771 28.7583 7.48668 28.7434 7.58185 28.7441C7.68205 28.7448 7.73523 28.7502 7.8335 28.7698C7.92683 28.7884 8.06557 28.8373 8.34305 28.9353C9.07414 29.1933 9.85883 29.3334 10.6755 29.3334Z"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapperWithHover>
);

interface IconProps {
	width?: number;
	height?: number;
}
export const UserIcon = ({ width, height }: IconProps) => (
	<IconWrapperWithHover
		width={width ? width : 40}
		height={height ? height : 40}
		viewBox="0 0 40 40"
		fill="none"
	>
		<path
			d="M20 25C14.7165 25 10.018 27.5511 7.02661 31.5099C6.3828 32.362 6.06089 32.788 6.07142 33.3638C6.07955 33.8086 6.35889 34.3698 6.7089 34.6445C7.16193 35 7.78974 35 9.04534 35H30.9546C32.2102 35 32.838 35 33.291 34.6445C33.641 34.3698 33.9204 33.8086 33.9285 33.3638C33.939 32.788 33.6171 32.362 32.9733 31.5099C29.982 27.5511 25.2834 25 20 25Z"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20 20C24.1421 20 27.5 16.6421 27.5 12.5C27.5 8.35786 24.1421 5 20 5C15.8578 5 12.5 8.35786 12.5 12.5C12.5 16.6421 15.8578 20 20 20Z"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapperWithHover>
);

export const IconWrapperWithHover = styled.svg`
	&:hover path {
		stroke: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
	}
`;
