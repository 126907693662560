/**
 * @generated SignedSource<<2742e8eae570b629a4585c83d19c9ea2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow" | "%future added value";
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_CanNot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NodeCard_NodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly extension?: {
      readonly configConsequences?: {
        readonly isRewardedTo: string | null;
        readonly rewardExpired: boolean;
      };
      readonly firstLearnOpportunityIdWithMultipleChoiceElement?: string | null;
      readonly firstReleasedAt?: string | null;
      readonly heftContentSubmissionInfo?: {
        readonly hasActiveContentSubmission: boolean;
      };
      readonly isVisibleOnlyForAdmins?: boolean;
      readonly issuedCertificates?: ReadonlyArray<{
        readonly fileRef: {
          readonly url: string | null;
        } | null;
        readonly issuedAt: string;
      }>;
      readonly reducedDataInTree?: {
        readonly cmePoints: number | null;
      };
    };
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly children?: ReadonlyArray<{
      readonly id: string;
      readonly typeDefinition: {
        readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
        readonly contentNodeAdvancementResult?: {
          readonly configResults?: ReadonlyArray<{
            readonly blockedUntil?: string;
            readonly canBeRestarted: boolean;
            readonly configType: RestartIfFailedContentConfigType;
          }>;
          readonly status: ContentNodeAdvancementStatus;
        };
      };
    }>;
    readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    readonly contentNodeAdvancementResult?: {
      readonly configResults?: ReadonlyArray<{
        readonly blockedUntil?: string;
        readonly canBeRestarted: boolean;
        readonly configType: RestartIfFailedContentConfigType;
      }>;
      readonly status: ContentNodeAdvancementStatus;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"NodeCardBadgeComponent_Fragment" | "NodeCardCertificateButton_Fragment" | "NodeCardDateComponent_Fragment" | "NodeCardDurationAndAwardComponent_Fragment" | "NodeCardFooterContainer_LearnOpportunityFragment" | "NodeIcon_NodeFragment">;
  readonly " $fragmentType": "NodeCard_NodeFragment";
};
export type NodeCard_NodeFragment$key = {
  readonly " $data"?: NodeCard_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeCard_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "containedElementTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "contentNodeAdvancementResult",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "configResults",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canBeRestarted",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "blockedUntil",
                      "storageKey": null
                    }
                  ],
                  "type": "NegativeBlockTemporarilyWhenRewardedRestartIfFailedContentConfigResult",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "CanNotBeRestartedAfterFailedContentNodeAdvancementResult",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityELearningContentTypeDefinition",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeCard_NodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isVisibleOnlyForAdmins",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IssuedCertificateV2",
                      "kind": "LinkedField",
                      "name": "issuedCertificates",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "issuedAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "File",
                          "kind": "LinkedField",
                          "name": "fileRef",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReducedDataInTree",
                      "kind": "LinkedField",
                      "name": "reducedDataInTree",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cmePoints",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstReleasedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstLearnOpportunityIdWithMultipleChoiceElement",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentSubmissionInfo",
                      "kind": "LinkedField",
                      "name": "heftContentSubmissionInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasActiveContentSubmission",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "InfectopharmTreeConfigConsequences",
                      "kind": "LinkedField",
                      "name": "configConsequences",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rewardExpired",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isRewardedTo",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunityV2",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeDefinition",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityBranchTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeIcon_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeCardBadgeComponent_Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeCardDateComponent_Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeCardDurationAndAwardComponent_Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeCardFooterContainer_LearnOpportunityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeCardCertificateButton_Fragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "867ef55094cd75d94a55a65a680f9e13";

export default node;
