import React, { useEffect, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { LearningAchievementsTable_Query } from "@generated/LearningAchievementsTable_Query.graphql";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDateTime } from "../../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { BookIcon, DownloadIcon, PodcastIcon, VideoIcon } from "../nodecard/SvgNodeIcons.component";
import styled from "styled-components";
import { ShowOnlyCertifiedCheckboxComponent } from "./ShowOnlyCertifiedCheckbox.component";
import { UserHasNoStartedNodesComponent } from "../dashboard/UserHasNoStartedNodes.component";
import { ElementTypeV2 } from "@generated/NodeCard_NodeFragment.graphql";
import { IconAndFormatTypeContainer } from "../../containers/IconAndFormatType.container";
import { IconColor } from "../nodecard/NodeIcon.component";
import { sortArrayOfObjectsUsingAField } from "../../../infecto-lms-webapp/functions/helpers/sortArrayOfObjectsUsingAField";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { FeedbackPopup } from "../navigation/FeedbackPopup";
import { useTypedDispatch } from "../../../infecto-lms-webapp/redux/redux-store";
import {
	selectIsFeedbackPopupClosedByUser,
	setIsFeedbackPopupClosedByUser,
} from "../../../infecto-lms-webapp/redux/slices/app.slice";
import { useSelector } from "react-redux";

const QUERY = graphql`
	query LearningAchievementsTable_Query($isCertified: Boolean) {
		LearnV2 {
			LearningAchievements(isCertified: $isCertified) {
				finishedAt
				title
				categoryOpt
				formatTypes
				areCmePointsExported
				points
				issuedCertificateOpt {
					fileRef {
						url
					}
				}
			}
		}
	}
`;

export const LearningAchievementsTable = () => {
	const [isShowingOnlyCertified, setIsShowingOnlyCertified] = useState(false);
	const [visibleModal, setVisibleModal] = useState(false);
	const query = useLazyLoadQuery<LearningAchievementsTable_Query>(QUERY, {
		isCertified: isShowingOnlyCertified,
	});
	const dispatch = useTypedDispatch();
	const popupClosed = useSelector(selectIsFeedbackPopupClosedByUser);

	const closeFeedbackPopup = () => {
		dispatch(setIsFeedbackPopupClosedByUser(true));
	};

	useEffect(() => {
		if (!popupClosed && learningAchievementsData.length > 0) {
			setTimeout(() => setVisibleModal(true), 2000);
		}
	}, []);

	const learningAchievementsData = query.LearnV2.LearningAchievements;

	const noShowCategoryString = "noValue";

	const learningAchievements = [
		...learningAchievementsData.map((la) => {
			return {
				...la,
				categoryOpt: la.categoryOpt && la.points > 0 ? la.categoryOpt : noShowCategoryString,
			};
		}),
	];

	const [sortField, setSortField] = useState<string>("finishedAt");
	const [sortOrder, setSortOrder] = useState<any>(-1);
	const [sortedLearningAchievements, setSortedLearningAchievements] = useState(
		sortArrayOfObjectsUsingAField(learningAchievements, "finishedAt", "desc"),
	);

	useEffect(() => {
		setSortedLearningAchievements(
			sortArrayOfObjectsUsingAField(learningAchievements, sortField, sortOrder),
		);
	}, [isShowingOnlyCertified, sortField, sortOrder]);

	const onSort = (e: any) => {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
		setSortedLearningAchievements(
			sortArrayOfObjectsUsingAField(
				learningAchievements,
				e.sortField,
				e.sortOrder > 0 ? "asc" : "desc",
			),
		);
	};

	const arePointsExported = (item: any) => {
		if (item.points > 0 && item.areCmePointsExported) {
			return "Ja";
		} else if (item.points > 0 && !item.areCmePointsExported) {
			return "Nein";
		} else {
			return "-";
		}
	};

	const formatTypeWithIcon = (formatType: ElementTypeV2) => {
		switch (formatType) {
			case "file":
				return (
					<IconAndFormatTypeContainer>
						<BookIcon width={19} height={19} target={"book"} color={IconColor} />
						<FormatTypeText>Heft</FormatTypeText>
					</IconAndFormatTypeContainer>
				);

			case "video":
				return (
					<IconAndFormatTypeContainer>
						<VideoIcon width={19} height={19} target={"book"} color={IconColor} />
						<FormatTypeText>Video</FormatTypeText>
					</IconAndFormatTypeContainer>
				);

			case "podcast":
				return (
					<IconAndFormatTypeContainer>
						<PodcastIcon width={19} height={19} target={"book"} color={IconColor} />
						<FormatTypeText>Podcast</FormatTypeText>
					</IconAndFormatTypeContainer>
				);

			default:
				<span>-</span>;
		}
	};
	return (
		<div>
			{learningAchievementsData && learningAchievementsData.length > 0 ? (
				<div>
					<FeedbackPopup
						modalVisible={visibleModal}
						setVisible={setVisibleModal}
						onHide={closeFeedbackPopup}
					/>
					<div>
						<ShowOnlyCertifiedCheckboxComponent
							isShowingOnlyCertified={isShowingOnlyCertified}
							setIsShowingOnlyCertified={setIsShowingOnlyCertified}
						/>
					</div>

					<ScrollbarDataTableContainer className="my-4">
						<StyledTable
							emptyMessage="Keine Lernfolgs"
							className="mb-3"
							value={sortedLearningAchievements as any[]}
							stripedRows
							sortField={sortField}
							sortOrder={sortOrder}
							onSort={onSort}
							responsiveLayout="scroll"
						>
							<Column
								sortable
								field="finishedAt"
								header="Absolviert am"
								body={(row) => <SpanText>{formatDateTime(row.finishedAt, false)}</SpanText>}
							/>
							<Column
								sortable
								field="title"
								header="Titel"
								body={(row) => <SpanText>{row.title}</SpanText>}
							/>
							<Column
								header="Format"
								body={(row) =>
									row.formatTypes.map((format: ElementTypeV2) => formatTypeWithIcon(format))
								}
							/>
							<Column
								header="Kategorie"
								sortable
								field="categoryOpt"
								body={(row) =>
									row.categoryOpt !== noShowCategoryString ? (
										<SpanText>{row.categoryOpt}</SpanText>
									) : (
										<SpanText>-</SpanText>
									)
								}
							/>
							<Column
								header="CME-Punkte"
								sortable
								field="points"
								body={(row) =>
									row.points > 0 ? (
										<SpanText>{row.points}</SpanText>
									) : (
										<SpanText>Übungsmodul</SpanText>
									)
								}
							/>
							<Column
								header="An Ärztekammer gemeldet"
								body={(row) => <SpanText>{arePointsExported(row)}</SpanText>}
							/>
							<Column
								header="Zertifikat"
								body={(row) =>
									row.issuedCertificateOpt ? (
										<a
											target="_blank"
											rel="noopener noreferrer"
											download
											href={row.issuedCertificateOpt.fileRef.url}
										>
											<div className="flex flex-row justify-content-between align-items-center">
												<SpanText className="mr-2">Download</SpanText>
												<DownloadIcon></DownloadIcon>
											</div>
										</a>
									) : (
										<SpanText></SpanText>
									)
								}
							/>
						</StyledTable>
					</ScrollbarDataTableContainer>
				</div>
			) : (
				<UserHasNoStartedNodesComponent />
			)}
		</div>
	);
};

const ScrollbarDataTableContainer = styled.div`
	overflow-x: auto;
	overflow-y: hidden;
	scroll-behavior: smooth;
	scrollbar-width: none;
	overscroll-behavior: contain auto;
	position: relative;
`;

const FormatTypeText = styled.span`
	margin-left: 5px;
	margin-top: 0;
	margin-bottom: 0;
	color: ${IconColor};
`;

const SpanText = styled.span`
	color: ${IconColor};
`;
const headerBackgroundColor = "#f8f9fa";
const StyledTable = styled(DataTable)`
	.p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
		background-color: ${headerBackgroundColor} !important;
	}
	.p-sortable-column.p-highlight {
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
	}
	.p-sortable-column.p-highlight .p-sortable-column-icon {
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
	}
	.p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
	}
	.p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
		background-color: ${headerBackgroundColor} !important;
	}
	.p-sortable-column:focus {
		box-shadow: none !important;
	}
	.p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
	}
	.p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
	}
`;
