import React from "react";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { ErrorPageComponent } from "../../infecto-lms-webapp/components/ErrorPage.component";
import { PageDefaultContainer } from "../containers/PageDefaultContainer";

export const PageNotFoundScreen = () => {
	usePageTitle("PageNotFound");
	return (
		<PageDefaultContainer collapsed={false} screenName="PageNotFound">
			<MainContainerWithTopBar>
				<ErrorPageComponent
					errorType={"404"}
					title={"Die gewünschte Seite wurde nicht gefunden."}
					description={
						"Entschuldigung, die Seite, die Sie suchen, konnte nicht gefunden werden oder wurde entfernt."
					}
				/>
			</MainContainerWithTopBar>
		</PageDefaultContainer>
	);
};
