import React from "react";
import "./style.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "react-toastify/dist/ReactToastify.css";
import { createRoot } from "react-dom/client";
import App from "./app/app";
import { Persistor, ReduxStore } from "./infecto-lms-webapp/redux/redux-store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
	<React.StrictMode>
		<Provider store={ReduxStore}>
			<PersistGate loading={null} persistor={Persistor}>
				<App />
			</PersistGate>
		</Provider>
	</React.StrictMode>,
);
