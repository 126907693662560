import React from "react";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { ErrorPageComponent } from "../../infecto-lms-webapp/components/ErrorPage.component";
import { PageDefaultContainer } from "../containers/PageDefaultContainer";

export const ServerErrorScreen = () => {
	usePageTitle("Server Error");

	return (
		<PageDefaultContainer collapsed={false} screenName="ServerError">
			<MainContainerWithTopBar>
				<ErrorPageComponent
					errorType={"Oops!"}
					title={"Etwas ist schiefgelaufen..."}
					description={
						"Es tut uns leid, aber auf dem Server ist ein unerwarteter Fehler aufgetreten."
					}
				/>
			</MainContainerWithTopBar>
		</PageDefaultContainer>
	);
};
