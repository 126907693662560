import React from "react";
import styled from "styled-components";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";

const CardWrapper = styled.div<{
	isVisibleOnlyForAdmins: boolean;
	brandColor: string;
}>`
	height: 100%;
	display: inline-flex;
	padding: 24px;
	align-items: flex-start;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 4px;
	background: #fff;
	width: 100%;
	border: ${(props) =>
		props.isVisibleOnlyForAdmins ? `1px solid  ${props.brandColor}` : "border: none;"};

	&:hover {
		box-shadow: 0 20px 64px 0 rgba(16, 24, 40, 0.08);
	}
`;

interface OwnProps {
	isVisibleOnlyForAdmins: boolean;
	onClick: () => void;
	children: any;
}

export const NodeCardContainer = ({ isVisibleOnlyForAdmins, children, onClick }: OwnProps) => {
	return (
		<CardWrapper
			onClick={onClick}
			isVisibleOnlyForAdmins={isVisibleOnlyForAdmins}
			brandColor={LMS_WEBAPP_CONFIG_IMPL.brandColor}
		>
			{children}
		</CardWrapper>
	);
};
