import React from "react";
import styled from "styled-components";

interface OwnProps {
	children: any;
}

export const NodeCardContentContainer = ({ children }: OwnProps) => {
	return <CardContentWrapper>{children}</CardContentWrapper>;
};

const CardContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	gap: 24px;
	justify-content: space-between;
	height: 100%;
`;
