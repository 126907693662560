/**
 * @generated SignedSource<<abdd6e3b8dc5fd802110ba7f48250fa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TreeAdvancementMessageComponent_NodeFragment$data = {
  readonly structureDefinition: {
    readonly viewerTreeState?: {
      readonly kind: ViewerTreeStateKind;
    };
  };
  readonly " $fragmentType": "TreeAdvancementMessageComponent_NodeFragment";
};
export type TreeAdvancementMessageComponent_NodeFragment$key = {
  readonly " $data"?: TreeAdvancementMessageComponent_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TreeAdvancementMessageComponent_NodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TreeAdvancementMessageComponent_NodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "viewerTreeState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "bed684e6e68a9ac03c9f7f4428d04a70";

export default node;
