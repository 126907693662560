import React, { Suspense } from "react";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { AllNodesSearchContextProvider } from "../../infecto-lms-webapp/context/search-context/AllNodesSearchContextProvider";
import { SearchNodesCarouselComponent } from "../components/search/SearchNodesCarousel.component";
import { SearchTagsFilter } from "../components/search/SearchTagsFilter";
import { CarouselContainer } from "../containers/Carousel.container";
import { SearchNodesCarouselSkeletonComponent } from "../components/search/SearchNodesCarouselSkeleton.component";
import { WideContainerWithTopBar } from "../containers/WideContainerWithTopBar.container";
import { PageMainText } from "../components/PageTitleText";
import { PageDefaultContainer } from "../containers/PageDefaultContainer";

export const SearchNodesScreen = () => {
	usePageTitle("Suche");

	return (
		<AllNodesSearchContextProvider>
			<PageDefaultContainer collapsed={false} screenName="Suche">
				<WideContainerWithTopBar>
					<div className="flex flex-column">
						<div className="flex flex-column align-items-center xl:align-items-start">
							<PageMainText className="xl:pl-4 pt-4">Module</PageMainText>
							<div className="mt-3 xl:pl-4 flex row w-full justify-content-center lg:justify-content-end">
								<SearchTagsFilter />
							</div>
						</div>
						<CarouselContainer className="mt-4">
							<Suspense fallback={<SearchNodesCarouselSkeletonComponent />}>
								<SearchNodesCarouselComponent elementType={"file"} />
							</Suspense>
							<Suspense fallback={<SearchNodesCarouselSkeletonComponent />}>
								<SearchNodesCarouselComponent elementType={"video"} />
							</Suspense>
							<Suspense fallback={<SearchNodesCarouselSkeletonComponent />}>
								<SearchNodesCarouselComponent elementType={"podcast"} />
							</Suspense>
						</CarouselContainer>
					</div>
				</WideContainerWithTopBar>
			</PageDefaultContainer>
		</AllNodesSearchContextProvider>
	);
};
