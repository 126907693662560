import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styled from "styled-components";
import {
	NodeCardProgressBarComponent,
	ProgressBarState,
} from "../components/nodecard/NodeCardProgressBar.component";
import { NodeCardFooterContainer_LearnOpportunityFragment$key } from "@generated/NodeCardFooterContainer_LearnOpportunityFragment.graphql";
import { MultipleChoiceElements } from "../../infecto-lms-webapp-impl/components/NodeCard.component";
import { NodeCardLearnProgressComponent } from "../components/nodecard/NodeCardLearnProgress.component";
import { NodeCardProgressBarTextComponent } from "../components/nodecard/NodeCardProgressBarText.component";

const TREE_ADVANCEMENT_RESULT_FRAGMENT = graphql`
	fragment NodeCardFooterContainer_LearnOpportunityFragment on LearnOpportunityV2 {
		id
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						firstLearnOpportunityIdWithMultipleChoiceElement
						heftContentSubmissionInfo {
							hasActiveContentSubmission
						}
					}
				}
			}
		}
		...NodeCardLearnProgressComponent_Fragment
		typeDefinition {
			... on LearnOpportunityELearningContentTypeDefinition {
				containedElementTypes
			}
			... on LearnOpportunityBranchTypeDefinition {
				children {
					...NodeCardLearnProgressComponent_Fragment
					id
					typeDefinition {
						... on LearnOpportunityELearningContentTypeDefinition {
							containedElementTypes
						}
					}
				}
			}
		}
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	width: 100%;
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeCardFooterContainer_LearnOpportunityFragment$key;
	componentColor: string;
	progressBarState: ProgressBarState;
}

export const NodeCardFooterContainer = ({
	learnOpportunityFragmentRef,
	componentColor,
	progressBarState,
}: OwnProps) => {
	const learnOpportunity = useFragment<NodeCardFooterContainer_LearnOpportunityFragment$key>(
		TREE_ADVANCEMENT_RESULT_FRAGMENT,
		learnOpportunityFragmentRef,
	);
	const extension = learnOpportunity?.structureDefinition.extension;

	const children = learnOpportunity?.typeDefinition.children;

	const containedElementTypes = learnOpportunity?.typeDefinition.containedElementTypes;

	const hasActiveContentSubmission =
		extension?.heftContentSubmissionInfo?.hasActiveContentSubmission;
	const hasContentModuleWithMultipleChoiceElement =
		extension?.firstLearnOpportunityIdWithMultipleChoiceElement;

	let content;

	if (hasContentModuleWithMultipleChoiceElement) {
		if (children) {
			content = children.map((c) => {
				const hasMultipleChoiceChild = c.typeDefinition.containedElementTypes?.some((el) =>
					el.includes(MultipleChoiceElements),
				);
				//Find the child with MultipleChoiceElements and give it as props to the component. If the child has no MultiplechoiceElement return null
				if (hasMultipleChoiceChild) {
					return (
						<NodeCardLearnProgressComponent
							key={c.id}
							progressBarState={progressBarState}
							componentColor={componentColor}
							learnOpportunityFragmentRef={c}
						/>
					);
				}
				return null;
			});
			// If it is a module with the old structure (which has no children), check if the root content contains MultipleChoiceElements.
		} else if (containedElementTypes?.some((el) => el.includes(MultipleChoiceElements))) {
			content = (
				<NodeCardLearnProgressComponent
					progressBarState={progressBarState}
					componentColor={componentColor}
					learnOpportunityFragmentRef={learnOpportunity}
				/>
			);
		}
	} else {
		// Modul doesn't contain MultiplechoiceElements
		content = (
			<div className="flex-column w-full">
				<NodeCardProgressBarTextComponent isBold={false}>
					Ohne Lernerfolgskontrolle
				</NodeCardProgressBarTextComponent>

				<NodeCardProgressBarComponent
					progressBarState={progressBarState}
					componentColor={componentColor}
					isBlocked={false}
					percentage={hasActiveContentSubmission ? 100 : 0}
				/>
			</div>
		);
	}
	return <Container>{content}</Container>;
};
