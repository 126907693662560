/**
 * @generated SignedSource<<4316481c54377d4187be2e38a102f108>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type LearningAchievementsTable_Query$variables = {
  isCertified?: boolean | null;
};
export type LearningAchievementsTable_Query$data = {
  readonly LearnV2: {
    readonly LearningAchievements: ReadonlyArray<{
      readonly areCmePointsExported: boolean;
      readonly categoryOpt: string | null;
      readonly finishedAt: any;
      readonly formatTypes: ReadonlyArray<ElementTypeV2>;
      readonly issuedCertificateOpt: {
        readonly fileRef: {
          readonly url: string | null;
        } | null;
      } | null;
      readonly points: number;
      readonly title: string;
    }>;
  };
};
export type LearningAchievementsTable_Query = {
  response: LearningAchievementsTable_Query$data;
  variables: LearningAchievementsTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isCertified"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "isCertified",
    "variableName": "isCertified"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finishedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryOpt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formatTypes",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "areCmePointsExported",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LearningAchievementsTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnV2Queries",
        "kind": "LinkedField",
        "name": "LearnV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LearningAchievement",
            "kind": "LinkedField",
            "name": "LearningAchievements",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "IssuedCertificateV2",
                "kind": "LinkedField",
                "name": "issuedCertificateOpt",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "fileRef",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LearningAchievementsTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnV2Queries",
        "kind": "LinkedField",
        "name": "LearnV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LearningAchievement",
            "kind": "LinkedField",
            "name": "LearningAchievements",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "IssuedCertificateV2",
                "kind": "LinkedField",
                "name": "issuedCertificateOpt",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "fileRef",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e418f29cc10764a22a3c21dc636c8af",
    "id": null,
    "metadata": {},
    "name": "LearningAchievementsTable_Query",
    "operationKind": "query",
    "text": "query LearningAchievementsTable_Query(\n  $isCertified: Boolean\n) {\n  LearnV2 {\n    LearningAchievements(isCertified: $isCertified) {\n      finishedAt\n      title\n      categoryOpt\n      formatTypes\n      areCmePointsExported\n      points\n      issuedCertificateOpt {\n        fileRef {\n          url\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6cf7c8ec3ad22f6ef80dec20f611fd30";

export default node;
