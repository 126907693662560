import { NavLink } from "react-router-dom";
import { Menu } from "primereact/menu";
import React from "react";
import { MAIN_MENU_ITEMS } from "../../../infecto-lms-webapp-impl/impl-main-menu-items";
import { ProjectLogoComponent } from "../../../infecto-lms-webapp-impl/components/ProjectLogo.component";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import styled from "styled-components";
import { MainSideMenuFooter } from "./MainSideMenuFooter";

interface OwnProps {
	className: string;
	collapsed: boolean;
}

export function MainSidebar({ className, collapsed }: OwnProps) {
	return (
		<DivContainer className={"flex-column px-4 " + (className || "")}>
			<ContentContainer>
				{LMS_WEBAPP_CONFIG_IMPL.showLogoInSideBar && (
					<div className=" text-center">
						<NavLink to="/">
							{!collapsed && <ProjectLogoComponent width={95} />}
							{collapsed && <ProjectLogoComponent width={50} />}
						</NavLink>
					</div>
				)}

				<div className="py-4 flex">
					<MenuComponent
						collapsed={collapsed}
						className="border-0"
						model={MAIN_MENU_ITEMS("SideMenu", collapsed)}
					/>
				</div>

				{!collapsed && <MainSideMenuFooter collapsed={collapsed} />}
			</ContentContainer>
		</DivContainer>
	);
}
const DivContainer = styled.div`
	height: 100%;
`;

const ContentContainer = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 145px;
	height: 100%;
	padding: 0 10px 0 10px;
`;

interface MenuProps {
	collapsed: boolean;
}
const MenuComponent = styled(Menu)<MenuProps>`
	background-color: ${(props) => (props.collapsed ? "#fff" : "transparent")};
	border: ${(props) => (props.collapsed ? "1px solid #dee2e6" : "none")};
	width: 100%;
`;
