import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useUrlFilterState } from "../../hooks/useUrlFilterState";

export interface AllNodesSearchParameters {
	query?: string | undefined;
	tagIds: string[];
}

interface AllNodeSearchContextParameters {
	allNodesFilters: AllNodesSearchParameters;
	setAllNodesFilters: (allNodesFilters: AllNodesSearchParameters) => void;
	clearFilters: () => void;
}

export const AllNodesSearchContext = React.createContext<AllNodeSearchContextParameters>({
	setAllNodesFilters: () => {},
	clearFilters: () => {},
	allNodesFilters: { tagIds: [] },
});

interface OwnProps {
	children: any;
}

export function AllNodesSearchContextProvider({ children }: OwnProps) {
	const [params] = useSearchParams();

	const { state, setState } = useUrlFilterState<AllNodesSearchParameters>({
		query: params.get("query") || undefined,
		tagIds: [],
	});

	useEffect(() => {
		const newQuery = params.get("query");
		if (newQuery) {
			setState({
				...state,
				query: newQuery,
			});
		}
		// eslint-disable-next-line
    }, [params])

	return (
		<AllNodesSearchContext.Provider
			value={{
				allNodesFilters: state,
				setAllNodesFilters: (allNodesFilters) => {
					setState(() => allNodesFilters);
				},
				clearFilters: () => {
					setState({ tagIds: [] });
				},
			}}
		>
			{children}
		</AllNodesSearchContext.Provider>
	);
}
