import React from "react";
import styled, { keyframes } from "styled-components";
export const SkeletonCard = () => {
	return (
		<SkeletonCardContainer>
			<ContentContainer>
				<div className="flex flex-row justify-content-between mt-2 w-full">
					<Badge></Badge>
					<NodeIcon />
				</div>
				<div className="w-full mt-2">
					<LongLine className="mb-2" />
					<LongLine />
				</div>
				<div className=" w-full mt-2">
					<div className="flex flex-row">
						<Icon className="mr-3" /> <MediumLine />
					</div>
					<div className="flex flex-row mt-2">
						<Icon className="mr-3 " /> <ShortLine />
					</div>
				</div>
				<div className="w-full mt-4">
					<div className="flex flex-row justify-content-between w-full mb-2">
						<ShortLine className="mr-3" /> <ShortLine />
					</div>
					<ProgressBar />
				</div>
			</ContentContainer>
		</SkeletonCardContainer>
	);
};

const SkeletonAnimation = keyframes`
	0% {
		background-color: #e0e0e0;
	}
	50% {
		background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
`;
const ElementsAnimation = keyframes`
	0% {
		background-color: #b4b3b3;
	}
	50% {
		background-color: #b4b3b3;
	}
	100% {
		background-color: #cecece;
	}
`;
const SkeletonCardContainer = styled.div`
	margin-right: 10px;
	margin-left: 10px;
	height: 100%;
	display: inline-flex;
	padding: 24px;
	align-items: flex-start;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 4px;
	background-color: #e0e0e0;
	animation: ${SkeletonAnimation} 1.5s infinite ease-in-out;
	max-width: 350px;
	width: 100%;
`;
const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	gap: 24px;
	justify-content: space-between;
`;
const Badge = styled.div`
	height: 28px;
	width: 50%;
	background-color: white;
	animation: ${ElementsAnimation} 1.5s infinite ease-in-out;
	border-radius: 5px;
`;
const LongLine = styled.div`
	height: 15px;
	width: 90%;
	background-color: white;
	animation: ${ElementsAnimation} 1.5s infinite ease-in-out;
	border-radius: 5px;
`;
const ProgressBar = styled.div`
	height: 15px;
	width: 100%;
	background-color: white;
	animation: ${ElementsAnimation} 1.5s infinite ease-in-out;
	border-radius: 5px;
`;

export const ShortLine = styled.div`
	height: 15px;
	width: 30%;
	background-color: white;
	animation: ${ElementsAnimation} 1.5s infinite ease-in-out;
	border-radius: 5px;
`;

const MediumLine = styled.div`
	height: 15px;
	width: 40%;
	background-color: white;
	animation: ${ElementsAnimation} 1.5s infinite ease-in-out;
	border-radius: 5px;
`;

export const Icon = styled.div`
	height: 15px;
	width: 15px;
	background-color: white;
	animation: ${ElementsAnimation} 1.5s infinite ease-in-out;
	border-radius: 5px;
`;

const NodeIcon = styled.div`
	height: 24px;
	width: 24px;
	background-color: white;
	animation: ${ElementsAnimation} 1.5s infinite ease-in-out;
	border-radius: 5px;
`;
