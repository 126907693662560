/**
 * @generated SignedSource<<9d102945d7946d49755df58149df0d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DataPrivacyScreen_Query$variables = {
  brandId: string;
};
export type DataPrivacyScreen_Query$data = {
  readonly Viewer: {
    readonly Privacy: {
      readonly LatestPrivacy: {
        readonly content: string;
        readonly id: string;
      } | null;
    };
  };
};
export type DataPrivacyScreen_Query = {
  response: DataPrivacyScreen_Query$data;
  variables: DataPrivacyScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerQueryType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PrivacyViewerSchema",
        "kind": "LinkedField",
        "name": "Privacy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "brandId",
                "variableName": "brandId"
              }
            ],
            "concreteType": "Privacy",
            "kind": "LinkedField",
            "name": "LatestPrivacy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DataPrivacyScreen_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DataPrivacyScreen_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d3bddcf113c3e424789ffeacd44db8ec",
    "id": null,
    "metadata": {},
    "name": "DataPrivacyScreen_Query",
    "operationKind": "query",
    "text": "query DataPrivacyScreen_Query(\n  $brandId: ID!\n) {\n  Viewer {\n    Privacy {\n      LatestPrivacy(brandId: $brandId) {\n        id\n        content\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a228de750d96ed14b6636360ed92722c";

export default node;
