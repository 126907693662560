import React from "react";
import styled from "styled-components";

interface OwnProps {
	children: any;
}

const Container = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	gap: 8px;
`;

export const NodeCardIconTextContainer = ({ children }: OwnProps) => {
	return <Container>{children}</Container>;
};
