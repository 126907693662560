import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
	DATA_PRIVACY_SCREEN_PATH,
	IMPRESS_SCREEN_PATH,
} from "../../../infecto-lms-webapp-impl/router/routes/auth/legal.routes";
import styled from "styled-components";
import { FeedbackMenuLink } from "./FeedbackMenuLink";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { defaultTextColor } from "./NavigationLinkMenuItem.component";

interface OwnProps {
	screenName: string;
	containerId?: string;
}
export const MainFooter = ({ screenName, containerId }: OwnProps) => {
	const location = useLocation();

	const isSelected = (url: string) => location.pathname === url;
	return (
		<MainFooterContainer className="flex flex-row py-1">
			<div className="flex align-items-center">
				<FeedbackMenuLink nodeId={containerId} screen={screenName} />
				<NavLink className="mr-2 md:mr-4 my-0" to={DATA_PRIVACY_SCREEN_PATH}>
					<TextStyle isSelected={isSelected(DATA_PRIVACY_SCREEN_PATH)}>DATENSCHUTZ</TextStyle>
				</NavLink>
				<div className="flex align-items-center">
					<NavLink className="my-0 mr2 md:mr-4" to={IMPRESS_SCREEN_PATH}>
						<TextStyle isSelected={isSelected(IMPRESS_SCREEN_PATH)}>IMPRESSUM</TextStyle>
					</NavLink>
				</div>
			</div>
		</MainFooterContainer>
	);
};

const MainFooterContainer = styled.div`
	background-color: transparent;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-shrink: 0;
	padding: 5px;
`;

interface TextProps {
	isSelected?: boolean;
}
export const TextStyle = styled.p<TextProps>`
	color: ${(props) => (props.isSelected ? LMS_WEBAPP_CONFIG_IMPL.brandColor : defaultTextColor)};
	text-align: center;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0.72px;
	text-transform: uppercase;
	&:hover {
		cursor: pointer;
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor};
	}
	@media (min-width: 481px) {
		font-size: 12px;
	}
`;
