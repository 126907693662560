import React, { Suspense, useRef, useState, useEffect } from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import styled from "styled-components";
import { Carousel } from "primereact/carousel";
import { ProgressSpinner } from "primereact/progressspinner";
import { Card } from "primereact/card";
import { AllMessagesScreen_Query } from "@generated/AllMessagesScreen_Query.graphql";
import { useViewportDimensions } from "../../infecto-lms-webapp/hooks/useViewportDimensions";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { ImageLazyLoad } from "../../infecto-lms-webapp/components/ImageLazyLoad.component";
import { AllMessagesScreen_MessagesPaginationFragment_RefetchQuery } from "@generated/AllMessagesScreen_MessagesPaginationFragment_RefetchQuery.graphql";
import { AllMessagesScreen_MessagesPaginationFragment$key } from "@generated/AllMessagesScreen_MessagesPaginationFragment.graphql";
import { MessageModal_MessageFragment$key } from "@generated/MessageModal_MessageFragment.graphql";
import { MessageModal } from "../../infecto-lms-webapp/components/message/MessageModal.component";
import ArrowBackward from "../../infecto-lms-webapp/assets/arrow-backward.svg";
import ArrowForward from "../../infecto-lms-webapp/assets/arrow-forward.svg";
import { MessagePreview } from "../../infecto-lms-webapp/components/message/MessagePreview.component";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";

const NUM_MESSAGES_LOADED_AT_ONCE = 10;

const QUERY = graphql`
	query AllMessagesScreen_Query($first: Int!) {
		Viewer {
			Messaging {
				MessageInformation {
					id
					numUnseenMessages
				}
			}
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
					}
				}
			}
		}
		...AllMessagesScreen_MessagesPaginationFragment @arguments(first: $first)
	}
`;

const MESSAGES_PAGINATION_FRAGMENT = graphql`
	fragment AllMessagesScreen_MessagesPaginationFragment on Query
	@refetchable(queryName: "AllMessagesScreen_MessagesPaginationFragment_RefetchQuery")
	@argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
		Viewer {
			Messaging {
				Messages(after: $after, first: $first) @connection(key: "AllmessagesScreen_Messages") {
					edges {
						node {
							id
							messageDefinition {
								pages {
									edges {
										node {
											image {
												url
											}
										}
									}
								}
							}
							...MessagePreview_MessageFragment
							...MessageModal_MessageFragment
						}
					}
				}
			}
		}
	}
`;

interface State {
	allMessagesCarouselPage: number;
	selectedMessage?: MessageModal_MessageFragment$key;
}

export function AllMessagesScreen() {
	const query = useLazyLoadQuery<AllMessagesScreen_Query>(
		QUERY,
		{ first: NUM_MESSAGES_LOADED_AT_ONCE },
		{ fetchPolicy: "network-only" },
	);

	const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
		AllMessagesScreen_MessagesPaginationFragment_RefetchQuery,
		AllMessagesScreen_MessagesPaginationFragment$key
	>(MESSAGES_PAGINATION_FRAGMENT, query);

	const carousel = useRef<any>(null);

	const [state, setState] = useState<State>({ allMessagesCarouselPage: 0 });
	const [messageShowed, setMessageShowed] = useState(3);

	const { height: viewportHeight } = useViewportDimensions();

	const messages =
		data.Viewer.Messaging.Messages.edges
			?.filter((e) => e?.node.messageDefinition?.pages.edges?.filter((e) => e?.node.image?.url)[0])
			.map((e) => e!.node) || [];

	const numDefinitionsInCarousel = messages.length > 2 ? messageShowed : messages.length;

	const canMoveForward = state.allMessagesCarouselPage < messages.length - messageShowed || hasNext;

	const canMoveBackward = state.allMessagesCarouselPage > 0;
	useEffect(() => {
		if (window.innerWidth >= 1200) {
			setMessageShowed(3);
		} else {
			setMessageShowed(1);
		}
	}, [window.innerWidth]);

	const navForward = () => {
		if (carousel.current) {
			if (state.allMessagesCarouselPage === messages.length - messageShowed) {
				loadNext(NUM_MESSAGES_LOADED_AT_ONCE, {
					onComplete: () => {
						setState((state) => ({
							...state,
							allMessagesCarouselPage: state.allMessagesCarouselPage + 1,
						}));
						carousel.current.navForward({ cancelable: false }, state.allMessagesCarouselPage + 1);
					},
				});
			} else {
				setState((state) => ({
					...state,
					allMessagesCarouselPage: state.allMessagesCarouselPage! + 1,
				}));
				carousel.current.navForward({ cancelable: false }, state.allMessagesCarouselPage! + 1);
			}
		}
	};

	const navBackward = () => {
		if (carousel.current) {
			setState((state) => ({
				...state,
				allMessagesCarouselPage: state.allMessagesCarouselPage - 1,
			}));
			carousel.current.navBackward({ cancelable: false }, state.allMessagesCarouselPage - 1);
		}
	};

	const limitHeight = 520;
	const modalHeightBellowMaxHeight = 0.58 * viewportHeight;
	const modalHeight =
		modalHeightBellowMaxHeight > limitHeight ? limitHeight : modalHeightBellowMaxHeight;
	const modalWidth = (modalHeight * 9) / 16;
	const responsiveOptions = [
		{
			breakpoint: "2000px",
			numVisible: 3,

			numScroll: 3,
		},
		{
			breakpoint: "1400px",
			numVisible: 2,

			numScroll: 2,
		},
		{
			breakpoint: "1200px",
			numVisible: 1,

			numScroll: 1,
		},
	];

	return (
		<DefaultContainer
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user!}
			collapsed={false}
			screenName="all-messages"
		>
			<MainContainerWithTopBar>
				<h1 className="mb-4">Alle Nachrichten</h1>
				<Description className="mb-3">
					In diesem Bereich sehen Sie alle Nachrichten die wir an Sie versandt haben.
				</Description>

				<Suspense fallback={<ProgressSpinner />}>
					{!isLoadingNext ? (
						<ImageLazyLoad
							imageUrls={messages.map(
								(cmd) => cmd.messageDefinition!.pages.edges!.map((e) => e!.node.image!.url)[0]!,
							)}
							showBeforeLoaded={<ProgressSpinner />}
						>
							<StyledCard numDefinitions={numDefinitionsInCarousel} modalWidth={modalWidth}>
								{messages.length ? (
									<CarouselContainer
										viewportHeight={viewportHeight}
										className="flex justify-content-center"
									>
										<NavImage
											modalWidth={modalWidth}
											onClick={navBackward}
											src={ArrowBackward}
											alt=""
											show={canMoveBackward}
										/>
										<StyledCarousel
											modalWidth={modalWidth}
											numDefinitions={numDefinitionsInCarousel}
											value={messages}
											responsiveOptions={responsiveOptions}
											itemTemplate={(message) => (
												<MessagePreview
													key={message.id}
													selectMessage={() =>
														setState((state) => ({
															...state,
															selectedMessage: message,
														}))
													}
													messageFragmentRef={message}
												/>
											)}
											numVisible={1}
											ref={carousel}
										/>
										<NavImage
											modalWidth={modalWidth}
											onClick={navForward}
											src={ArrowForward}
											alt=""
											show={canMoveForward}
										/>
									</CarouselContainer>
								) : (
									<div className="p-3">Keine Nachrichten</div>
								)}
							</StyledCard>
						</ImageLazyLoad>
					) : (
						<ProgressSpinner />
					)}
				</Suspense>
			</MainContainerWithTopBar>
			<MessageModal
				messageFragment={state.selectedMessage}
				hideModal={() => setState((state) => ({ ...state, selectedMessage: undefined }))}
			/>
		</DefaultContainer>
	);
}

const Description = styled.div``;

const NavImage = styled.img<{ modalWidth: number; show: boolean }>`
	margin-top: ${(props) => (16 / 18 - 0.065) * props.modalWidth}px;
	cursor: pointer;
	width: ${(props) => props.modalWidth * 0.065}px;
	height: ${(props) => props.modalWidth * 0.065}px;
	${(props) => !props.show && "visibility: hidden;"}
`;

const StyledCard = styled(Card)<{ numDefinitions: number; modalWidth: number }>`
	padding: ${(props) => props.modalWidth * 0.05}px 0px;
`;

const CarouselContainer = styled.div<{ viewportHeight: number }>`
	height: 0.7 * ${(props) => props.viewportHeight}px;
`;

const StyledCarousel = styled(Carousel)<{ numDefinitions: number; modalWidth: number }>`
	.p-carousel-indicators {
		display: none;
	}

	.p-carousel-next {
		display: none;
	}

	.p-carousel-prev {
		display: none;
	}

	.p-carousel-item {
		justify-content: center;
		display: flex;
	}

	width: ${(props) => props.modalWidth * props.numDefinitions * 1.1}px;
`;
