/**
 * @generated SignedSource<<c955e8e3e6453327cbf47beac7c41f98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeCardDurationAndAwardComponent_Fragment$data = {
  readonly structureDefinition: {
    readonly extension?: {
      readonly lengthInSecondsOverride?: number | null;
      readonly reducedDataInTree?: {
        readonly cmePoints: number | null;
      };
    };
  };
  readonly " $fragmentType": "NodeCardDurationAndAwardComponent_Fragment";
};
export type NodeCardDurationAndAwardComponent_Fragment$key = {
  readonly " $data"?: NodeCardDurationAndAwardComponent_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeCardDurationAndAwardComponent_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeCardDurationAndAwardComponent_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lengthInSecondsOverride",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReducedDataInTree",
                      "kind": "LinkedField",
                      "name": "reducedDataInTree",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cmePoints",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "560b798bf06ffc26fa95cfc530efd949";

export default node;
