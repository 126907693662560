import React from "react";
import styled from "styled-components";

interface TextProps {
	isBold: boolean;
}

const TextInformation = styled.span<TextProps>`
	display: flex;
	flex-direction: row;
	color: #000;
	font-size: 15px;
	font-style: normal;
	line-height: 20px;
	${(props) => props.isBold && "font-weight : 600"};
	@media screen and (max-width: 1024px) {
		font-size: 13px;
	}
`;

interface OwnProps {
	isBold: boolean;
	children: any;
}

export const NodeCardProgressBarTextComponent = ({ children, isBold }: OwnProps) => {
	return <TextInformation isBold={isBold}>{children}</TextInformation>;
};
