import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { SEARCH_SCREEN_PATH } from "../../../infecto-lms-webapp-impl/router/routes/auth/search.routes";
import { InputText } from "primereact/inputtext";
import React from "react";
import styled from "styled-components";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";

interface SearchFormState {
	query?: string;
}

interface OwnProps {
	className?: string;
}

export function RedesignedExternalSearchBar({ className }: OwnProps) {
	const [params] = useSearchParams();
	const navigate = useNavigate();

	const formik = useFormik<SearchFormState>({
		enableReinitialize: true,
		initialValues: {
			query: params.get("query") || "",
		},
		onSubmit: (values, { setSubmitting }) => {
			navigate(`${SEARCH_SCREEN_PATH}?query=${values.query}`, { replace: true });
			setSubmitting(false);
		},
	});

	return (
		<div className={className}>
			<span className="p-input-icon-left">
				<StyledIcon className="pi pi-search" />
				<StyledInputText
					className="w-13rem md:w-30rem"
					placeholder="Suche"
					value={formik.values.query}
					onChange={(e) => formik.setFieldValue("query", e.target.value)}
					onKeyUp={(e) => {
						if (e.key === "Enter") {
							formik.handleSubmit();
						}
					}}
				/>
			</span>
		</div>
	);
}

const StyledInputText = styled(InputText)`
	border-radius: 20px;
	box-shadow: none !important;
	border-color: var(--text-color) !important;
`;

const StyledIcon = styled.i`
	:hover {
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
	}
`;
