/**
 * @generated SignedSource<<d8d0b71f0aa49a1e993f317964f967f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow" | "%future added value";
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_CanNot" | "%future added value";
export type StartContentConfigType = "StartContent_CanIfInTreeFlow" | "StartContent_CanNot" | "StartContent_SpecificUserStatusesCan" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NodeCardLearnProgressComponent_Fragment$data = {
  readonly root: {
    readonly structureDefinition: {
      readonly extension?: {
        readonly configConsequences?: {
          readonly restartTriesCount: number | null;
        };
        readonly heftContentSubmissionInfo?: {
          readonly hasActiveContentSubmission: boolean;
        };
        readonly multipleChoiceContentSubmissionInfo?: {
          readonly hasActiveContentSubmission: boolean;
        };
        readonly numberOfSubmittedAnswers?: number;
      };
    };
  } | null;
  readonly structureDefinition: {
    readonly extension?: {
      readonly configConsequences?: {
        readonly restartTriesCount: number | null;
      };
      readonly heftContentSubmissionInfo?: {
        readonly hasActiveContentSubmission: boolean;
      };
      readonly multipleChoiceContentSubmissionInfo?: {
        readonly hasActiveContentSubmission: boolean;
      };
      readonly numberOfSubmittedAnswers?: number;
    };
  };
  readonly typeDefinition: {
    readonly contentNodeAdvancementResult?: {
      readonly activeContentSubmissionId?: string;
      readonly configResults?: ReadonlyArray<{
        readonly blockedUntil?: string;
        readonly canBeRestarted: boolean;
        readonly configType: RestartIfFailedContentConfigType;
      }>;
      readonly reasonConfigTypes?: ReadonlyArray<StartContentConfigType>;
      readonly status: ContentNodeAdvancementStatus;
    };
    readonly extension?: {
      readonly amountOfMultipleChoiceElementsInContentSubmission?: number;
    };
  };
  readonly " $fragmentType": "NodeCardLearnProgressComponent_Fragment";
};
export type NodeCardLearnProgressComponent_Fragment$key = {
  readonly " $data"?: NodeCardLearnProgressComponent_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeCardLearnProgressComponent_Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasActiveContentSubmission",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "structureDefinition",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "extension",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InfectopharmTreeConfigConsequences",
                  "kind": "LinkedField",
                  "name": "configConsequences",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "restartTriesCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentSubmissionInfo",
                  "kind": "LinkedField",
                  "name": "heftContentSubmissionInfo",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "numberOfSubmittedAnswers",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentSubmissionInfo",
                  "kind": "LinkedField",
                  "name": "multipleChoiceContentSubmissionInfo",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootExtensionImpl",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LearnOpportunityRootStructureDefinition",
      "abstractKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeCardLearnProgressComponent_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amountOfMultipleChoiceElementsInContentSubmission",
                      "storageKey": null
                    }
                  ],
                  "type": "LearnContentExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "contentNodeAdvancementResult",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reasonConfigTypes",
                      "storageKey": null
                    }
                  ],
                  "type": "CanNotBeStartedContentNodeAdvancementResult",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "activeContentSubmissionId",
                      "storageKey": null
                    }
                  ],
                  "type": "ContinueContentNodeAdvancementResult",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "configResults",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "configType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canBeRestarted",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "blockedUntil",
                              "storageKey": null
                            }
                          ],
                          "type": "NegativeBlockTemporarilyWhenRewardedRestartIfFailedContentConfigResult",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "CanNotBeRestartedAfterFailedContentNodeAdvancementResult",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "f9e7e1db9a2c51f2885f96b419e350ea";

export default node;
