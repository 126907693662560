import React from "react";
import { MenuItem } from "primereact/menuitem";
import styled from "styled-components";
import { classNames } from "primereact/utils";
import { MenuType } from "../../../infecto-lms-webapp/components/navigation/navigation.interface";
import { ItemProps } from "./StyledMenuItem";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { IconWrapperWithHover } from "./SVGMenuIcons.component";

interface OwnProps {
	item: MenuItem;
	collapsed: boolean;
	menuType: MenuType;
	icon: any;
	customedLabel?: any;
}
export const LinkMenuText = styled.span<ItemProps>`
	letter-spacing: 0.84px;
	color: ${(props) =>
		props.isSelected ? LMS_WEBAPP_CONFIG_IMPL.brandColor : defaultTextColor} !important;
	word-break: break-word;
	hyphens: manual;
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	text-transform: uppercase;
	margin-left: ${(props) => (props.menuType === "SideMenu" ? 0 : 5)}px;
	&:hover {
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
	}
`;
const StyledItem = styled.a<ItemProps>`
	display: flex;
	flex-direction: ${(props) => (props.menuType === "SideMenu" ? "column" : "row")};
	align-items: center;
	:hover {
		cursor: pointer;
		color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
		${IconWrapperWithHover} path {
			stroke: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
		}
		,
		${LinkMenuText}, i {
			color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
		}
	}
`;

export function NavigationLinkMenuItemComponent({
	item,
	collapsed,
	menuType,
	icon,
	customedLabel,
}: OwnProps) {
	return (
		<StyledItem
			menuType={menuType}
			href={item.url!}
			target="_blank"
			rel="noreferrer"
			className={
				"py-2 px-1  border-round font-bold flex align-items-center mb-1 text-black-alpha-90 "
			}
		>
			{item.icon && <span className={`flex align-items-center ${classNames()}`}>{icon}</span>}
			{!collapsed &&
				(customedLabel ? (
					customedLabel
				) : (
					<LinkMenuText className={"text"} menuType={menuType}>
						{item?.label?.toUpperCase()}
					</LinkMenuText>
				))}
		</StyledItem>
	);
}
export const defaultTextColor = "#000";
