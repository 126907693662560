import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import styled from "styled-components";

interface OwnProps {
	mainSideBarWidth: number;
}
export const GoBackButtonComponent = ({ mainSideBarWidth }: OwnProps) => {
	const navigate = useNavigate();

	const location = useLocation();
	const previousRouteStarted = localStorage.getItem("previousRouteStarted");
	const previousRouteContinue = localStorage.getItem("previousRouteContinue");
	const previousRouteAfterFailed = localStorage.getItem("previousRouteAfterFailed");
	const previousRouteAfterPassed = localStorage.getItem("previousRouteAfterPassed");

	const goBack = () => {
		if (location.pathname === previousRouteStarted) {
			navigate(-2);
			localStorage.setItem("previousRouteStarted", "");
		} else if (location.pathname === previousRouteContinue) {
			navigate(-2);
			localStorage.setItem("previousRouteContinue", "");
		} else if (location.pathname === previousRouteAfterFailed) {
			navigate(-2);
			localStorage.setItem("previousRouteAfterFailed", "");
		} else if (location.pathname === previousRouteAfterPassed) {
			navigate(-2);
			localStorage.setItem("previousRouteAfterPassed", "");
		} else {
			navigate(-1);
		}
	};
	return (
		<DivContainer mainSideBarWidth={mainSideBarWidth}>
			{/modul\b/.test(location.pathname) && (
				<StyledButton
					className="mr-2 bg-transparent text-black-alpha-90 hover:text-primary border-none"
					onClick={() => goBack()}
					icon={<i className="pi pi-chevron-left text-xs md:text-base"></i>}
					label={"ZURÜCK"}
				></StyledButton>
			)}
		</DivContainer>
	);
};

interface ContainerProps {
	mainSideBarWidth: number;
}

const DivContainer = styled.div<ContainerProps>`
	margin-left: ${(props) => props.mainSideBarWidth}px;
	display: flex;
	height: 100%;
	align-items: center;
`;

const StyledButton = styled(Button)`
	font-size: 10px !important;
	@media (min-width: 481px) {
		font-size: 12px !important;
	}
`;
