import React from "react";
import { NodeBreadcrumbs } from "../../infecto-lms-webapp/components/node/NodeBreadcrumbs.component";
import { NodeTagList } from "../../infecto-lms-webapp/components/node/NodeTagList.component";
import { DurationDisplay } from "../../infecto-lms-webapp/functions/helpers/DurationDisplay";
import { NodeContentElementsList } from "../../infecto-lms-webapp/components/node/NodeContentElementsList.component";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { NodeLeftFrame_NodeFragment$key } from "@generated/NodeLeftFrame_NodeFragment.graphql";
import styled from "styled-components";
import { AttachmentHeftDisplay } from "../../infecto-lms-webapp/components/attachment/AttachmentHeftDisplay.component";
import { NodeLeftFrame_Query } from "@generated/NodeLeftFrame_Query.graphql";
import { singularPluralPointsDisplay } from "../../infecto-lms-webapp/functions/helpers/singularPluralPointsDisplay";
import { formatDateTime } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { isTemporarilyBlockedUntilUtil } from "../../infecto-lms-webapp/utils/advancement-result/is-temporarily-blocked-until";

const QUERY = graphql`
	query NodeLeftFrame_Query($learnOpportunityId: ID!) {
		node(id: $learnOpportunityId) {
			id
			... on LearnOpportunityV2 {
				id
				...NodeLeftFrame_NodeFragment
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
					}
				}
			}
		}
	}
`;
const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeLeftFrame_NodeFragment on LearnOpportunityV2 {
		id
		image {
			url
		}
		root {
			id
			structureDefinition {
				title
				... on LearnOpportunityRootStructureDefinition {
					icon {
						url
					}
					extension {
						... on LearnOpportunityRootExtensionImpl {
							lengthInSecondsOverride
							reducedDataInTree {
								cmePoints
							}
							configConsequences {
								isRewardedTo
								rewardExpired
								showAnswerExpired
							}
						}
					}
					...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
				}
			}
			typeDefinition {
				... on LearnOpportunityBranchTypeDefinition {
					children {
						id
						typeDefinition {
							definitionType
							... on LearnOpportunityELearningContentTypeDefinition {
								containedElementTypes
								contentNodeAdvancementResult {
									status
									... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
										configResults {
											configType

											... on RestartIfFailedContentConfigResult {
												canBeRestarted
											}

											... on NegativeBlockPermanentlyRestartIfFailedContentConfigResult {
												canBeRestarted
											}

											... on NegativeBlockTemporarilyRestartIfFailedContentConfigResult {
												blockedUntil
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		shortDescription
		structureDefinition {
			title
			definitionType
		}
		typeDefinition {
			definitionType
			... on LearnOpportunityELearningContentTypeDefinition {
				extension {
					... on LearnContentExtensionImpl {
						cmePoints
						lengthInSecondsOverride
					}
				}
			}
		}
		...ProjectContentButtonCME_LearnOpportunityFragment
		...AttachmentHeftDisplay_LearnOpportunityV2Fragment
		...NodeNavigationButtons_NodeFragment
		...NodeBreadcrumbs_NodeFragment
		...NodeConfigurationsDisplay_NodeFragment
		...NodeContentElementsList_NodeFragment
		...AttachmentDisplay_LearnOpportunityV2Fragment
		...NodeDescription_NodeFragment
		...ShowAnswersButton_LearnOpportunityFragment
	}
`;
interface OwnProps {
	rootId: string;
}
const Icon = styled.img`
	max-width: 100px;
`;
export const NodeLeftFrame = ({ rootId }: OwnProps) => {
	const query = useLazyLoadQuery<NodeLeftFrame_Query>(
		QUERY,
		{
			learnOpportunityId: rootId!,
		},
		{ fetchPolicy: "network-only" },
	);

	const learnOpportunity = useFragment<NodeLeftFrame_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		query.node,
	)!;

	const isRoot = learnOpportunity.structureDefinition.definitionType === "root";

	const lengthInSeconds = isRoot
		? learnOpportunity.root?.structureDefinition.extension?.lengthInSecondsOverride || 0
		: learnOpportunity.typeDefinition.extension?.lengthInSecondsOverride || 0;

	const hasPoints = () => {
		return (
			learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree?.cmePoints || -1 > 0
		);
	};

	const title = learnOpportunity.root?.structureDefinition.extension?.configConsequences
		?.isRewardedTo
		? `zertifiziert bis ${formatDateTime(
				learnOpportunity.root?.structureDefinition.extension?.configConsequences?.isRewardedTo,
				false,
		  )}`
		: learnOpportunity.structureDefinition.title;

	const contentNodeWithMultipleChoice =
		learnOpportunity.root?.typeDefinition?.children &&
		learnOpportunity.root?.typeDefinition?.children.find((ch) =>
			ch.typeDefinition?.containedElementTypes?.includes("multipleChoice"),
		);

	const contentNodeWithMultipleChoiceIsTemporarilyBlockedUntil = contentNodeWithMultipleChoice
		? isTemporarilyBlockedUntilUtil(
				contentNodeWithMultipleChoice?.typeDefinition.contentNodeAdvancementResult?.configResults,
		  )
		: undefined;

	interface PointsProps {
		points: number | null | undefined;
		type: string;
	}
	const AvailablePoints = ({ points, type }: PointsProps) => {
		return (
			<div className="text-base" style={{ textAlign: "right" }}>
				{(points || -1) > 0 && (
					<div>
						{type}
						{singularPluralPointsDisplay(points!)}
						<span className="ml-2 text-base font-bold">{points}</span>
					</div>
				)}
			</div>
		);
	};
	return (
		<div className="col-12 xl:col-3 pl-4 md:bg-transparent tk-bg-white lg:h-full flex flex-column align-content-start">
			<div className="pr-4 block md:hidden">
				<NodeBreadcrumbs learnOpportunityFragmentRef={learnOpportunity} />
			</div>

			<div className="flex pt-5 pr-4 align-items-start">
				{learnOpportunity.root?.structureDefinition.icon?.url && (
					<Icon
						className="mr-2 mb-2"
						alt={learnOpportunity.root.structureDefinition.title}
						src={learnOpportunity?.root.structureDefinition.icon.url}
					/>
				)}

				{learnOpportunity.root && (
					<NodeTagList
						fontSize={"0.5"}
						className="justify-content-end align-items-end flex-grow-1"
						learnOpportunityRootStructureDefinitionFragmentRef={
							learnOpportunity.root?.structureDefinition
						}
					/>
				)}
			</div>
			<div className="flex align-items-center pr-4 pt-4 pb-2 flex-wrap">
				<table className="w-full">
					<tbody>
						<tr>
							<td>
								{lengthInSeconds !== 0 && (
									<div className="text-base">
										Dauer: <DurationDisplay duration={lengthInSeconds} />
									</div>
								)}
							</td>
							{learnOpportunity.root?.structureDefinition.extension?.configConsequences
								?.rewardExpired || false ? (
								<td className="text-base" style={{ textAlign: "right" }}>
									Übungsmodul
								</td>
							) : (
								<td>
									{hasPoints() ? (
										<AvailablePoints
											type={"CME-"}
											points={
												learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree
													?.cmePoints
											}
										/>
									) : null}
								</td>
							)}
						</tr>
						<tr>
							{learnOpportunity.root?.structureDefinition.extension?.configConsequences
								?.rewardExpired ||
							false ||
							!hasPoints() ? (
								<td></td>
							) : (
								<td colSpan={2}>
									{contentNodeWithMultipleChoice ? (
										contentNodeWithMultipleChoice?.typeDefinition?.contentNodeAdvancementResult
											?.status == "CanNotBeRestartedAfterFailed" &&
										contentNodeWithMultipleChoiceIsTemporarilyBlockedUntil ? (
											<span className="text-base">
												Bis{" "}
												{formatDateTime(
													contentNodeWithMultipleChoiceIsTemporarilyBlockedUntil.blockedUntil as string,
												)}{" "}
												gesperrt
											</span>
										) : null
									) : null}
								</td>
							)}
						</tr>
					</tbody>
				</table>
			</div>

			<hr />
			<span className="mr-4 mt-0 word-wrap font-bold text-base">{title}</span>
			<hr />

			{learnOpportunity.shortDescription && (
				<div
					className="mr-4 text-base"
					dangerouslySetInnerHTML={{ __html: learnOpportunity.shortDescription }}
				/>
			)}

			<NodeContentElementsList learnOpportunityFragmentRef={learnOpportunity} />
			<AttachmentHeftDisplay
				learnOpportunityFragmentRef={learnOpportunity}
				marginTopValue={"auto"}
			/>
		</div>
	);
};
