import React, { Suspense, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { UserStartedNotBlockedNodesComponent_Query } from "@generated/UserStartedNotBlockedNodesComponent_Query.graphql";
import { UserStartedNotBlockedNodesComponent_Refetch } from "@generated/UserStartedNotBlockedNodesComponent_Refetch.graphql";
import { UserStartedNotBlockedNodesComponent_NodeFragment$key } from "@generated/UserStartedNotBlockedNodesComponent_NodeFragment.graphql";
import { NodesCarouselSkeleton } from "./NodesCarouselSkeleton";
import { UserHasNoStartedNodesComponent } from "./UserHasNoStartedNodes.component";
import { NodesCarousel } from "./NodesCarousel";
import styled from "styled-components";

const QUERY = graphql`
	query UserStartedNotBlockedNodesComponent_Query {
		...UserStartedNotBlockedNodesComponent_NodeFragment
	}
`;

const LEARN_OPPORTUNITIES_FRAGMENT = graphql`
	fragment UserStartedNotBlockedNodesComponent_NodeFragment on Query
	@refetchable(queryName: "UserStartedNotBlockedNodesComponent_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" }) {
		LearnV2 {
			GetUserStartedNotBlockedNodes(first: $first, after: $after)
				@connection(key: "UserStartedNotBlockedNodesComponent_GetUserStartedNotBlockedNodes") {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						...NodeCard_NodeFragment
					}
				}
			}
		}
	}
`;
export const UserStartedNotBlockedNodesComponent = () => {
	const queryData = useLazyLoadQuery<UserStartedNotBlockedNodesComponent_Query>(
		QUERY,
		{},
		{
			fetchPolicy: "store-and-network",
		},
	);

	const { data, loadNext, hasNext, refetch } = usePaginationFragment<
		UserStartedNotBlockedNodesComponent_Refetch,
		UserStartedNotBlockedNodesComponent_NodeFragment$key
	>(LEARN_OPPORTUNITIES_FRAGMENT, queryData);

	const userStartedNodes =
		data.LearnV2.GetUserStartedNotBlockedNodes?.edges
			?.filter((e) => !!e?.node)
			.map((e) => e!.node!) || [];

	useEffect(() => {
		refetch({}, { fetchPolicy: "store-and-network" });
	}, []);

	return (
		<Container className="w-full">
			{userStartedNodes && userStartedNodes.length > 0 ? (
				<Suspense fallback={<NodesCarouselSkeleton />}>
					<NodesCarousel
						hasNext={hasNext}
						onLoadMore={() => loadNext(10)}
						nodes={userStartedNodes}
					/>
				</Suspense>
			) : (
				<UserHasNoStartedNodesComponent />
			)}
		</Container>
	);
};

const Container = styled.div`
	margin-bottom: 49px;
`;
