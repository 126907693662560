/**
 * @generated SignedSource<<06fd728dbbf4671850cbc14dba44b9e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeStatusMessageComponent_NodeFragment$data = {
  readonly id: string;
  readonly root: {
    readonly id: string;
    readonly structureDefinition: {
      readonly extension?: {
        readonly configConsequences?: {
          readonly rewardExpired: boolean;
          readonly showAnswerExpired: boolean;
        };
      };
    };
  } | null;
  readonly " $fragmentType": "NodeStatusMessageComponent_NodeFragment";
};
export type NodeStatusMessageComponent_NodeFragment$key = {
  readonly " $data"?: NodeStatusMessageComponent_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeStatusMessageComponent_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeStatusMessageComponent_NodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "InfectopharmTreeConfigConsequences",
                          "kind": "LinkedField",
                          "name": "configConsequences",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rewardExpired",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "showAnswerExpired",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "6660dd4ef1b0b945da9af7b97cf60536";

export default node;
