import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeIcon_NodeFragment$key } from "@generated/NodeIcon_NodeFragment.graphql";
import { BookIcon, VideoIcon, PodcastIcon } from "./SvgNodeIcons.component";
import styled from "styled-components";
import { NodeIconWrapperComponent } from "./NodeIconWrapper.component";

const ICON_FRAGMENT = graphql`
	fragment NodeIcon_NodeFragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						reducedDataInTree {
							elementTypes
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeIcon_NodeFragment$key;
}

const IconsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	padding-top: 2px;
	padding-bottom: 2px;
`;
export const IconColor = "#000";

export const NodeIconComponent = ({ learnOpportunityFragmentRef }: OwnProps) => {
	const learnOpportunity = useFragment<NodeIcon_NodeFragment$key>(
		ICON_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	const elementType =
		learnOpportunity.structureDefinition.extension?.reducedDataInTree?.elementTypes;

	if (!elementType) return null;

	const hasBook = elementType.includes("file");
	const hasVideo = elementType.includes("video");
	const hasPodcast = elementType.includes("podcast");

	return (
		<IconsContainer className="flex-column sm:flex-row">
			{hasBook && (
				<NodeIconWrapperComponent
					icon={
						<BookIcon
							width={24}
							height={24}
							showHoverEffect={true}
							target={"book"}
							color={IconColor}
						/>
					}
					tooltip={"Heft"}
					targetTooltip={".book"}
				/>
			)}

			{hasPodcast && (
				<NodeIconWrapperComponent
					icon={
						<PodcastIcon
							width={24}
							height={24}
							showHoverEffect={true}
							target={"podcast"}
							color={IconColor}
						/>
					}
					tooltip={"Podcast"}
					targetTooltip={".podcast"}
				/>
			)}

			{hasVideo && (
				<NodeIconWrapperComponent
					icon={
						<VideoIcon
							width={24}
							height={24}
							showHoverEffect={true}
							target={"video"}
							color={IconColor}
						/>
					}
					tooltip={"Video"}
					targetTooltip={".video"}
				/>
			)}
		</IconsContainer>
	);
};
