import React from "react";
import { ShowAnswersButton } from "../../../infecto-lms-webapp/components/content-element/ShowAnswersButton.component";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ShowAnswerButtonComponent_NodeFragment$key } from "@generated/ShowAnswerButtonComponent_NodeFragment.graphql";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment ShowAnswerButtonComponent_NodeFragment on LearnOpportunityV2 {
		id
		root {
			id
		}
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					id
					typeDefinition {
						... on LearnOpportunityELearningContentTypeDefinition {
							containedElementTypes
						}
					}
					...ShowAnswersButton_LearnOpportunityFragment
				}
			}
			definitionType
		}
		...ShowAnswersButton_LearnOpportunityFragment
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: ShowAnswerButtonComponent_NodeFragment$key;
	hasMultipleChoice: boolean;
}
export const ShowAnswerButtonContainer = ({
	learnOpportunityFragmentRef,
	hasMultipleChoice,
}: OwnProps) => {
	const learnOpportunity = useFragment<ShowAnswerButtonComponent_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	)!;
	return (
		<div className="ml-auto flex flex-column md:flex-row">
			{learnOpportunity.typeDefinition.children
				? learnOpportunity.typeDefinition.children
						?.filter(
							(c) =>
								c.typeDefinition.containedElementTypes?.filter((e) => e.includes("multipleChoice"))
									.length,
						)
						.map((ch) => (
							<div key={ch.id}>
								<ShowAnswersButton learnOpportunityFragmentRef={ch} />
							</div>
						))
				: hasMultipleChoice && <ShowAnswersButton learnOpportunityFragmentRef={learnOpportunity} />}
		</div>
	);
};
