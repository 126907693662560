/**
 * The sortItems function is used to sort an array of objects based on a specific field. It supports both ascending (asc) and descending (desc) order, with ascending being the default. The function can handle various data types (like strings or numbers) and ensures proper handling of null or undefined values.
 * @param itemsObjectArray array of Objects that has to be sorted
 * @param sortField field that has to be sorted
 * @param order order of the sort. It can be asc or desc. Default value is asc
 */

export const sortArrayOfObjectsUsingAField = (
	itemsObjectArray: any[],
	sortField: string,
	order: "asc" | "desc" = "asc",
) => {
	const compareFunction = (a: any, b: any) => {
		const aValue = a[sortField];
		const bValue = b[sortField];

		// Handle null or undefined values
		if (aValue == null && bValue == null) {
			return 0;
		}

		// Place null values first only for descending order
		if (order === "desc") {
			if (aValue == null) {
				return -1;
			}
			if (bValue == null) {
				return 1;
			}
		}
		// Null goes to the end for ascending
		if (aValue == null) {
			return 1;
		}
		if (bValue == null) {
			return -1;
		}

		// Perform string comparison when values are non-null
		if (typeof aValue === "string" && typeof bValue === "string") {
			return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
		}

		// For other types (e.g., numbers), fallback to standard comparison
		return order === "asc" ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
	};

	return [...itemsObjectArray].sort(compareFunction);
};
