import React from "react";
import { ProgressBar } from "primereact/progressbar";
import styled from "styled-components";
import { LockIcon } from "./SvgNodeIcons.component";

interface OwnProps {
	componentColor: string;
	isBlocked: boolean;
	percentage: number;
	progressBarState: ProgressBarState;
}

export type ProgressBarState = "isRewarded" | "noRewarded";

export const NodeCardProgressBarComponent = ({
	componentColor,
	isBlocked,
	percentage,
	progressBarState,
}: OwnProps) => {
	const rewardedBackgroundColor = "#DBEDD9";
	const rewardedBorderColor = "#BDDEBA";

	let backgroundColor = rewardedBackgroundColor;
	let borderColor = rewardedBorderColor;

	switch (progressBarState) {
		case "isRewarded":
			backgroundColor = rewardedBackgroundColor;
			borderColor = rewardedBorderColor;
			break;
		case "noRewarded":
			backgroundColor = "#EFEFF0";
			borderColor = "#C3CCD5";
			break;
	}
	return (
		<Container>
			<ProgressBarContainer
				backGroundColor={backgroundColor}
				borderColor={borderColor}
				color={componentColor}
				value={percentage}
				displayValueTemplate={() => <></>}
			/>
			{isBlocked ? (
				<IconContainer>
					<LockIcon />
				</IconContainer>
			) : null}
		</Container>
	);
};

interface ProgressBarProps {
	borderColor: string;
	backGroundColor: string;
}

const ProgressBarContainer = styled(ProgressBar)<ProgressBarProps>`
	width: 100%;
	height: 100%;
	flex-shrink: 0;
	border-radius: 16px;
	border: 1px solid ${(props) => props.borderColor};
	background: ${(props) => props.backGroundColor};
	margin-top: 5px;
`;

const Container = styled.div`
	width: 100%;
	height: 16px;
	position: relative;
`;

const IconContainer = styled.div`
	position: absolute;
	left: 9px;
	top: 50%;
	transform: translateY(-50%);
	padding-top: 1px;
`;
