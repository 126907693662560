import React from "react";
import styled from "styled-components";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";

interface OwnProps {
	color: string;
	target?: string;
	showHoverEffect?: boolean;
	width: number;
	height: number;
}
export const BookIcon = ({ color, target, showHoverEffect, height, width }: OwnProps) => (
	<IconWrapper
		showHoverEffect={showHoverEffect || false}
		className={`${target}`}
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M12 21L11.8999 20.8499C11.2053 19.808 10.858 19.287 10.3991 18.9098C9.99286 18.5759 9.52476 18.3254 9.02161 18.1726C8.45325 18 7.82711 18 6.57482 18H5.2C4.07989 18 3.51984 18 3.09202 17.782C2.71569 17.5903 2.40973 17.2843 2.21799 16.908C2 16.4802 2 15.9201 2 14.8V6.2C2 5.07989 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.07989 3 5.2 3H5.6C7.84021 3 8.96031 3 9.81596 3.43597C10.5686 3.81947 11.1805 4.43139 11.564 5.18404C12 6.03968 12 7.15979 12 9.4M12 21V9.4M12 21L12.1001 20.8499C12.7947 19.808 13.142 19.287 13.6009 18.9098C14.0071 18.5759 14.4752 18.3254 14.9784 18.1726C15.5467 18 16.1729 18 17.4252 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V6.2C22 5.07989 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H18.4C16.1598 3 15.0397 3 14.184 3.43597C13.4314 3.81947 12.8195 4.43139 12.436 5.18404C12 6.03968 12 7.15979 12 9.4"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapper>
);

export const VideoIcon = ({ color, target, showHoverEffect, height, width }: OwnProps) => (
	<IconWrapper
		showHoverEffect={showHoverEffect || false}
		className={`${target}`}
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M22 8.93137C22 8.32555 22 8.02265 21.8802 7.88238C21.7763 7.76068 21.6203 7.69609 21.4608 7.70865C21.2769 7.72312 21.0627 7.93731 20.6343 8.36569L17 12L20.6343 15.6343C21.0627 16.0627 21.2769 16.2769 21.4608 16.2914C21.6203 16.3039 21.7763 16.2393 21.8802 16.1176C22 15.9774 22 15.6744 22 15.0686V8.93137Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2 9.8C2 8.11984 2 7.27976 2.32698 6.63803C2.6146 6.07354 3.07354 5.6146 3.63803 5.32698C4.27976 5 5.11984 5 6.8 5H12.2C13.8802 5 14.7202 5 15.362 5.32698C15.9265 5.6146 16.3854 6.07354 16.673 6.63803C17 7.27976 17 8.11984 17 9.8V14.2C17 15.8802 17 16.7202 16.673 17.362C16.3854 17.9265 15.9265 18.3854 15.362 18.673C14.7202 19 13.8802 19 12.2 19H6.8C5.11984 19 4.27976 19 3.63803 18.673C3.07354 18.3854 2.6146 17.9265 2.32698 17.362C2 16.7202 2 15.8802 2 14.2V9.8Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapper>
);

export const PodcastIcon = ({ color, target, showHoverEffect, height, width }: OwnProps) => (
	<IconWrapper
		showHoverEffect={showHoverEffect || false}
		className={`${target}`}
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M17.1189 18C19.4623 16.4151 21 13.7779 21 10.785C21 5.9333 16.9704 2 12 2C7.02958 2 3 5.9333 3 10.785C3 13.7779 4.53771 16.4151 6.88113 18M8.35967 14C7.51875 13.15 7 12.0086 7 10.7505C7 8.12711 9.23881 6 12 6C14.7612 6 17 8.12711 17 10.7505C17 12.0095 16.4813 13.15 15.6403 14M12 22C10.8954 22 10 21.1046 10 20V18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18V20C14 21.1046 13.1046 22 12 22ZM13 11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</IconWrapper>
);

export const CalendarIcon = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M17.5 8.33332H2.5M13.3333 1.66666V4.99999M6.66667 1.66666V4.99999M7.5 13.3333L9.16667 15L12.9167 11.25M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8212 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7335 17.5 14.3333V7.33332C17.5 5.93319 17.5 5.23313 17.2275 4.69835C16.9878 4.22794 16.6054 3.84549 16.135 3.60581C15.6002 3.33332 14.9001 3.33332 13.5 3.33332H6.5C5.09987 3.33332 4.3998 3.33332 3.86502 3.60581C3.39462 3.84549 3.01217 4.22794 2.77248 4.69835C2.5 5.23313 2.5 5.93319 2.5 7.33332V14.3333C2.5 15.7335 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z"
			stroke="black"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const ClockIcon = () => (
	<svg
		width="18"
		height="18"
		fill="none"
		stroke="black"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
		className="feather feather-clock"
		viewBox="0 0 24 24"
	>
		<circle cx="12" cy="12" r="10"></circle>
		<polyline points="12 6 12 12 16 14"></polyline>
	</svg>
);

export const AwardIcon = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M5.83333 12.5752V18.3333L9.75241 16.7657C9.84387 16.7291 9.88961 16.7108 9.93683 16.7036C9.9787 16.6971 10.0213 16.6971 10.0632 16.7036C10.1104 16.7108 10.1561 16.7291 10.2476 16.7657L14.1667 18.3333V12.5752M16.25 7.91666C16.25 11.3684 13.4518 14.1667 10 14.1667C6.54822 14.1667 3.75 11.3684 3.75 7.91666C3.75 4.46488 6.54822 1.66666 10 1.66666C13.4518 1.66666 16.25 4.46488 16.25 7.91666Z"
			stroke="black"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const LockIcon = () => (
	<svg width="13" height="13" viewBox="0 0 16 16" fill="none">
		<path
			d="M11.3332 6.66667V5.33333C11.3332 3.49238 9.84079 2 7.99984 2C6.15889 2 4.6665 3.49238 4.6665 5.33333V6.66667M7.99984 9.66667V11M5.8665 14H10.1332C11.2533 14 11.8133 14 12.2412 13.782C12.6175 13.5903 12.9234 13.2843 13.1152 12.908C13.3332 12.4802 13.3332 11.9201 13.3332 10.8V9.86667C13.3332 8.74656 13.3332 8.18651 13.1152 7.75869C12.9234 7.38236 12.6175 7.0764 12.2412 6.88465C11.8133 6.66667 11.2533 6.66667 10.1332 6.66667H5.8665C4.7464 6.66667 4.18635 6.66667 3.75852 6.88465C3.3822 7.0764 3.07624 7.38236 2.88449 7.75869C2.6665 8.18651 2.6665 8.74656 2.6665 9.86667V10.8C2.6665 11.9201 2.6665 12.4802 2.88449 12.908C3.07624 13.2843 3.3822 13.5903 3.75852 13.782C4.18635 14 4.7464 14 5.8665 14Z"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const DownloadIcon = () => (
	<svg width="17" height="16" viewBox="0 0 17 16" fill="none">
		<path
			d="M14.5 14H2.5M12.5 7.33333L8.5 11.3333M8.5 11.3333L4.5 7.33333M8.5 11.3333V2"
			stroke="black"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

interface IconWrapperProps {
	showHoverEffect: boolean;
}
const IconWrapper = styled.svg<IconWrapperProps>`
	${(props) =>
		props.showHoverEffect &&
		`
    &:hover path {
      stroke: ${LMS_WEBAPP_CONFIG_IMPL.brandColor} !important;
    }
  `}
`;
