import React from "react";
import { NavigationLinkMenuItemComponent } from "./NavigationLinkMenuItem.component";
import { ContactLinkIcon } from "./SVGMenuIcons.component";
import styled from "styled-components";

interface OwnProps {
	collapsed: boolean;
}
export const MainSideMenuFooter = ({ collapsed }: OwnProps) => {
	const contactLink = {
		url: "https://infectopharm.com/kontakt",
		label: "kontakt",
		icon: "icon",
	};
	return (
		<div className="w-full">
			<div className="text-xl flex align-items-center justify-content-center">
				<RestyledLink
					item={contactLink}
					collapsed={collapsed}
					menuType={"SideMenu"}
					icon={<ContactLinkIcon />}
				/>
			</div>
		</div>
	);
};

const RestyledLink = styled(NavigationLinkMenuItemComponent)`
	padding-bottom: 0;
`;
