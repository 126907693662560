import { MenuItem } from "primereact/menuitem";
import React from "react";
import { MenuType } from "../infecto-lms-webapp/components/navigation/navigation.interface";
import { NavigationMenuItemComponent } from "../app/components/navigation/NavigationMenuItem.component";
import { COMMUNICATION_SETTINGS_SCREEN_PATH } from "./router/routes/auth/communication-settings.routes";
import { UserIcon } from "../app/components/navigation/SVGMenuIcons.component";
import { NavigationLinkMenuItemComponent } from "../app/components/navigation/NavigationLinkMenuItem.component";
import { useLocation } from "react-router-dom";
import { LMS_WEBAPP_CONFIG_IMPL } from "./config";

export const PROFILE_LINK_MENU_ITEMS: (
	onclick: () => void,
	menuType: MenuType,
	collapsed: boolean,
) => MenuItem[] = (onclick: () => void, menuType: MenuType, collapsed: boolean) => {
	const location = useLocation();

	const isSelected = (url: string) => location.pathname === url;
	return [
		{
			label: "Profil",
			url: process.env.REACT_APP_USER_SYSTEM_BASE,
			icon: "pi pi-sliders-h",
			template: (item) => (
				<NavigationLinkMenuItemComponent
					icon={<UserIcon width={30} height={30} />}
					collapsed={collapsed}
					item={item}
					menuType={menuType}
				/>
			),
		},
		{
			label: "Einstellungen",
			url: COMMUNICATION_SETTINGS_SCREEN_PATH,
			icon: "pi pi-sliders-h",
			template: (item) => (
				<NavigationMenuItemComponent
					icon={
						<i
							style={{ color: isSelected(item.url!) ? LMS_WEBAPP_CONFIG_IMPL.brandColor : "black" }}
							className={"text-2xl p-1 " + item.icon}
						/>
					}
					onClick={() => {}}
					collapsed={collapsed}
					item={item}
					menuType={menuType}
				/>
			),
		},
		{
			label: "Logout",
			url: "",
			icon: "pi pi-sign-out",
			template: (item) => (
				<NavigationMenuItemComponent
					icon={<i style={{ color: "black" }} className={"text-2xl p-1 " + item.icon} />}
					menuType={menuType}
					onClick={onclick}
					item={item}
					collapsed={collapsed}
				/>
			),
		},
	];
};
