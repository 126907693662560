import React from "react";
import styled from "styled-components";

const CardHeaderWrapper = styled.div<{ showLabelCard: boolean }>`
	display: flex;
	width: 100%;
	justify-content: ${({ showLabelCard }) => (showLabelCard ? "space-between" : "flex-end")};
	align-items: center;
`;

interface OwnProps {
	children: any;
	showLabelCard: boolean;
}

export const NodeCardHeaderContainer = ({ children, showLabelCard }: OwnProps) => {
	return <CardHeaderWrapper showLabelCard={showLabelCard}>{children}</CardHeaderWrapper>;
};
