import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem } from "primereact/menuitem";
import { classNames } from "primereact/utils";
import { MenuType } from "../../../infecto-lms-webapp/components/navigation/navigation.interface";
import { LinkMenuText } from "./NavigationLinkMenuItem.component";
import { StyledMenuItem } from "./StyledMenuItem";

interface OwnProps {
	item: MenuItem;
	collapsed: boolean;
	icon: any;
	onClick: () => void;
	menuType: MenuType;
}

export function NavigationMenuItemComponent({
	item,
	collapsed,
	icon,
	onClick,
	menuType,
}: OwnProps) {
	const navigate = useNavigate();
	const location = useLocation();

	const isSelected = (url: string) => location.pathname === url;
	return (
		<StyledMenuItem
			menuType={menuType}
			onClick={() => {
				onClick();
				navigate(item.url!);
			}}
			className={"py-2 px-1 border-round font-bold flex align-items-center mb-1 "}
		>
			{item.icon && <span className={`flex align-items-center${classNames()}`}>{icon}</span>}
			{!collapsed && (
				<LinkMenuText isSelected={isSelected(item.url!)} className={"text"} menuType={menuType}>
					{item?.label?.toUpperCase()}
				</LinkMenuText>
			)}
		</StyledMenuItem>
	);
}
