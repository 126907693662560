/**
 * @generated SignedSource<<8f57bf660638c34e8966984ea268b174>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NodeCardFooterContainer_LearnOpportunityFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly extension?: {
      readonly firstLearnOpportunityIdWithMultipleChoiceElement?: string | null;
      readonly heftContentSubmissionInfo?: {
        readonly hasActiveContentSubmission: boolean;
      };
    };
  };
  readonly typeDefinition: {
    readonly children?: ReadonlyArray<{
      readonly id: string;
      readonly typeDefinition: {
        readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
      };
      readonly " $fragmentSpreads": FragmentRefs<"NodeCardLearnProgressComponent_Fragment">;
    }>;
    readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"NodeCardLearnProgressComponent_Fragment">;
  readonly " $fragmentType": "NodeCardFooterContainer_LearnOpportunityFragment";
};
export type NodeCardFooterContainer_LearnOpportunityFragment$key = {
  readonly " $data"?: NodeCardFooterContainer_LearnOpportunityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeCardFooterContainer_LearnOpportunityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "NodeCardLearnProgressComponent_Fragment"
},
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "containedElementTypes",
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityELearningContentTypeDefinition",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeCardFooterContainer_LearnOpportunityFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstLearnOpportunityIdWithMultipleChoiceElement",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentSubmissionInfo",
                      "kind": "LinkedField",
                      "name": "heftContentSubmissionInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasActiveContentSubmission",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunityV2",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeDefinition",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityBranchTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "77194e9bae3c8ff128caf3802b5556ea";

export default node;
