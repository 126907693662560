import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";

/**The hook checks if the actual contents of the object or array are the same. It compares the properties and values inside the object, not just the reference.
 This avoids unnecessary effect triggers if the object itself remains logically the same, even if its reference changes.*/
export const useDeepCompareEffect = (callback: any, dependencies: any) => {
	const previousDeps = useRef();
	useEffect(() => {
		if (!isEqual(previousDeps.current, dependencies)) {
			callback();
		}
		previousDeps.current = dependencies;
	}, [callback, dependencies]);
};
