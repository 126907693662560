import React, { useContext } from "react";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { TotalCountFilteredNodesComponent } from "./TotalCountFilteredNodes.component";
import { SearchNodesCarouselComponent_Query } from "@generated/SearchNodesCarouselComponent_Query.graphql";
import { SearchNodesCarouselComponent_Refetch } from "@generated/SearchNodesCarouselComponent_Refetch.graphql";
import { SearchNodesCarouselComponent_NodeFragment$key } from "@generated/SearchNodesCarouselComponent_NodeFragment.graphql";
import { AllNodesSearchContext } from "../../../infecto-lms-webapp/context/search-context/AllNodesSearchContextProvider";
import { NodesCarousel } from "../dashboard/NodesCarousel";
import { ElementTypeV2 } from "@generated/NodeCard_NodeFragment.graphql";
import { useDeepCompareEffect } from "../../../infecto-lms-webapp/hooks/useDeepCompareEffect";

const QUERY = graphql`
	query SearchNodesCarouselComponent_Query(
		$query: String
		$tagIds: [ID!]
		$elementTypes: [ElementTypeV2!]
	) {
		...SearchNodesCarouselComponent_NodeFragment
			@arguments(query: $query, tagIds: $tagIds, elementTypes: $elementTypes)
	}
`;

const FRAGMENT = graphql`
	fragment SearchNodesCarouselComponent_NodeFragment on Query
	@refetchable(queryName: "SearchNodesCarouselComponent_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 10 }
		after: { type: "String" }
		query: { type: "String" }
		tagIds: { type: "[ID!]" }
		elementTypes: { type: "[ElementTypeV2!]" }
	) {
		LearnV2 {
			NodesSearchResult(
				first: $first
				after: $after
				query: $query
				tagIds: $tagIds
				elementTypes: $elementTypes
			) @connection(key: "SearchNodesCarouselComponent_NodesSearchResult") {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						...NodeCard_NodeFragment
					}
					cursor
				}
			}
		}
	}
`;
interface OwnProps {
	elementType: ElementTypeV2;
}
export const SearchNodesCarouselComponent = ({ elementType }: OwnProps) => {
	const { allNodesFilters } = useContext(AllNodesSearchContext);

	const query = useLazyLoadQuery<SearchNodesCarouselComponent_Query>(
		QUERY,
		{
			...allNodesFilters,
			elementTypes: [elementType],
			query: allNodesFilters.query ? allNodesFilters.query : undefined,
		},
		{
			fetchPolicy: "store-and-network",
		},
	);

	const { data, loadNext, hasNext, refetch } = usePaginationFragment<
		SearchNodesCarouselComponent_Refetch,
		SearchNodesCarouselComponent_NodeFragment$key
	>(FRAGMENT, query);

	const results =
		data.LearnV2.NodesSearchResult?.edges?.filter((e) => !!e?.node).map((e) => e!.node!) || [];

	useDeepCompareEffect(() => {
		refetch({
			...allNodesFilters,
			elementTypes: [elementType],
			query: allNodesFilters.query ? allNodesFilters.query : undefined,
		});
	}, [allNodesFilters, elementType]);

	return (
		<div className="pb-5">
			{results.length > 0 ? (
				<div>
					<TotalCountFilteredNodesComponent elementType={elementType} />
					<NodesCarousel nodes={results} onLoadMore={() => loadNext(10)} hasNext={hasNext} />{" "}
				</div>
			) : null}
		</div>
	);
};
