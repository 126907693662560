import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MainSidebar } from "../components/navigation/MainSidebar";
import { MainFooter } from "../components/navigation/MainFooter";
import { TopBarWithSearchBarAndProfileMenu } from "../components/navigation/TopBarWithSearchBarAndProfileMenu";
import { GoBackButtonComponent } from "../components/navigation/GoBackButton.component";

interface OwnProps {
	children: any;
	rightSideSlot?: ReactNode;
	screenName: string;
	collapsed: boolean;
	containerId?: string;
}

export function PageDefaultContainer({
	children,
	rightSideSlot,
	screenName,
	collapsed,
	containerId,
}: OwnProps) {
	const topBarRef = useRef(null);
	const footerBarRef = useRef(null);
	const mainSideBarRef = useRef(null);
	const [mainSideBarWidth, setMainSideBarWidth] = useState(0);
	const [topBarHeight, setTopBarHeight] = useState(0);
	const [footerBarHeight, setFooterBarHeight] = useState(0);

	useEffect(() => {
		// @ts-ignore
		setMainSideBarWidth(mainSideBarRef.current.offsetWidth);
		// @ts-ignore
		setTopBarHeight(topBarRef.current.offsetHeight);

		// @ts-ignore
		setFooterBarHeight(footerBarRef.current.offsetHeight);
	}, []);
	return (
		<MainContainer
			className="flex flex-grow-1 max-h-full flex-column md:pt-3 md:pr-3 "
			style={{ boxSizing: "border-box" }}
		>
			<MainSideBarAndRightSlotContainer className="flex flex-row">
				<div ref={mainSideBarRef}>
					<MainSidebar collapsed={collapsed} className="hidden md:flex " />
				</div>

				<RightContentDefaultWrapperContainer
					sideBarWidth={mainSideBarWidth}
					className="flex flex-grow-1 flex-column"
				>
					<div ref={topBarRef}>
						<TopBarWithSearchBarAndProfileMenu />
					</div>

					<RightSlotContentDefaultContainer
						footerBarHeight={footerBarHeight}
						topBarsHeight={topBarHeight}
						className="flex flex-grow-1"
					>
						<div className="flex-grow-1 justify-content-center flex w-full">{children}</div>
						{rightSideSlot}
					</RightSlotContentDefaultContainer>
				</RightContentDefaultWrapperContainer>
			</MainSideBarAndRightSlotContainer>

			<div ref={footerBarRef}>
				<FooterContainer>
					<GoBackButtonComponent mainSideBarWidth={mainSideBarWidth} />
					<MainFooter containerId={containerId} screenName={screenName} />
				</FooterContainer>
			</div>
		</MainContainer>
	);
}
const RightSlotBackgroundColor = "#f8fdf7";
export interface ContentContainer {
	topBarsHeight: number;
	footerBarHeight: number;
}

export const MainSideBarAndRightSlotContainer = styled.div`
	overflow: auto;
`;

export const RightSlotContentDefaultContainer = styled.div<ContentContainer>`
	height: calc(
		100vh - (${(props) => `${props.topBarsHeight}px + ${props.footerBarHeight}px`}) - 1rem
	);
	@media screen and (max-width: 480px) {
		height: calc(100vh - (${(props) => `${props.topBarsHeight}px + ${props.footerBarHeight}px`}));
	}
`;
export interface WrapperDefaultContainer {
	sideBarWidth: number;
}
export const RightContentDefaultWrapperContainer = styled.div<WrapperDefaultContainer>`
	width: calc(100vw - ${(props) => props.sideBarWidth}px);
	background-color: ${RightSlotBackgroundColor};
`;

const MainContainer = styled.div`
	height: 100%;
	background: linear-gradient(
		93deg,
		rgba(255, 255, 255, 0.1) 8.2%,
		rgba(75, 165, 64, 0.3) 30.35%,
		rgba(75, 165, 64, 0.4) 50.35%,
		rgba(75, 165, 64, 0.3) 70.35%,
		rgba(75, 165, 64, 0.2) 90.03%,
		#e6f9e6 97.51%
	);
`;
const FooterContainer = styled.div`
	height: 100%;
	display: flex;
	justify-content: space-between;
`;
