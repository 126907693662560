import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { ROLE_ADMIN, ROLE_USER } from "../../../../infecto-lms-webapp/router/UserRoles";
import { LearningAchievementsScreen } from "../../../../app/screens/LearningAchievements.screen";

export const LEARNING_ACHIEVEMENT_PATH = "/lernerfolg";

export const LearningAchievementsRoutes: PrivateRouteDefinition[] = [
	{
		path: LEARNING_ACHIEVEMENT_PATH,
		component: <LearningAchievementsScreen />,
		requiredRoles: [ROLE_ADMIN, ROLE_USER],
	},
];
