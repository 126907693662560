import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { NodeCardIconTextContainer } from "../../containers/NodeCardIconText.container";
import { AwardIcon, ClockIcon } from "./SvgNodeIcons.component";
import { useFragment } from "react-relay";
import { DurationDisplay } from "../../../infecto-lms-webapp/functions/helpers/DurationDisplay";
import { TextContainer } from "./NodeCardDate.component";
import { NodeCardDurationAndAwardComponent_Fragment$key } from "@generated/NodeCardDurationAndAwardComponent_Fragment.graphql";
import { singularPluralPointsDisplay } from "../../../infecto-lms-webapp/functions/helpers/singularPluralPointsDisplay";

const FRAGMENT = graphql`
	fragment NodeCardDurationAndAwardComponent_Fragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						lengthInSecondsOverride
						reducedDataInTree {
							cmePoints
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	isUserRewardedWithCertificate: boolean;
	learnOpportunityFragmentRef: NodeCardDurationAndAwardComponent_Fragment$key;
}

export const NodeCardDurationAndAwardComponent = ({
	isUserRewardedWithCertificate,
	learnOpportunityFragmentRef,
}: OwnProps) => {
	const learnOpportunity = useFragment<NodeCardDurationAndAwardComponent_Fragment$key>(
		FRAGMENT,
		learnOpportunityFragmentRef,
	);

	const points = learnOpportunity.structureDefinition.extension?.reducedDataInTree?.cmePoints;
	const duration = learnOpportunity.structureDefinition.extension?.lengthInSecondsOverride;

	let icon = null;
	let text = null;

	if (isUserRewardedWithCertificate) {
		if (points && points > 0) {
			icon = <AwardIcon />;
			text = `${points} CME-${singularPluralPointsDisplay(points)} erhalten`;
		}
	} else {
		if (duration) {
			icon = <ClockIcon />;
			text = <DurationDisplay duration={duration as number} text={"Minuten"} />;
		} else {
			icon = <ClockIcon />;
			text = <span>-</span>;
		}
	}

	return (
		<div>
			<NodeCardIconTextContainer>
				{icon}
				<TextContainer>{text}</TextContainer>
			</NodeCardIconTextContainer>
		</div>
	);
};
