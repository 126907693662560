import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ShowAnswersButton_LearnOpportunityFragment$key } from "@generated/ShowAnswersButton_LearnOpportunityFragment.graphql";
import { ShowAnswersDialog } from "./multiplechoice/ShowAnswersDialog";
import { PrimaryButton } from "../buttons/PrimaryButton";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment ShowAnswersButton_LearnOpportunityFragment on LearnOpportunityV2 {
		id
		typeDefinition {
			definitionType
			... on LearnOpportunityELearningContentTypeDefinition {
				showAnswerElements {
					id
					elementType
					... on MultipleChoiceElementV2 {
						question
						answerOptions {
							answer
							isCorrect
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: ShowAnswersButton_LearnOpportunityFragment$key;
}

export function ShowAnswersButton({ learnOpportunityFragmentRef }: OwnProps) {
	// const navigate = useNavigate();

	const learnOpportunity = useFragment<ShowAnswersButton_LearnOpportunityFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	const multipleChoiceElements = learnOpportunity.typeDefinition.showAnswerElements
		? learnOpportunity.typeDefinition.showAnswerElements?.filter(
				(e) => e.elementType == "multipleChoice",
		  )
		: [];

	const [isShowAnswersDialogVisible, setIsShowAnswersDialogVisible] = useState(false);

	if (
		learnOpportunity.typeDefinition.definitionType !== "content" ||
		multipleChoiceElements.length <= 0
	)
		return null;

	return (
		<>
			<PrimaryButton
				label="Antworten ansehen"
				className="mb-1 md:mb-0"
				tooltipOptions={{ showOnDisabled: true, position: "left" }}
				onClick={() => {
					// navigate(getAnswersPath(learnOpportunity.id));
					setIsShowAnswersDialogVisible(true);
				}}
			/>

			<ShowAnswersDialog
				isVisible={isShowAnswersDialogVisible}
				onHideDialog={() => setIsShowAnswersDialogVisible(false)}
				elements={multipleChoiceElements}
			/>
		</>
	);
}
