import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeStatusMessageComponent_NodeFragment$key } from "@generated/NodeStatusMessageComponent_NodeFragment.graphql";
import { HideableMessage } from "./HideableMessage";

const FRAGMENT = graphql`
	fragment NodeStatusMessageComponent_NodeFragment on LearnOpportunityV2 {
		id
		root {
			id
			structureDefinition {
				... on LearnOpportunityRootStructureDefinition {
					extension {
						... on LearnOpportunityRootExtensionImpl {
							configConsequences {
								rewardExpired
								showAnswerExpired
							}
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeStatusMessageComponent_NodeFragment$key;
}

export const NodeStatusMessageComponent = ({ learnOpportunityFragmentRef }: OwnProps) => {
	const node = useFragment<NodeStatusMessageComponent_NodeFragment$key>(
		FRAGMENT,
		learnOpportunityFragmentRef,
	);
	const [isRewardExpiredMessageVisible, setRewardExpiredMessageVisible] = useState(true);
	const [isShowAnswerExpiredMessageVisible, setShowAnswerExpiredMessageVisible] = useState(true);
	return (
		<div>
			{(node.root?.structureDefinition.extension?.configConsequences?.rewardExpired || false) &&
			isRewardExpiredMessageVisible ? (
				<div className="mb-4">
					<HideableMessage
						onClick={() => setRewardExpiredMessageVisible(false)}
						content="ACHTUNG: Dieses Modul ist abgelaufen. Es werden keine Punkte / Zertifikate für das Bestehen vergeben."
					/>
				</div>
			) : null}
			{(node.root?.structureDefinition.extension?.configConsequences?.showAnswerExpired || false) &&
			isShowAnswerExpiredMessageVisible ? (
				<div className="mb-4">
					<HideableMessage
						onClick={() => setShowAnswerExpiredMessageVisible(false)}
						content="Sie können sich nun die Antworten anzeigen lassen."
					/>
				</div>
			) : null}
		</div>
	);
};
