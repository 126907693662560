import React from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { NodeCard } from "../../infecto-lms-webapp-impl/components/NodeCard.component";
import { UserStartedTreesStates_Query } from "@generated/UserStartedTreesStates_Query.graphql";
import { UserStartedTreesStates_Refetch } from "@generated/UserStartedTreesStates_Refetch.graphql";
import { UserStartedTreesStates_NodeFragment$key } from "@generated/UserStartedTreesStates_NodeFragment.graphql";
import styled from "styled-components";

const QUERY = graphql`
	query UserStartedTreesStates_Query {
		...UserStartedTreesStates_NodeFragment
	}
`;

const LEARN_OPPORTUNITIES_FRAGMENT = graphql`
	fragment UserStartedTreesStates_NodeFragment on Query
	@refetchable(queryName: "UserStartedTreesStates_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" }) {
		LearnV2 {
			SearchLearnOpportunities(first: $first, after: $after, searchOptions: [onlyShowStarted])
				@connection(key: "UserStartedTreesStates_SearchLearnOpportunities") {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						id
						...NodeCard_NodeFragment
					}
				}
			}
		}
	}
`;

export function UserStartedTreesStates() {
	const queryData = useLazyLoadQuery<UserStartedTreesStates_Query>(QUERY, {});

	const { data, loadNext, hasNext } = usePaginationFragment<
		UserStartedTreesStates_Refetch,
		UserStartedTreesStates_NodeFragment$key
	>(LEARN_OPPORTUNITIES_FRAGMENT, queryData);

	const results =
		data.LearnV2.SearchLearnOpportunities.edges?.filter((e) => !!e?.node).map((e) => e!.node!) ||
		[];

	return (
		<>
			<div className="grid w-12">
				{results?.map((learnOpportunity) => (
					<NodeCardsContainer className="lg:col-4 col-12" key={learnOpportunity.id}>
						<NodeCard ignoreUserLearnState={false} learnOpportunityFragmentRef={learnOpportunity} />
					</NodeCardsContainer>
				))}
				{results.length === 0 && (
					<div className="text-center col-12">Sie haben keine gestarteten Lernerfahrungen.</div>
				)}
			</div>
			<div className="flex justify-content-center mt-4">
				{hasNext && <Button label="Mehr laden..." onClick={() => loadNext(10)} />}
			</div>
		</>
	);
}
export const NodeCardsContainer = styled.div`
	@media screen and (max-width: 1320px) and (min-width: 992px) {
		flex: 0 0 auto;
		padding: 0.5rem;
		width: 50%;
	}
`;
