import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { formatDateTime } from "../../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { PointsPoolDisplayWithDateComponent_Query } from "@generated/PointsPoolDisplayWithDateComponent_Query.graphql";

const QUERY = graphql`
	query PointsPoolDisplayWithDateComponent_Query($pointPoolId: ID!, $limitByDays: Int) {
		CountPointsWithDate {
			pointsCountWithDate(pointPoolId: $pointPoolId, limitByDays: $limitByDays) {
				pointsAmount
				pointPoolName
				datePointsFirstEarned
			}
		}
	}
`;

export const PointsPoolDisplayWithDateComponent = () => {
	const query = useLazyLoadQuery<PointsPoolDisplayWithDateComponent_Query>(QUERY, {
		pointPoolId: process.env.REACT_APP_POINTS_POOL_ID!,
		limitByDays: 1825,
	});

	const pointsValues = query.CountPointsWithDate.pointsCountWithDate;

	return (
		<div>
			{pointsValues && pointsValues.pointsAmount > 0 ? (
				<div className="mt-4 flex flex-column md:flex-row align-items-center justify-content-center">
					<div className="text-base mr-0 md:mr-2">
						{`Erhaltene ${pointsValues?.pointPoolName} seit dem ${formatDateTime(
							pointsValues?.datePointsFirstEarned,
							false,
						)}:`}
					</div>
					<span className="text-primary font-bold text-xl m-0 p-0">
						{pointsValues?.pointsAmount}
					</span>
				</div>
			) : null}
		</div>
	);
};
