import React from "react";
import { Icon, ShortLine } from "../dashboard/SkeletonCard";
import { NodesCarouselSkeleton } from "../dashboard/NodesCarouselSkeleton";

export const SearchNodesCarouselSkeletonComponent = () => {
	return (
		<div>
			<div className="flex flex-row mb-4 w-3">
				<ShortLine className="ml-4 mr-3" /> <Icon />
			</div>
			<NodesCarouselSkeleton />
		</div>
	);
};
