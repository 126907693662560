import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { TreeAdvancementMessageComponent_NodeFragment$key } from "@generated/TreeAdvancementMessageComponent_NodeFragment.graphql";
import { HideableMessage } from "./HideableMessage";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment TreeAdvancementMessageComponent_NodeFragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				viewerTreeState {
					kind
				}
			}
		}
	}
`;

interface OwnProps {
	nodeFragment: TreeAdvancementMessageComponent_NodeFragment$key;
}

export function TreeAdvancementMessageComponent({ nodeFragment }: OwnProps) {
	const node = useFragment<TreeAdvancementMessageComponent_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		nodeFragment,
	);
	const [isTreeAdvanceMessageVisible, setTreeAdvanceMessageVisible] = useState(true);

	switch (node.structureDefinition.viewerTreeState?.kind) {
		case "IsFinished":
			return (
				<div>
					{isTreeAdvanceMessageVisible ? (
						<HideableMessage
							onClick={() => setTreeAdvanceMessageVisible(false)}
							content={
								<div>
									<strong>Hinweis:</strong> Sie haben dieses Modul bereits erfolgreich absolviert.
								</div>
							}
						/>
					) : null}
				</div>
			);
		default:
			return null;
	}
}
