import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	flex-wrap: wrap;
`;

interface OwnProps {
	children: any;
}

export const NodeCardLearnProgressContainer = ({ children }: OwnProps) => {
	return <Container>{children}</Container>;
};
