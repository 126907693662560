/**
 * @generated SignedSource<<98a4041a4ab1bf467253927d387d765f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PointsPoolDisplayWithDateComponent_Query$variables = {
  limitByDays?: number | null;
  pointPoolId: string;
};
export type PointsPoolDisplayWithDateComponent_Query$data = {
  readonly CountPointsWithDate: {
    readonly pointsCountWithDate: {
      readonly datePointsFirstEarned: any | null;
      readonly pointPoolName: string;
      readonly pointsAmount: number;
    };
  };
};
export type PointsPoolDisplayWithDateComponent_Query = {
  response: PointsPoolDisplayWithDateComponent_Query$data;
  variables: PointsPoolDisplayWithDateComponent_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limitByDays"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pointPoolId"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CountPointsWithDateQueries",
    "kind": "LinkedField",
    "name": "CountPointsWithDate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "limitByDays",
            "variableName": "limitByDays"
          },
          {
            "kind": "Variable",
            "name": "pointPoolId",
            "variableName": "pointPoolId"
          }
        ],
        "concreteType": "PointsCountedWithDate",
        "kind": "LinkedField",
        "name": "pointsCountWithDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pointsAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pointPoolName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datePointsFirstEarned",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PointsPoolDisplayWithDateComponent_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PointsPoolDisplayWithDateComponent_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9f8ff27f885afc085aebe3b52dac08b9",
    "id": null,
    "metadata": {},
    "name": "PointsPoolDisplayWithDateComponent_Query",
    "operationKind": "query",
    "text": "query PointsPoolDisplayWithDateComponent_Query(\n  $pointPoolId: ID!\n  $limitByDays: Int\n) {\n  CountPointsWithDate {\n    pointsCountWithDate(pointPoolId: $pointPoolId, limitByDays: $limitByDays) {\n      pointsAmount\n      pointPoolName\n      datePointsFirstEarned\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1a2d249234d2f7dcc8a846957a2db56";

export default node;
