import graphql from "babel-plugin-relay/macro";
import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { AllNodesSearchContext } from "../../../infecto-lms-webapp/context/search-context/AllNodesSearchContextProvider";
import { TotalCountFilteredNodesComponent_TotalCountQuery } from "@generated/TotalCountFilteredNodesComponent_TotalCountQuery.graphql";
import { ElementTypeV2 } from "@generated/NodeCard_NodeFragment.graphql";

const QUERY = graphql`
	query TotalCountFilteredNodesComponent_TotalCountQuery(
		$query: String
		$tagIds: [ID!]
		$elementTypes: [ElementTypeV2!]
	) {
		LearnV2 {
			NodesSearchResultCount(query: $query, tagIds: $tagIds, elementTypes: $elementTypes) {
				totalAmount
			}
		}
	}
`;
interface OwnProps {
	elementType: ElementTypeV2;
}
export const TotalCountFilteredNodesComponent = ({ elementType }: OwnProps) => {
	const { allNodesFilters } = useContext(AllNodesSearchContext);
	const totalAmountQuery = useLazyLoadQuery<TotalCountFilteredNodesComponent_TotalCountQuery>(
		QUERY,
		{
			elementTypes: [elementType],
			tagIds: allNodesFilters.tagIds,
			query: allNodesFilters.query,
		},
		{
			fetchPolicy: "store-and-network",
		},
	);
	const getTypeElementName = (type: ElementTypeV2, total: number) => {
		if (total === 1) {
			switch (type) {
				case "file":
					return "Heft";
				case "podcast":
					return "Podcast";
				case "video":
					return "Video";
				default:
					return "";
			}
		} else {
			switch (type) {
				case "file":
					return "Hefte";
				case "podcast":
					return "Podcasts";
				case "video":
					return "Videos";
				default:
					return "";
			}
		}
	};
	const total = totalAmountQuery.LearnV2.NodesSearchResultCount?.totalAmount || 0;
	const typeElement = getTypeElementName(elementType, total);
	return <div className="ml-4 mb-4 lg: ml-0">{`${typeElement} (${total})`}</div>;
};
