/**
 * @generated SignedSource<<f5530f571957929ca0ea6ca96bb9f33b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchTagsFilters_QueryFragment$data = {
  readonly Learn: {
    readonly Tags: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly colorHex: string;
          readonly id: string;
          readonly name: string;
        };
      } | null> | null;
    };
  };
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly extension: {
            readonly fachrichtung?: string | null;
          };
        };
      } | null;
    };
  };
  readonly " $fragmentType": "SearchTagsFilters_QueryFragment";
};
export type SearchTagsFilters_QueryFragment$key = {
  readonly " $data"?: SearchTagsFilters_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchTagsFilters_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchTagsFilters_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnQueries",
      "kind": "LinkedField",
      "name": "Learn",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TagsConnection",
          "kind": "LinkedField",
          "name": "Tags",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TagsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tag",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "colorHex",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthViewerSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CurrentUser",
              "kind": "LinkedField",
              "name": "currentUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "extension",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fachrichtung",
                              "storageKey": null
                            }
                          ],
                          "type": "InfectopharmUserExtensionImpl",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d8e9c23aa1aed7c8d5f0aed835cad7b7";

export default node;
