/**
 * @generated SignedSource<<02069a328fdb871fabda112a5e4d2952>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeCardDateComponent_Fragment$data = {
  readonly structureDefinition: {
    readonly extension?: {
      readonly configConsequences?: {
        readonly isRewardedTo: string | null;
        readonly rewardExpired: boolean;
      };
      readonly firstLearnOpportunityIdWithMultipleChoiceElement?: string | null;
      readonly firstReleasedAt?: string | null;
      readonly heftContentSubmissionInfo?: {
        readonly activeContentSubmissionStartedAt: string | null;
        readonly hasActiveContentSubmission: boolean;
      };
      readonly issuedCertificates?: ReadonlyArray<{
        readonly issuedAt: string;
      }>;
      readonly multipleChoiceContentSubmissionInfo?: {
        readonly passedContentSubmissionFinishedAt: string | null;
      };
      readonly reducedDataInTree?: {
        readonly cmePoints: number | null;
      };
    };
  };
  readonly " $fragmentType": "NodeCardDateComponent_Fragment";
};
export type NodeCardDateComponent_Fragment$key = {
  readonly " $data"?: NodeCardDateComponent_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeCardDateComponent_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeCardDateComponent_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "InfectopharmTreeConfigConsequences",
                      "kind": "LinkedField",
                      "name": "configConsequences",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isRewardedTo",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rewardExpired",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReducedDataInTree",
                      "kind": "LinkedField",
                      "name": "reducedDataInTree",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cmePoints",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstLearnOpportunityIdWithMultipleChoiceElement",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentSubmissionInfo",
                      "kind": "LinkedField",
                      "name": "multipleChoiceContentSubmissionInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "passedContentSubmissionFinishedAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentSubmissionInfo",
                      "kind": "LinkedField",
                      "name": "heftContentSubmissionInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasActiveContentSubmission",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "activeContentSubmissionStartedAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstReleasedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IssuedCertificateV2",
                      "kind": "LinkedField",
                      "name": "issuedCertificates",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "issuedAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "9c4472b5fecfa9712c083a3f1645fed2";

export default node;
