import React from "react";
import styled from "styled-components";

interface OwnProps {
	title: string;
}

export const NodeCardTitleComponent = ({ title }: OwnProps) => {
	const textLength = 79;
	const truncatedTitle = truncateTextShowingLastCompleteWord(title, textLength);
	return (
		<Container>
			<Title>{truncatedTitle}</Title>
		</Container>
	);
};

const truncateTextShowingLastCompleteWord = (text: string, maxLength: number) => {
	if (text.length <= maxLength) return text;
	const truncated = text.substring(0, maxLength);
	const lastSpaceIndex = truncated.lastIndexOf(" ");
	return `${truncated.substring(0, lastSpaceIndex)} ...`;
};

const Title = styled.p`
	word-break: break-word;
	hyphens: auto;
	flex: 1 0 0;
	color: #000;
	font-size: 17px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	height: 100%;
	@media screen and (max-width: 1024px) {
		font-size: 15px;
	}
`;

const Container = styled.div`
	height: 80px;
	margin-bottom: 20px;
	@media screen and (max-width: 1024px) {
		margin-bottom: 15px;
	}
`;
