import React, { Suspense } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { TabPanel } from "primereact/tabview";
import { UserTreesScreen_Query } from "@generated/UserTreesScreen_Query.graphql";
import { UserStartedTreesStates } from "../components/UserStartedTreesStates.component";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { UserStatusMessage } from "../../infecto-lms-webapp/components/UserStatusMessage.component";
import { UserFinishedTreeStatesWithCertificate } from "../components/UserFinishedTreeStatesWithCertificate.component";
import { UserFinishedTreeStatesWithoutCertificate } from "../components/UserFinishedTreeStatesWithoutCertificate.component";
import { PointsPoolDisplay } from "../../infecto-lms-webapp/components/PointsPoolDisplay.component";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { Loader } from "../../infecto-lms-webapp/components/loading.component";
import {
	ResponsiveTabView,
	tabHeaderTemplate,
} from "../components/dashboard/CarouselsTabs.component";

const DASHBOARD_QUERY = graphql`
	query UserTreesScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on InfectopharmUserExtensionImpl {
								salutation
								title
								lastName
								efnNumber
							}
						}
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
						...PointsPoolDisplay_UserFragment
					}
				}
			}
		}
	}
`;

export function UserTreesScreen() {
	usePageTitle("Meine Lernerfahrungen");

	const query = useLazyLoadQuery<UserTreesScreen_Query>(
		DASHBOARD_QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	return (
		<DefaultContainer
			collapsed={false}
			screenName="learn-states"
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user}
		>
			<MainContainerWithTopBar>
				<UserStatusMessage userFragment={query.Viewer.Auth.currentUser?.user!} />

				<h1>
					<PointsPoolDisplay userFragment={query.Viewer.Auth.currentUser?.user!} />
				</h1>
				<p className="text-base">
					Hier finden Sie alle Module, die Sie begonnen oder abgeschlossen haben sowie Ihre
					erworbenen Zertifikate zum Herunterladen.
				</p>
				<ResponsiveTabView>
					<TabPanel
						className="mr-5"
						headerTemplate={(options) => tabHeaderTemplate(options, "Gestartete Module")}
					>
						<Suspense fallback={<Loader />}>
							<UserStartedTreesStates />
						</Suspense>
					</TabPanel>

					<TabPanel
						className="mr-5"
						headerTemplate={(options) =>
							tabHeaderTemplate(options, "Abgeschlossene Module (mit Zertifikat)")
						}
					>
						<Suspense fallback={<Loader />}>
							<UserFinishedTreeStatesWithCertificate />
						</Suspense>
					</TabPanel>

					<TabPanel
						className="mr-5"
						headerTemplate={(options) =>
							tabHeaderTemplate(options, "Abgeschlossene Module (ohne Zertifikat)")
						}
					>
						<Suspense fallback={<Loader />}>
							<UserFinishedTreeStatesWithoutCertificate />
						</Suspense>
					</TabPanel>
				</ResponsiveTabView>
			</MainContainerWithTopBar>
		</DefaultContainer>
	);
}
