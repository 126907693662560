import React from "react";
import styled from "styled-components";
import { Carousel, CarouselResponsiveOptions } from "primereact/carousel";
import { NodeCard } from "../../../infecto-lms-webapp-impl/components/NodeCard.component";
import { NodeCard_NodeFragment$key } from "@generated/NodeCard_NodeFragment.graphql";
import { PrimaryButton } from "../../../infecto-lms-webapp/components/buttons/PrimaryButton";

export const NodesCarouselContainer = styled.div`
	background: transparent;
	width: 100%;
	@media screen and (min-width: 768px) and (max-width: 786px) {
		width: calc(100vw - 300px);
	}
	@media screen and (max-width: 767px) {
		width: 95vw;
		padding: 1rem 0;
	}
`;

const StyledCarousal = styled(Carousel)`
	.p-carousel-indicators {
		display: none;
	}

	.p-carousel-content,
	.p-carousel-container {
		height: 100% !important;
		justify-content: center;
	}

	.p-carousel-items-content {
		height: 100% !important;
		justify-content: center;
		display: flex;
		flex-direction: column;
	}

	height: 400px;
	justify-content: center;
`;

const LoadMoreCardContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 1rem;
	background-color: #f0f0f0;
	border: 1px solid #d5d5d5;
	border-radius: 5px;
	text-align: center;
	width: 100%;
`;

export const ItemContainer = styled.div`
	height: 100%;
	margin-right: 10px;
	margin-left: 10px;
`;

const TransparentCardContainer = styled.div`
	height: 100%;
	width: 100%;
	background-color: transparent;
`;
interface OwnProps {
	nodes?: readonly NodeCard_NodeFragment$key[];
	hasNext?: boolean;
	onLoadMore?: () => void;
}
export const responsiveOptionsCarousel: CarouselResponsiveOptions[] = [
	{
		breakpoint: "3600px",
		numVisible: 6,
		numScroll: 1,
	},
	{
		breakpoint: "2400px",
		numVisible: 5,
		numScroll: 1,
	},
	{
		breakpoint: "1920px",
		numVisible: 4,
		numScroll: 1,
	},
	{
		breakpoint: "1536px",
		numVisible: 3,
		numScroll: 1,
	},
	{
		breakpoint: "1366px",
		numVisible: 3,
		numScroll: 1,
	},
	{
		breakpoint: "1300px",
		numVisible: 2,
		numScroll: 1,
	},
	{
		breakpoint: "1024px",
		numVisible: 2,
		numScroll: 1,
	},
	{
		breakpoint: "950px",
		numVisible: 1,
		numScroll: 1,
	},
	{
		breakpoint: "767px",
		numVisible: 2,
		numScroll: 1,
	},
	{
		breakpoint: "600px",
		numVisible: 1,
		numScroll: 1,
	},
];
export const NodesCarousel = ({ nodes = [], hasNext, onLoadMore }: OwnProps) => {
	const selectedBreakpoint = responsiveOptionsCarousel
		.sort((a, b) => Number(a.breakpoint.replace("px", "")) - Number(b.breakpoint.replace("px", "")))
		.find((option) => window.innerWidth <= Number(option.breakpoint.replace("px", "")));

	const numVisible = selectedBreakpoint?.numVisible || 1;
	const nodesWithLoadMore = hasNext ? [...nodes, null] : [...nodes];

	const emptyCardsCount = numVisible - nodesWithLoadMore.length;
	const emptyCards = Array(Math.max(0, emptyCardsCount)).fill(null);

	return (
		<div>
			<NodesCarouselContainer>
				<StyledCarousal
					value={[...nodesWithLoadMore, ...emptyCards] as []}
					responsiveOptions={responsiveOptionsCarousel}
					numVisible={1}
					numScroll={1}
					itemTemplate={(node) =>
						node ? (
							<ItemContainer>
								<NodeCard learnOpportunityFragmentRef={node} ignoreUserLearnState={false} />
							</ItemContainer>
						) : hasNext ? (
							<ItemContainer>
								<LoadMoreCardContainer>
									<PrimaryButton onClick={onLoadMore} label={"Mehr laden ..."} />
								</LoadMoreCardContainer>
							</ItemContainer>
						) : (
							<ItemContainer>
								<TransparentCardContainer />
							</ItemContainer>
						)
					}
				/>
			</NodesCarouselContainer>
		</div>
	);
};
