import React from "react";
import styled from "styled-components";
interface OwnProps {
	children: any;
}
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const NodeCardInformationContainer = ({ children }: OwnProps) => {
	return <Container>{children}</Container>;
};
