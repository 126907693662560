import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { LMS_WEBAPP_CONFIG_IMPL } from "../config";
import { NodeCardBadgeComponent } from "../../app/components/nodecard/NodeCardBadgeComponent";
import { NodeIconComponent } from "../../app/components/nodecard/NodeIcon.component";
import { NodeCardTitleComponent } from "../../app/components/nodecard/NodeCardTitle.component";
import { NodeCardContainer } from "../../app/containers/NodeCard.container";
import { NodeCardContentContainer } from "../../app/containers/NodeCardContent.container";
import { NodeCardHeaderContainer } from "../../app/containers/NodeCardHeader.container";
import {
	NodeCardDateComponent,
	TextContainer,
} from "../../app/components/nodecard/NodeCardDate.component";
import { NodeCardInformationContainer } from "../../app/containers/NodeCardInformation.container";
import { NodeCardDurationAndAwardComponent } from "../../app/components/nodecard/NodeCardDurationAndAward.component";
import { getLearnOpportunityPath } from "../router/routes/auth/nodes.routes";
import { DASHBOARD_PATH } from "../router/routes/auth/dashboard.routes";
import { NavLink } from "react-router-dom";
import { useTypedSelector } from "../../infecto-lms-webapp/redux/redux-store";
import { selectAuthSlice } from "../../infecto-lms-webapp/redux/slices/auth.slice";
import { NodeCardFooterContainer } from "../../app/containers/NodeCardFooterContainer";
import { NodeCardCertificateButton } from "../../app/components/nodecard/NodeCardCertificateButton";
import { isPermanentlyBlockedUtil } from "../../app/utils/advancement-result/is-permanently-blocked";
import { isTemporarilyBlockedUntilUtil } from "../../infecto-lms-webapp/utils/advancement-result/is-temporarily-blocked-until";
import { calculateDaysDifference } from "../../infecto-lms-webapp/functions/helpers/calculateDaysDifference";
import {
	NodeCardProgressBarComponent,
	ProgressBarState,
} from "../../app/components/nodecard/NodeCardProgressBar.component";
import { NodeCardIconTextContainer } from "../../app/containers/NodeCardIconText.container";
import { CalendarIcon } from "../../app/components/nodecard/SvgNodeIcons.component";
import { formatDateTime } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { ElementTypeV2, NodeCard_NodeFragment$key } from "@generated/NodeCard_NodeFragment.graphql";
import { NodeCard_LimitedAuthorizationsFragment$key } from "@generated/NodeCard_LimitedAuthorizationsFragment.graphql";
import { NodeCardProgressBarTextComponent } from "../../app/components/nodecard/NodeCardProgressBarText.component";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeCard_NodeFragment on LearnOpportunityV2 {
		id
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						isVisibleOnlyForAdmins
						issuedCertificates {
							issuedAt
							fileRef {
								url
							}
						}
						reducedDataInTree {
							cmePoints
						}
						firstReleasedAt
						firstLearnOpportunityIdWithMultipleChoiceElement
						heftContentSubmissionInfo {
							hasActiveContentSubmission
						}
						configConsequences {
							rewardExpired
							isRewardedTo
						}
					}
				}
			}
		}
		typeDefinition {
			... on LearnOpportunityELearningContentTypeDefinition {
				containedElementTypes
				contentNodeAdvancementResult {
					status
					... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
						status
						configResults {
							configType

							... on RestartIfFailedContentConfigResult {
								canBeRestarted
							}

							... on NegativeBlockPermanentlyWhenRewardedRestartIfFailedContentConfigResult {
								canBeRestarted
							}

							... on NegativeBlockTemporarilyWhenRewardedRestartIfFailedContentConfigResult {
								blockedUntil
							}
						}
					}
				}
			}
			... on LearnOpportunityBranchTypeDefinition {
				children {
					id
					typeDefinition {
						... on LearnOpportunityELearningContentTypeDefinition {
							containedElementTypes
							contentNodeAdvancementResult {
								status
								... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
									status
									configResults {
										configType

										... on RestartIfFailedContentConfigResult {
											canBeRestarted
										}

										... on NegativeBlockPermanentlyWhenRewardedRestartIfFailedContentConfigResult {
											canBeRestarted
										}

										... on NegativeBlockTemporarilyWhenRewardedRestartIfFailedContentConfigResult {
											blockedUntil
										}
									}
								}
							}
						}
					}
				}
			}
		}
		...NodeIcon_NodeFragment
		...NodeCardBadgeComponent_Fragment
		...NodeCardDateComponent_Fragment
		...NodeCardDurationAndAwardComponent_Fragment
		...NodeCardFooterContainer_LearnOpportunityFragment
		...NodeCardCertificateButton_Fragment
	}
`;

const WITHOUT_LEARNSTATE_FRAGMENT = graphql`
	fragment NodeCard_LimitedAuthorizationsFragment on LearnOpportunityV2 {
		id
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						configConsequences {
							rewardExpired
							isRewardedTo
						}
						firstLearnOpportunityIdWithMultipleChoiceElement
						firstReleasedAt
						reducedDataInTree {
							cmePoints
						}
					}
				}
			}
		}
		...NodeIcon_NodeFragment
		...NodeCardBadgeComponent_Fragment
		...NodeCardDurationAndAwardComponent_Fragment
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef?: NodeCard_NodeFragment$key;

	nodeWithoutUserLearnStateFragmentRef?: NodeCard_LimitedAuthorizationsFragment$key;
	ignoreUserLearnState: boolean;
}
export const MultipleChoiceElements: ElementTypeV2 = "multipleChoice" || "randomMultipleChoice";

export const NodeCard = ({
	learnOpportunityFragmentRef,
	nodeWithoutUserLearnStateFragmentRef,
	ignoreUserLearnState,
}: OwnProps) => {
	const nodeWithoutUserLearnStateFragment = useFragment<NodeCard_LimitedAuthorizationsFragment$key>(
		WITHOUT_LEARNSTATE_FRAGMENT,
		nodeWithoutUserLearnStateFragmentRef!,
	);
	const learnOpportunityLoggedIn = useFragment<NodeCard_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef!,
	);

	const learnOpportunity = learnOpportunityLoggedIn || nodeWithoutUserLearnStateFragment;
	const authState = useTypedSelector(selectAuthSlice);
	const isLoggedIn = authState.isLoggedIn;

	const extension = learnOpportunity.structureDefinition.extension;

	const multipleChoiceModul = learnOpportunity?.typeDefinition?.children
		? learnOpportunity.typeDefinition.children.filter((c) =>
				c?.typeDefinition?.containedElementTypes?.some((el) => el.includes("multipleChoice")),
		  )
		: learnOpportunity?.typeDefinition?.containedElementTypes?.some((el) =>
				el.includes("multipleChoice"),
		  )
		? [learnOpportunity]
		: [];

	const isTemporarilyBlockedUntil =
		multipleChoiceModul.length > 0 &&
		multipleChoiceModul[0]?.typeDefinition?.contentNodeAdvancementResult?.configResults &&
		isTemporarilyBlockedUntilUtil(
			multipleChoiceModul[0].typeDefinition.contentNodeAdvancementResult.configResults,
		);
	const isPermanentlyBlocked =
		multipleChoiceModul.length > 0 &&
		multipleChoiceModul[0]?.typeDefinition?.contentNodeAdvancementResult?.configResults &&
		isPermanentlyBlockedUtil(
			multipleChoiceModul[0].typeDefinition.contentNodeAdvancementResult.configResults,
		);
	const hasCanNotBeRestarteAfterPassedConfig =
		(multipleChoiceModul &&
			multipleChoiceModul[0]?.typeDefinition?.contentNodeAdvancementResult?.status ===
				"CanNotBeRestartedAfterPassed") ||
		false;
	const blockedUntil = !!(
		isTemporarilyBlockedUntil &&
		isTemporarilyBlockedUntil.blockedUntil &&
		isTemporarilyBlockedUntil.blockedUntil.length > 0
	);
	const doesNodeExpiresBeforeUserGetsUnblocked = !!(
		extension?.configConsequences?.isRewardedTo &&
		blockedUntil &&
		calculateDaysDifference(
			extension?.configConsequences?.isRewardedTo,
			isTemporarilyBlockedUntil?.blockedUntil,
		) < 0
	);
	const numIssuedCertificates =
		(!ignoreUserLearnState &&
			extension?.issuedCertificates! &&
			extension.issuedCertificates?.length) ||
		0;
	const isModulRewarded =
		((extension?.reducedDataInTree?.cmePoints || -1) > 0 &&
			!extension?.configConsequences?.rewardExpired) ||
		numIssuedCertificates > 0;
	const isModulNotRewardedOrIsBlockedOrExpired =
		!extension?.firstLearnOpportunityIdWithMultipleChoiceElement ||
		isPermanentlyBlocked ||
		doesNodeExpiresBeforeUserGetsUnblocked ||
		!isModulRewarded;

	const componentColor = isModulNotRewardedOrIsBlockedOrExpired
		? "#787878"
		: LMS_WEBAPP_CONFIG_IMPL.brandColor;

	const progressBarState: ProgressBarState = isModulNotRewardedOrIsBlockedOrExpired
		? "noRewarded"
		: "isRewarded";

	const hasNoLekButActiveContentSubmission =
		!extension?.firstLearnOpportunityIdWithMultipleChoiceElement &&
		extension?.heftContentSubmissionInfo?.hasActiveContentSubmission === true;

	const isShowingBadge =
		(isModulRewarded && !isPermanentlyBlocked && !doesNodeExpiresBeforeUserGetsUnblocked) ||
		(hasNoLekButActiveContentSubmission &&
			!isPermanentlyBlocked &&
			!doesNodeExpiresBeforeUserGetsUnblocked) ||
		hasCanNotBeRestarteAfterPassedConfig;

	const redirectToLearnOpportunityAfterLogin = () => {
		localStorage.setItem("landingPage", "true");
		localStorage.setItem("learnOpportunityId", learnOpportunity.id);
	};

	return (
		<NodeCardContainer
			onClick={redirectToLearnOpportunityAfterLogin}
			isVisibleOnlyForAdmins={
				learnOpportunity.structureDefinition.extension?.isVisibleOnlyForAdmins as boolean
			}
		>
			<NodeCardContentContainer>
				<NavLink
					className="h-full w-full"
					to={isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH}
				>
					<NodeCardHeaderContainer showLabelCard={isShowingBadge}>
						{isShowingBadge ? (
							<NodeCardBadgeComponent
								ignoreUserLearnState={ignoreUserLearnState}
								learnOpportunityFragmentRef={learnOpportunity}
								color={componentColor}
								isNodeFinished={
									numIssuedCertificates > 0 ||
									hasNoLekButActiveContentSubmission ||
									hasCanNotBeRestarteAfterPassedConfig
								}
							/>
						) : null}
						<NodeIconComponent learnOpportunityFragmentRef={learnOpportunity} />
					</NodeCardHeaderContainer>

					<NodeCardInformationContainer>
						<NodeCardTitleComponent title={learnOpportunity.structureDefinition.title} />

						{ignoreUserLearnState ? (
							<NodeCardIconTextContainer>
								<CalendarIcon></CalendarIcon>
								<TextContainer>
									{multipleChoiceModul &&
									learnOpportunity.structureDefinition.extension?.configConsequences
										?.isRewardedTo &&
									!learnOpportunity.structureDefinition.extension?.configConsequences?.rewardExpired
										? `bis ${formatDateTime(
												learnOpportunity.structureDefinition.extension?.configConsequences
													.isRewardedTo,
												false,
										  )} zertifiziert`
										: `verfügbar seit ${formatDateTime(
												learnOpportunity.structureDefinition.extension?.firstReleasedAt!,
												false,
										  )}`}
								</TextContainer>
							</NodeCardIconTextContainer>
						) : (
							<NodeCardDateComponent
								doesNodeExpiresBeforeUserGetsUnblocked={doesNodeExpiresBeforeUserGetsUnblocked}
								isUserRewardedWithCertificate={numIssuedCertificates > 0}
								learnOpportunityFragmentRef={learnOpportunity}
								hasCanNotBeRestarteAfterPassed={hasCanNotBeRestarteAfterPassedConfig}
								hasNoLekButActiveContentSubmission={hasNoLekButActiveContentSubmission}
							></NodeCardDateComponent>
						)}

						<NodeCardDurationAndAwardComponent
							isUserRewardedWithCertificate={numIssuedCertificates > 0}
							learnOpportunityFragmentRef={learnOpportunity}
						/>
					</NodeCardInformationContainer>
				</NavLink>

				{!ignoreUserLearnState && numIssuedCertificates > 0 ? (
					<NodeCardCertificateButton learnOpportunityFragmentRef={learnOpportunity} />
				) : (
					<div className="w-full align-items-start">
						<NavLink
							className="h-full"
							to={isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH}
						>
							{ignoreUserLearnState ? (
								<div className="flex-column w-full">
									<NodeCardProgressBarTextComponent isBold={false}>
										{multipleChoiceModul ? "Lernfortschritt" : "Ohne Lernerfolgskontrolle"}
									</NodeCardProgressBarTextComponent>

									<NodeCardProgressBarComponent
										progressBarState={progressBarState}
										componentColor={componentColor}
										isBlocked={false}
										percentage={0}
									/>
								</div>
							) : (
								<NodeCardFooterContainer
									progressBarState={progressBarState}
									componentColor={componentColor}
									learnOpportunityFragmentRef={learnOpportunity}
								></NodeCardFooterContainer>
							)}
						</NavLink>
					</div>
				)}
			</NodeCardContentContainer>
		</NodeCardContainer>
	);
};
