import React, { useState } from "react";
import { TextStyle } from "./MainFooter";
import { FeedbackPopup } from "./FeedbackPopup";

interface OwnProps {
	nodeId?: string;
	screen?: string;
}

export function FeedbackMenuLink({ nodeId, screen }: OwnProps) {
	const [modalVisible, setVisible] = useState(false);

	return (
		<div>
			<TextStyle onClick={() => setVisible(true)} className="mr-2 md:mr-4 my-0">
				FEEDBACK
			</TextStyle>
			<FeedbackPopup
				modalVisible={modalVisible}
				setVisible={setVisible}
				screen={screen}
				nodeId={nodeId}
			/>
		</div>
	);
}
