import styled from "styled-components";

export const PageMainText = styled.p`
	color: #000;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 1px;
	margin: 0;
`;
