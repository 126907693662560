import React from "react";
import { Tooltip } from "primereact/tooltip";
import styled from "styled-components";

interface OwnProps {
	targetTooltip: string;
	icon: any;
	tooltip: string;
}

export const NodeIconWrapperComponent = ({ targetTooltip, icon, tooltip }: OwnProps) => {
	return (
		<div className="flex align-items-center">
			<StyledTooltip position={"top"} mouseTrack target={targetTooltip} content={tooltip} />
			{icon}
		</div>
	);
};

const StyledTooltip = styled(Tooltip)`
	&.p-tooltip {
		background-color: transparent !important;
		border: none !important;
	}
	&.p-tooltip .p-tooltip-arrow {
		border-top-color: white !important;
	}

	&.p-tooltip .p-tooltip-text {
		color: black !important;
		background-color: white !important;
	}
`;
