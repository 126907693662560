import React from "react";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { DataPrivacyScreen_Query } from "@generated/DataPrivacyScreen_Query.graphql";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";
import { PageDefaultContainer } from "../containers/PageDefaultContainer";
import { WideContainerWithTopBar } from "../containers/WideContainerWithTopBar.container";

const QUERY = graphql`
	query DataPrivacyScreen_Query($brandId: ID!) {
		Viewer {
			Privacy {
				LatestPrivacy(brandId: $brandId) {
					id
					content
				}
			}
		}
	}
`;
export function DataPrivacyScreen() {
	usePageTitle("Datenschutz");

	const query = useLazyLoadQuery<DataPrivacyScreen_Query>(
		QUERY,
		{
			brandId: LMS_WEBAPP_CONFIG_IMPL.brandId,
		},
		{ fetchPolicy: "network-only" },
	);

	return (
		<PageDefaultContainer collapsed={false} screenName="DataPrivacyScreen">
			<WideContainerWithTopBar className="p-5 overflow-scroll flex-shrink-1">
				<div
					dangerouslySetInnerHTML={{
						__html: query.Viewer.Privacy.LatestPrivacy?.content as string,
					}}
				></div>
			</WideContainerWithTopBar>
		</PageDefaultContainer>
	);
}
