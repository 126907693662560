import React from "react";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import styled from "styled-components";
import { Checkbox } from "primereact/checkbox";

interface Ownprops {
	isShowingOnlyCertified: boolean;
	setIsShowingOnlyCertified: (value: boolean) => void;
}

export const ShowOnlyCertifiedCheckboxComponent = ({
	isShowingOnlyCertified,
	setIsShowingOnlyCertified,
}: Ownprops) => {
	return (
		<div className="flex pt-3 pb-3 justify-content-center lg:justify-content-end">
			<label className="flex align-items-center">
				<StyledCheckbox
					color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
					className="ml-auto"
					checked={isShowingOnlyCertified}
					onChange={(e) => setIsShowingOnlyCertified(e.checked!)}
				/>
				<span className="ml-2">nur zertifizierte Module anzeigen</span>
			</label>
		</div>
	);
};
export interface ColorProps {
	color: string;
}
export const StyledCheckbox = styled(Checkbox)<ColorProps>`
	.p-checkbox-box {
		border: 1px solid black !important;
	}
	.p-highlight {
		border-color: ${(props) => props.color}!important;
		background-color: ${(props) => props.color} !important;
		box-shadow: none !important;
	}
	.p-focus {
		box-shadow: none !important;
	}
`;
