import React from "react";
import styled from "styled-components";
import { Carousel } from "primereact/carousel";
import { SkeletonCard } from "./SkeletonCard";
import { ItemContainer, NodesCarouselContainer, responsiveOptionsCarousel } from "./NodesCarousel";

const StyledCarousal = styled(Carousel)`
	.p-carousel-indicators {
		display: none;
	}
`;

export const NodesCarouselSkeleton = () => {
	const skeletonItems = Array(6).fill(SkeletonCard);

	return (
		<div>
			<NodesCarouselContainer className="mt-4 mb-4">
				<StyledCarousal
					value={skeletonItems}
					responsiveOptions={responsiveOptionsCarousel}
					numVisible={4}
					numScroll={1}
					itemTemplate={() => (
						<ItemContainer>
							<SkeletonCard />
						</ItemContainer>
					)}
				/>
			</NodesCarouselContainer>
		</div>
	);
};
