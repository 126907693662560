import React from "react";
import { Dialog } from "primereact/dialog";
import { ValidatedField } from "../../../infecto-lms-webapp/components/form/ValidatedField.component";
import { DefaultTextAreaComponent } from "../../../infecto-lms-webapp/components/form/DefaultTextInput.component";
import { CustomButtonDialog } from "../../../infecto-lms-webapp/components/content-element/multiplechoice/ShowUserAnswersSummaryDialog.component";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { useMutation } from "react-relay";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { graphql } from "babel-plugin-relay/macro";
import { FeedbackPopup_LeaveFeedbackMutation } from "@generated/FeedbackPopup_LeaveFeedbackMutation.graphql";

const LEAVE_FEEDBACK_MUTATION = graphql`
	mutation FeedbackPopup_LeaveFeedbackMutation($input: InfectoLeaveFeedbackInput!) {
		FeedbackV2 {
			infectoLeaveFeedback(input: $input) {
				clientMutationId
			}
		}
	}
`;

interface FormState {
	body: string;
}

interface OwnProps {
	modalVisible: boolean;
	onHide?: () => void;
	setVisible: (v: boolean) => void;
	nodeId?: string;
	screen?: string;
}
export const FeedbackPopup = ({ onHide, modalVisible, setVisible, nodeId, screen }: OwnProps) => {
	const [leaveFeedback, isLeavingFeedback] =
		useMutation<FeedbackPopup_LeaveFeedbackMutation>(LEAVE_FEEDBACK_MUTATION);

	const formik = useFormik<FormState>({
		initialValues: {
			body: "",
		},
		validationSchema: Yup.object().shape({
			body: Yup.string().required("Das Feld Text wird benötigt."),
		}),
		onSubmit: (values) => {
			leaveFeedback({
				variables: {
					input: {
						body: values.body,
						screen,
						nodeId: nodeId,
					},
				},
				onCompleted: () => {
					toast("Feedback erfolgreich hinterlassen!", { type: "success" });
					setVisible(false);
					formik.resetForm();
					if (onHide) {
						onHide();
					}
				},
			});
		},
	});

	return (
		<Dialog
			className="w-9 md:w-6 lg:w-4"
			header={<p className="m-0 font-bold text-2xl md:text-3xl">Jetzt Feedback geben!</p>}
			onHide={() => {
				setVisible(false);
				if (onHide) {
					onHide();
				}
			}}
			visible={modalVisible}
		>
			<div>
				<p>
					Wir freuen uns über Ihr Feedback zum neuen Design von Wissenwirkt! Lassen Sie uns wissen,
					wie Ihnen das neue Layout gefällt und was wir verbessern können.
				</p>

				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<FormState, string>
						name="body"
						placeholder="Geben Sie hier Ihr Feedback ein..."
						formikConfig={formik}
						component={({
							isValid,
							fieldValue,
							fieldName,
							disabled,
							updateField,
							required,
							placeholder,
						}) => (
							<DefaultTextAreaComponent
								isValid={isValid}
								fieldValue={fieldValue}
								fieldName={fieldName}
								disabled={disabled}
								updateField={updateField}
								required={required}
								rows={8}
								placeholder={placeholder}
							/>
						)}
					/>

					<CustomButtonDialog
						color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
						disabled={isLeavingFeedback}
						type="submit"
						label="SENDEN"
						className="p-mt-2"
					/>
				</form>
			</div>
		</Dialog>
	);
};
