import React from "react";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeCardBadgeComponent_Fragment$key } from "@generated/NodeCardBadgeComponent_Fragment.graphql";
import { singularPluralPointsDisplay } from "../../../infecto-lms-webapp/functions/helpers/singularPluralPointsDisplay";

const FRAGMENT = graphql`
	fragment NodeCardBadgeComponent_Fragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						reducedDataInTree {
							cmePoints
						}
						configConsequences {
							rewardExpired
						}
					}
				}
			}
		}
	}
`;

const CardBadgeContainer = styled.div`
	color: #fff;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px;
`;

interface LabelProps {
	color: string;
	isNodeFinished: boolean;
}

const Label = styled.div<LabelProps>`
	display: flex;
	padding: 6px 8px;
	justify-content: space-between;
	align-items: flex-start;
	flex-shrink: 0;
	color: ${(props) => (props.isNodeFinished ? props.color : "#ffffff")};
	border: 1px solid ${(props) => props.color};
	background-color: ${(props) => (!props.isNodeFinished ? props.color : "#ffffff")};
	border-radius: 4px;
	backdrop-filter: blur(2px);
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeCardBadgeComponent_Fragment$key;
	color: string;
	isNodeFinished: boolean;
	ignoreUserLearnState: boolean;
}

export const NodeCardBadgeComponent = ({
	learnOpportunityFragmentRef,
	color,
	ignoreUserLearnState,
	isNodeFinished,
}: OwnProps) => {
	const fragment = useFragment<NodeCardBadgeComponent_Fragment$key>(
		FRAGMENT,
		learnOpportunityFragmentRef,
	);
	const points = fragment?.structureDefinition?.extension?.reducedDataInTree?.cmePoints;

	const isRewardExpired = fragment.structureDefinition.extension?.configConsequences?.rewardExpired; //Required only for screens without authorization, as the badge with Abgeschlossen is shown on authorized screens for Modules with expired awards
	let labelText;

	if (isNodeFinished) {
		labelText = "Abgeschlossen";
	} else if (points && points > 0) {
		labelText = `${points} CME ${singularPluralPointsDisplay(points).toUpperCase()}`;
	} else {
		labelText = "";
	}

	return (
		<CardBadgeContainer>
			{!ignoreUserLearnState ? (
				<Label color={color} isNodeFinished={isNodeFinished}>
					{labelText}
				</Label>
			) : points && points > 0 && !isRewardExpired ? (
				<Label color={color} isNodeFinished={false}>
					{`${
						fragment.structureDefinition.extension?.reducedDataInTree?.cmePoints
					} CME ${singularPluralPointsDisplay(points).toUpperCase()}`}
				</Label>
			) : null}
		</CardBadgeContainer>
	);
};
