import React, { useEffect, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { CertificatesScreen_QueryFragment$key } from "@generated/CertificatesScreen_QueryFragment.graphql";
import {
	CertificateAchievedCursorSortDirectionInput,
	CertificatesScreen_Refetch,
} from "@generated/CertificatesScreen_Refetch.graphql";
import { Card } from "primereact/card";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import styled from "styled-components";
import { CertificatesScreen_Query } from "@generated/CertificatesScreen_Query.graphql";
import { Dropdown } from "primereact/dropdown";
import { formatDateTime } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";

const QUERY = graphql`
	query CertificatesScreen_Query {
		...CertificatesScreen_QueryFragment
	}
`;

const QUERY_FRAGMENT = graphql`
	fragment CertificatesScreen_QueryFragment on Query
	@refetchable(queryName: "CertificatesScreen_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 10 }
		after: { type: "String" }
		sortDirection: { type: "CertificateAchievedCursorSortDirectionInput" }
	) {
		CertificateAchievedByUser {
			certificates(first: $first, after: $after, sortDirection: $sortDirection)
				@connection(key: "CertificatesScreen_certificates") {
				edges {
					node {
						name
						fileRef {
							url
						}
						issuedAt
					}
				}
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
					}
				}
			}
		}
	}
`;

export const CertificatesScreen = () => {
	usePageTitle("Meine Zertifikate");

	const [sortDirectionFilter, setSortDirectionFilter] = useState(
		"asc" as CertificateAchievedCursorSortDirectionInput,
	);

	const queryData = useLazyLoadQuery<CertificatesScreen_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const { data, hasNext, loadNext, refetch } = usePaginationFragment<
		CertificatesScreen_Refetch,
		CertificatesScreen_QueryFragment$key
	>(QUERY_FRAGMENT, queryData);

	useEffect(() => {
		refetch({
			sortDirection: sortDirectionFilter,
		});
	}, [sortDirectionFilter]);

	const certificates = data.CertificateAchievedByUser.certificates.edges?.map((c) => c?.node);

	return (
		<DefaultContainer
			collapsed={false}
			screenName="certificates"
			userDataFragmentRef={data.Viewer.Auth.currentUser?.user}
		>
			<MainContainerWithTopBar>
				<h1>Meine Zertifikate</h1>
				<p className="text-base mb-4">
					Hier finden Sie alle Ihre erworbenen Zertifikate zum Herunterladen.
				</p>
				<div className="w-full flex mb-4">
					<Dropdown
						className="ml-auto justify-content-end"
						placeholder="Sort by"
						options={[
							{ value: "asc", label: "Datum aufsteigend" },
							{ value: "desc", label: "Datum absteigend" },
						]}
						value={sortDirectionFilter}
						onChange={(e) => setSortDirectionFilter(e.value)}
					/>
				</div>
				{certificates?.length ? (
					<div className="flex flex-column align-items-center justify-content-center">
						<div className="grid w-12">
							{certificates?.map((certificate, index) => (
								<div key={index} className={`lg:col-6 col-12 `}>
									<CardContainer>
										<a
											target="_blank"
											rel="noopener noreferrer"
											download
											href={certificate?.fileRef?.url!}
										>
											<div className="p-1 flex">
												<h4 className="m-0 text-sm">{certificate?.name}</h4>
												<div className="ml-auto">
													<span className="font-bold ml-2 pi pi-cloud-download text-primary" />
												</div>
											</div>
											<span className="p-1 text-xs">
												{formatDateTime(certificate?.issuedAt!, false)}
											</span>
										</a>
									</CardContainer>
								</div>
							))}
							<div className="flex justify-content-center mt-4 w-full">
								{hasNext && <PrimaryButton label="Mehr laden..." onClick={() => loadNext(10)} />}
							</div>
						</div>
					</div>
				) : (
					<p className="text-base w-full mt-5 flex justify-content-center align-items-center">
						Sie haben noch keine Zertifikate gesammelt
					</p>
				)}
			</MainContainerWithTopBar>
		</DefaultContainer>
	);
};

const CardContainer = styled(Card)`
	display: flex;
	align-items: center;
	.p-card-body {
		width: 100% !important;
	}
`;
