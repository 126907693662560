import { MenuItem } from "primereact/menuitem";
import React from "react";
import { DASHBOARD_PATH } from "./router/routes/auth/dashboard.routes";
import { SEARCH_SCREEN_PATH } from "./router/routes/auth/search.routes";
import { MenuType } from "../infecto-lms-webapp/components/navigation/navigation.interface";
import { LEARNING_ACHIEVEMENT_PATH } from "./router/routes/auth/learning-achievements.routes";
import {
	DashboardIcon,
	LearningAchievementIcon,
	ModulesPageIcon,
	QuestionLinkIcon,
	TrainingPageIcon,
} from "../app/components/navigation/SVGMenuIcons.component";
import { NavigationMenuItemComponent } from "../app/components/navigation/NavigationMenuItem.component";
import { NavigationLinkMenuItemComponent } from "../app/components/navigation/NavigationLinkMenuItem.component";
import { useLocation } from "react-router-dom";
import { LMS_WEBAPP_CONFIG_IMPL } from "./config";

export const MAIN_MENU_ITEMS: (menuType: MenuType, collapsed: boolean) => MenuItem[] = (
	menuType: MenuType,
	collapsed: boolean,
) => {
	const location = useLocation();

	const isSelected = (url: string) => location.pathname === url;

	return [
		{
			label: "Startseite",
			url: DASHBOARD_PATH,
			icon: "pi pi-home",
			template: (item) => (
				<NavigationMenuItemComponent
					icon={
						<DashboardIcon
							color={isSelected(item.url!) ? LMS_WEBAPP_CONFIG_IMPL.brandColor : "black"}
						/>
					}
					onClick={() => {}}
					collapsed={collapsed}
					item={item}
					menuType={menuType}
				/>
			),
		},
		{
			label: "Module",
			url: SEARCH_SCREEN_PATH,
			icon: "pi pi-th-large",
			template: (item) => (
				<NavigationMenuItemComponent
					icon={
						<ModulesPageIcon
							color={isSelected(item.url!) ? LMS_WEBAPP_CONFIG_IMPL.brandColor : "black"}
						/>
					}
					onClick={() => {}}
					collapsed={collapsed}
					item={item}
					menuType={menuType}
				/>
			),
		},
		{
			label: "Fortbildung",
			url: "https://www.infectopharm.com/fortbildung/consilium/anmeldung-zu-fortbildungen/",
			icon: "pi pi-calendar",
			template: (item) => (
				<NavigationLinkMenuItemComponent
					icon={<TrainingPageIcon />}
					collapsed={collapsed}
					item={item}
					menuType={menuType}
				/>
			),
		},
		{
			label: "Frage stellen",
			url: "https://www.infectopharm.com/consilium/frage-an-das-consilium/",
			icon: "pi pi-question",
			template: (item) => (
				<NavigationLinkMenuItemComponent
					icon={<QuestionLinkIcon />}
					menuType={menuType}
					collapsed={collapsed}
					item={item}
				/>
			),
		},
		{
			label: "Lernerfolg",
			url: LEARNING_ACHIEVEMENT_PATH,
			icon: "award",
			template: (item) => (
				<NavigationMenuItemComponent
					onClick={() => {}}
					icon={
						<LearningAchievementIcon
							color={isSelected(item.url!) ? LMS_WEBAPP_CONFIG_IMPL.brandColor : "black"}
						/>
					}
					collapsed={collapsed}
					item={item}
					menuType={menuType}
				/>
			),
		},
		/*	{
            label: "Nachrichten",
            url: MESSAGING_SCREEN_PATH,
            icon: "pi pi-envelope",
            template: (item) => (
                <RouterMenuItem
                    icon={<i className={"text-lg " + item.icon} />}
                    onClick={() => {}}
                    collapsed={collapsed}
                    item={item}
                    menuType={"ProfileMenu"}
                />
            ),
        },*/
	];
};
