import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export const UserHasNoStartedNodesComponent = () => {
	const navigate = useNavigate();
	return (
		<ContainerNoStartedNodes>
			<div className="flex flex-column md:flex-row align-items-center justify-content-center">
				<p className="text-base mr-0 md:mr-2">Sie haben noch kein Modul begonnen. </p>
				<span className="underline text-primary" onClick={() => navigate("/alle-module")}>
					Zur Modulauswahl
				</span>
			</div>
		</ContainerNoStartedNodes>
	);
};
const ContainerNoStartedNodes = styled.div`
	height: 322px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
`;
