/**
 * @generated SignedSource<<a54710d358d439bcda68e7c622a78619>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CarouselsTabsComponent_CertificatedNodes_NodeFragment$data = {
  readonly LearnV2: {
    readonly CertificatedNodesExpiringInLessThanOneMonth: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"NodeCard_NodeFragment">;
        };
      } | null> | null;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
    };
  };
  readonly " $fragmentType": "CarouselsTabsComponent_CertificatedNodes_NodeFragment";
};
export type CarouselsTabsComponent_CertificatedNodes_NodeFragment$key = {
  readonly " $data"?: CarouselsTabsComponent_CertificatedNodes_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CarouselsTabsComponent_CertificatedNodes_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "LearnV2",
  "CertificatedNodesExpiringInLessThanOneMonth"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CarouselsTabsComponent_Refetch.graphql')
    }
  },
  "name": "CarouselsTabsComponent_CertificatedNodes_NodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnV2Queries",
      "kind": "LinkedField",
      "name": "LearnV2",
      "plural": false,
      "selections": [
        {
          "alias": "CertificatedNodesExpiringInLessThanOneMonth",
          "args": null,
          "concreteType": "LearnOpportunitiesV2Connection",
          "kind": "LinkedField",
          "name": "__CarouselsTabsComponent_CertificatedNodesExpiringInLessThanOneMonth_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunitiesV2Edge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LearnOpportunityV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "NodeCard_NodeFragment"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "45cc166649a89efca72d968af6a68142";

export default node;
