import React from "react";
import styled from "styled-components";
import { SecondaryButton } from "../../infecto-lms-webapp/components/buttons/SecondaryButton";
import { Card } from "primereact/card";

interface OwnProps {
	content: any;
	onClick: (value?: boolean) => void;
}
export const HideableMessage = ({ content, onClick }: OwnProps) => {
	return (
		<StyledCard className="mt-2 mb-6 w-12 pt-2 pb-2 pl-3 pr-2">
			<div className="flex flex-column">
				<div className="w-full flex justify-content-end align-items-center ml-3">
					<CloseButton icon="pi pi-times" onClick={() => onClick()}></CloseButton>
				</div>
				{content}
			</div>
		</StyledCard>
	);
};

const CloseButton = styled(SecondaryButton)`
	width: 20px !important;
	height: 20px !important;
	background: transparent !important;
	border: none !important;
	color: var(--text-color) !important;
`;

const StyledCard = styled(Card)`
	border-radius: 4px !important;
	.p-card-content {
		padding: 0 !important;
	}
	border: 1px solid var(--primary-color);
`;
