import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeCardCertificateButton_Fragment$key } from "@generated/NodeCardCertificateButton_Fragment.graphql";
import styled from "styled-components";
import { DownloadIcon } from "./SvgNodeIcons.component";

const FRAGMENT = graphql`
	fragment NodeCardCertificateButton_Fragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						issuedCertificates {
							fileRef {
								url
							}
						}
					}
				}
			}
		}
	}
`;

const CertificateButton = styled.div`
	border-radius: 4px;
	border: 1px solid #000;
	background: rgba(79, 175, 68, 0);
	display: flex;
	width: 100%;
	height: 44px;
	padding: 8px 24px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;

	&:hover {
		background-color: #efeff0;
	}
`;

const Label = styled.span`
	color: #000;
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.96px;
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeCardCertificateButton_Fragment$key;
}

export const NodeCardCertificateButton = ({ learnOpportunityFragmentRef }: OwnProps) => {
	const learnOpportunity = useFragment<NodeCardCertificateButton_Fragment$key>(
		FRAGMENT,
		learnOpportunityFragmentRef,
	);
	return (
		<div className="w-full">
			<a
				target="_blank"
				rel="noopener noreferrer"
				download
				href={learnOpportunity.structureDefinition.extension?.issuedCertificates![0].fileRef?.url!}
			>
				<CertificateButton>
					<Label>Zertifikat herunterladen</Label>
					<DownloadIcon />
				</CertificateButton>
			</a>
		</div>
	);
};
