import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { NodeScreen } from "../../../../app/screens/Node.screen";

export const LEARN_OPPORTUNITY_ID_URL_PARAM = ":learnOpportunityId";
export const getLearnOpportunityPath = (learnOpportunityId: string) => {
	return `/modul/${learnOpportunityId}`;
};

// TODO: remove after 01.01.2025 - this is temporary here as a fix for the reminder email sent with legacy URIs
export const getLegacyLearnOpportunityPath = (learnOpportunityId: string) => {
	return `/learn-opportunities/${learnOpportunityId}`;
};

export const NodesRoutes: PrivateRouteDefinition[] = [
	{
		path: getLearnOpportunityPath(LEARN_OPPORTUNITY_ID_URL_PARAM),
		component: <NodeScreen />,
	},
	// TODO: remove after 01.01.2025 - this is temporary here as a fix for the reminder email sent with legacy URIs
	{
		path: getLegacyLearnOpportunityPath(LEARN_OPPORTUNITY_ID_URL_PARAM),
		component: <NodeScreen />,
	},
];
