/**
 * @generated SignedSource<<f5b24696d8a6c2df6934fb9643191e66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type TotalCountFilteredNodesComponent_TotalCountQuery$variables = {
  elementTypes?: ReadonlyArray<ElementTypeV2> | null;
  query?: string | null;
  tagIds?: ReadonlyArray<string> | null;
};
export type TotalCountFilteredNodesComponent_TotalCountQuery$data = {
  readonly LearnV2: {
    readonly NodesSearchResultCount: {
      readonly totalAmount: number;
    };
  };
};
export type TotalCountFilteredNodesComponent_TotalCountQuery = {
  response: TotalCountFilteredNodesComponent_TotalCountQuery$data;
  variables: TotalCountFilteredNodesComponent_TotalCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "elementTypes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagIds"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LearnV2Queries",
    "kind": "LinkedField",
    "name": "LearnV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "elementTypes",
            "variableName": "elementTypes"
          },
          {
            "kind": "Variable",
            "name": "query",
            "variableName": "query"
          },
          {
            "kind": "Variable",
            "name": "tagIds",
            "variableName": "tagIds"
          }
        ],
        "concreteType": "nodesTotalAmount",
        "kind": "LinkedField",
        "name": "NodesSearchResultCount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TotalCountFilteredNodesComponent_TotalCountQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TotalCountFilteredNodesComponent_TotalCountQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "d4da7f088c9f0c905968a3c3671a26ef",
    "id": null,
    "metadata": {},
    "name": "TotalCountFilteredNodesComponent_TotalCountQuery",
    "operationKind": "query",
    "text": "query TotalCountFilteredNodesComponent_TotalCountQuery(\n  $query: String\n  $tagIds: [ID!]\n  $elementTypes: [ElementTypeV2!]\n) {\n  LearnV2 {\n    NodesSearchResultCount(query: $query, tagIds: $tagIds, elementTypes: $elementTypes) {\n      totalAmount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f83a3f206ae706694507df9a4f0339a";

export default node;
