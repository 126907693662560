import React from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { NodeCard } from "../../infecto-lms-webapp-impl/components/NodeCard.component";
import { UserFinishedTreeStatesWithoutCertificate_Query } from "@generated/UserFinishedTreeStatesWithoutCertificate_Query.graphql";
import { UserFinishedTreeStatesWithoutCertificate_Refetch } from "@generated/UserFinishedTreeStatesWithoutCertificate_Refetch.graphql";
import { UserFinishedTreeStatesWithoutCertificate_QueryFragment$key } from "@generated/UserFinishedTreeStatesWithoutCertificate_QueryFragment.graphql";
import { NodeCardsContainer } from "./UserStartedTreesStates.component";

const QUERY = graphql`
	query UserFinishedTreeStatesWithoutCertificate_Query {
		...UserFinishedTreeStatesWithoutCertificate_QueryFragment
	}
`;

const QUERY_FRAGMENT = graphql`
	fragment UserFinishedTreeStatesWithoutCertificate_QueryFragment on Query
	@refetchable(queryName: "UserFinishedTreeStatesWithoutCertificate_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" }) {
		LearnV2 {
			SearchLearnOpportunities(
				first: $first
				after: $after
				isCertified: false
				searchOptions: [onlyShowFinished]
			) @connection(key: "UserFinishedTreeStatesWithoutCertificate_SearchLearnOpportunities") {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						id
						...NodeCard_NodeFragment
					}
				}
			}
		}
	}
`;

export function UserFinishedTreeStatesWithoutCertificate() {
	const query = useLazyLoadQuery<UserFinishedTreeStatesWithoutCertificate_Query>(QUERY, {});

	const { data, loadNext, hasNext } = usePaginationFragment<
		UserFinishedTreeStatesWithoutCertificate_Refetch,
		UserFinishedTreeStatesWithoutCertificate_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const results =
		data.LearnV2.SearchLearnOpportunities.edges?.filter((e) => !!e?.node).map((e) => e!.node!) ||
		[];

	return (
		<>
			<div className="grid w-12">
				{results?.map((learnOpportunity) => (
					<NodeCardsContainer className="lg:col-4 col-12" key={learnOpportunity.id}>
						<NodeCard ignoreUserLearnState={false} learnOpportunityFragmentRef={learnOpportunity} />
					</NodeCardsContainer>
				))}
				{results.length === 0 && (
					<div className="text-center col-12">
						Sie haben leider noch keine aktiven Lernerfahrungen.
					</div>
				)}
			</div>
			<div className="flex justify-content-center mt-4">
				{hasNext && <Button label="Mehr laden..." onClick={() => loadNext(10)} />}
			</div>
		</>
	);
}
