import { useTypedDispatch } from "../../../infecto-lms-webapp/redux/redux-store";
import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { PROFILE_LINK_MENU_ITEMS } from "../../../infecto-lms-webapp-impl/impl-profile-menu-items";
import { logout } from "../../../infecto-lms-webapp/redux/slices/auth.slice";
import { UserIcon } from "./SVGMenuIcons.component";
import { removeAllHiddenUserMessage } from "../../../infecto-lms-webapp/redux/slices/hidden-user-messages.slice";
import styled from "styled-components";

interface OwnProps {
	className?: string;
}

export function ProfileMenu({ className }: OwnProps) {
	const dispatch = useTypedDispatch();
	const menu = useRef<Menu>();
	return (
		<div className={className}>
			<IconButton
				className="p-button-text text-700 font-bold text-sm bg-transparent"
				icon={<UserIcon />}
				onClick={(event) => menu.current!.toggle(event)}
			/>
			<StyledMenu
				model={PROFILE_LINK_MENU_ITEMS(
					() => {
						dispatch(logout());
						dispatch(removeAllHiddenUserMessage());
					},
					"ProfileMenu",
					false,
				)}
				popup
				ref={menu as any}
			/>
		</div>
	);
}

const StyledMenu = styled(Menu)`
	padding-left: 10px;
`;

const IconButton = styled(Button)`
	box-shadow: none !important;
`;
