import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { isTemporarilyBlockedUntilUtil } from "../../../infecto-lms-webapp/utils/advancement-result/is-temporarily-blocked-until";
import { isPermanentlyBlockedUtil } from "../../utils/advancement-result/is-permanently-blocked";
import { calculateDaysDifference } from "../../../infecto-lms-webapp/functions/helpers/calculateDaysDifference";
import { NodeCardProgressBarComponent, ProgressBarState } from "./NodeCardProgressBar.component";
import { NodeCardLearnProgressComponent_Fragment$key } from "@generated/NodeCardLearnProgressComponent_Fragment.graphql";
import { NodeCardLearnProgressContainer } from "../../containers/NodeCardLearnProgress.container";
import { NodeCardProgressBarTextComponent } from "./NodeCardProgressBarText.component";

const FRAGMENT = graphql`
	fragment NodeCardLearnProgressComponent_Fragment on LearnOpportunityV2 {
		root {
			structureDefinition {
				... on LearnOpportunityRootStructureDefinition {
					extension {
						... on LearnOpportunityRootExtensionImpl {
							configConsequences {
								restartTriesCount
							}
							heftContentSubmissionInfo {
								hasActiveContentSubmission
							}
							numberOfSubmittedAnswers
							multipleChoiceContentSubmissionInfo {
								hasActiveContentSubmission
							}
						}
					}
				}
			}
		}
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						configConsequences {
							restartTriesCount
						}
						heftContentSubmissionInfo {
							hasActiveContentSubmission
						}
						numberOfSubmittedAnswers
						multipleChoiceContentSubmissionInfo {
							hasActiveContentSubmission
						}
					}
				}
			}
		}
		typeDefinition {
			... on LearnOpportunityELearningContentTypeDefinition {
				extension {
					... on LearnContentExtensionImpl {
						amountOfMultipleChoiceElementsInContentSubmission
					}
				}
				contentNodeAdvancementResult {
					status
					... on CanNotBeStartedContentNodeAdvancementResult {
						reasonConfigTypes
					}
					... on ContinueContentNodeAdvancementResult {
						activeContentSubmissionId
					}
					... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
						configResults {
							configType

							... on RestartIfFailedContentConfigResult {
								canBeRestarted
							}

							... on NegativeBlockPermanentlyWhenRewardedRestartIfFailedContentConfigResult {
								canBeRestarted
							}

							... on NegativeBlockTemporarilyWhenRewardedRestartIfFailedContentConfigResult {
								blockedUntil
							}
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeCardLearnProgressComponent_Fragment$key;
	componentColor: string;
	progressBarState: ProgressBarState;
}

export const NodeCardLearnProgressComponent = ({
	learnOpportunityFragmentRef,
	componentColor,
	progressBarState,
}: OwnProps) => {
	const learnOpportunity = useFragment<NodeCardLearnProgressComponent_Fragment$key>(
		FRAGMENT,
		learnOpportunityFragmentRef,
	);

	const isTemporarilyBlockedUntil = isTemporarilyBlockedUntilUtil(
		learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.configResults,
	);
	const isPermanentlyBlocked = isPermanentlyBlockedUtil(
		learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.configResults,
	);

	const structureDefinitionExtension = learnOpportunity.root
		? learnOpportunity.root.structureDefinition.extension
		: learnOpportunity.structureDefinition.extension;

	const typeDefinitionExtension = learnOpportunity.typeDefinition.extension;

	const tries = structureDefinitionExtension?.configConsequences?.restartTriesCount;

	const progressPercentage =
		structureDefinitionExtension?.numberOfSubmittedAnswers &&
		typeDefinitionExtension?.amountOfMultipleChoiceElementsInContentSubmission
			? Math.round(
					(100 * structureDefinitionExtension?.numberOfSubmittedAnswers || 0) /
						typeDefinitionExtension?.amountOfMultipleChoiceElementsInContentSubmission,
			  )
			: 0;

	const isLekNoStartedAndThereAreNoAnswers =
		!structureDefinitionExtension?.multipleChoiceContentSubmissionInfo
			?.hasActiveContentSubmission && structureDefinitionExtension?.numberOfSubmittedAnswers === 0;
	const isLekStartedButThereAreNoAnswer =
		structureDefinitionExtension?.multipleChoiceContentSubmissionInfo?.hasActiveContentSubmission &&
		structureDefinitionExtension.numberOfSubmittedAnswers === 0;

	let status: string;

	if (isLekNoStartedAndThereAreNoAnswers) {
		status = "Nicht gestartet";
	} else if (isLekStartedButThereAreNoAnswer) {
		status = "Gestartet";
	} else {
		status = `${progressPercentage}%`;
	}

	const title = (() => {
		switch (learnOpportunity.typeDefinition.contentNodeAdvancementResult?.status) {
			case "CanBeStarted":
			case "Continue":
			case "CanBeRestartedAfterPassed":
			case "CanBeRestartedAfterFailed":
			case "BlockedByTree":
			case "CanNotBeStarted":
				return (
					<NodeCardLearnProgressContainer>
						<NodeCardProgressBarTextComponent isBold={false}>
							Lernfortschritt
						</NodeCardProgressBarTextComponent>
						<NodeCardProgressBarTextComponent isBold={true}>
							{status}
						</NodeCardProgressBarTextComponent>
					</NodeCardLearnProgressContainer>
				);
			case "CanNotBeRestartedAfterFailed":
				return isTemporarilyBlockedUntil ? (
					<NodeCardLearnProgressContainer>
						<NodeCardProgressBarTextComponent isBold={false}>
							Erneute Teilnahme in
						</NodeCardProgressBarTextComponent>
						<NodeCardProgressBarTextComponent isBold={true}>
							{calculateDaysDifference(isTemporarilyBlockedUntil.blockedUntil!)} Tagen
						</NodeCardProgressBarTextComponent>
					</NodeCardLearnProgressContainer>
				) : (
					<div className="flex w-full">
						<NodeCardProgressBarTextComponent isBold={true}>
							Nicht wiederholbar
						</NodeCardProgressBarTextComponent>
						{tries ? (
							<NodeCardProgressBarTextComponent isBold={true}>
								- {tries} Fehlversuche
							</NodeCardProgressBarTextComponent>
						) : null}
					</div>
				);
			case "CanNotBeRestartedAfterPassed":
				return (
					<NodeCardLearnProgressContainer>
						<NodeCardProgressBarTextComponent isBold={false}>
							Lernfortschritt
						</NodeCardProgressBarTextComponent>
						<NodeCardProgressBarTextComponent isBold={true}>100%</NodeCardProgressBarTextComponent>
					</NodeCardLearnProgressContainer>
				);
			default:
				return "";
		}
	})();
	const canBeStarted =
		(isPermanentlyBlocked?.canBeRestarted ?? true) &&
		(isTemporarilyBlockedUntil?.canBeRestarted ?? true);
	return (
		<div className="flex-column w-full">
			<div className="w-full">{title}</div>
			<NodeCardProgressBarComponent
				progressBarState={progressBarState}
				componentColor={componentColor}
				isBlocked={!canBeStarted}
				percentage={
					learnOpportunity.typeDefinition.contentNodeAdvancementResult?.status !==
					"CanNotBeRestartedAfterPassed"
						? progressPercentage
						: 100
				}
			/>
		</div>
	);
};
