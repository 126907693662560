import React from "react";
import { PointsPoolDisplayWithDateComponent } from "../components/learning/PointsPoolDisplayWithDate.component";
import { PageMainText } from "../components/PageTitleText";
import { WideContainerWithTopBar } from "../containers/WideContainerWithTopBar.container";
import { LearningAchievementsTable } from "../components/learning/LearningAchievementsTable";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { PageDefaultContainer } from "../containers/PageDefaultContainer";
import { UserStatusMessage } from "../../infecto-lms-webapp/components/UserStatusMessage.component";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { LearningAchievementsScreen_Query } from "@generated/LearningAchievementsScreen_Query.graphql";

const QUERY = graphql`
	query LearningAchievementsScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						...UserStatusMessage_UserFragment
					}
				}
			}
		}
	}
`;
export const LearningAchievementsScreen = () => {
	usePageTitle("Lernerfolg");

	const query = useLazyLoadQuery<LearningAchievementsScreen_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	return (
		<PageDefaultContainer collapsed={false} screenName="Lernerfolg">
			<WideContainerWithTopBar>
				<UserStatusMessage userFragment={query.Viewer.Auth.currentUser?.user!} />
				<div className="flex flex-column lg:flex-row lg:justify-content-between align-items-center mt-2 lg:mt-0">
					<PageMainText>Lernerfolg</PageMainText>
					<PointsPoolDisplayWithDateComponent />
				</div>

				<LearningAchievementsTable />
			</WideContainerWithTopBar>
		</PageDefaultContainer>
	);
};
